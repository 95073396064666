@import 'gw-common-styles';

.gwToolTip {
    min-width: fit-content;
    background-color: none;
    background: none;
    width: fit-content;
    i{
        font-size: small;
    }
    

    text-align: center;
}

.paHeader {
    h2 {
      font-size: 30px !important;
      color: #333;
    }
  
    h3 {
      font-size: 24px;
      color: #333;
      font-weight: normal !important;
  
      @include media("<=phoneLandscape") {
        display: none;
      }
    }
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
    
  }

  .errorMessage {
    font-size: 14px;
    display: block;
    color: $alfa-red-color;
    @include media('<=phoneLandscape') {
      transform: translateY(-15%);
    }
  }

.dobMobileVisible {
    @include media(">phoneLandscape") {
      display: none !important;
  }
  width:45%;
  }
  
  .radioContainer {
    display: flex;
    display: -webkit-flex;
    margin-bottom: 45px;
    position: relative;
  }
  .addressControlYourInfo{
    margin-top: 10px;
   
      @include media(">=desktopSmall") {
        width: 70rem;
        }
  }
  
  .relativeDiv {
    position: relative;
  }
  .wizardGroupLabel {
    font-size: 12px;
    color: var(--gw-alfa-graphite);
  }
  .displayBlock {
    display: block;
  }
  .validateBtn {
    @include media('<=phoneLandscape') {
      display: none;
    }
   
    position: absolute;
    right: 5%;
    bottom: 0;
    background-color: $alfa-btn-active;
    box-shadow: 0 1px 1px 0 $alfa-btn-box-shadow;

  &:hover {
    background-color: $alfa-btn-hover;
  }

  }

  .validateMobileBtn {
    @include media('>phoneLandscape') {
      display: none;
    }
   
    position: absolute;
    right: 0px;
    bottom: 0;
    background-color: $alfa-btn-active;
    box-shadow: 0 1px 1px 0 $alfa-btn-box-shadow;

  &:hover {
    background-color: $alfa-btn-hover !important;
  }

  }
  .contactCaption {
    font-size: 12px;
    color: var(--gw-alfa-graphite);
    width: 70%;
  
    &.quote {
      width: 100%;
      padding: 10px;
    }
  
    a {
      text-decoration: underline !important;      
      font-size: 12px;
    }
    @include media('<=phonePortrait') {
      width: 90%;
    }
  
    @include media('<=phoneLandscape') {
      -webkit-text-size-adjust: none;
    }
  }
  .digitalContentContainer {
    grid-area: revert;
    width: 100%;
    align-self: center;
}
.smallBlock {
  width: 84% !important;
  margin-left: 7px;
}
.desktopDisplay {
  @include media('>phoneLandscape') {
    display: flex;
    }
}
.spanInfoDiv {
  display: flex;
  position: relative;
  width: 200px;
  color: var(--gw-alfa-graphite);  
}
.radioSpan {
  width:30%;
  @include media('>phonePortrait') {
  width: 120px;
  }
}
.flexMargin {
  margin-left: 0px !important;
}
.firstNameSeparator {
  margin-right: 20px;
  @include media('<=phoneLandscape') {
  padding: 0 20px 35px 0;
  }
}
.homeClass {
  --radioButton-icon-checked-border-color: #4d9cbc !important;
  border: 2px solid #CCCCCC !important;
  width: 25rem;
  /* position: absolute; */
  width: 310px;
  height: 50px;
  left: 357px;
  top: 820px;
  box-sizing: border-box;
  border-radius: 4px;
  background: #FFFFFF;
}
.suffixSeparator {
  margin-left: 20px;
}
.componentDisplayFlex {
  display: flex;
}
.dateSeparator {
  margin-top: 20px;
}

.noPOBoxesMessage {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.4rem;
  color: $alfa-red-color;
  @include media('<=phoneLandscape') {
    margin-top: 10px;
  }
  @include media('>phoneLandscape','<=tabletLandscape') {
    margin-top: 10px;
  }  

  h4 {
    color: $alfa-red-color;
  }
}

.recaptchaContainer {
  --retrieve-quote-container-width: 600px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  width: fit-content;
  @include media('<=phoneLandscape') {
    margin-top: 10px;
  }
  @include media('>phoneLandscape','<=tabletLandscape') {
    margin-top: 10px;
  }    
}

.recaptchaValidationMsg {
  color: $alfa-red-color;
  font-size: 1.4rem;
}
.homeOwnershipClass {
  font-weight: bold;
  font-size: 16px;    
  &::before {
    content: "\A";
    white-space: pre;
  }
}