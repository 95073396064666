@import "~gw-common-styles";
@import "~@jutro/theme/assets/sass/helpers";

.wizardSidebarWrapper {
  --gw-wizard-sidebar-radius: 4px;
  --gw-wizard-sidebar-background-color: var(--GW-COLOR-BACKGROUND-2);
  --gw-wizard-sidebar-padding: 1.5rem 0 1rem;
  --gw-wizard-sidebar-title-font-size: var(--GW-FONT-SIZE-LG);
  --gw-wizard-sidebar-title-line-height: var(--GW-LINE-HEIGHT-LG);
  --gw-wizard-sidebar-title-border-bottom: 1px solid var(--GW-COLOR-BRAND-2);
  --gw-wizard-sidebar-title-padding: 0 10px;
  --gw-wizard-sidebar-title-margin-bottom: 10px;
  --gw-wizard-sidebar-title-margin-top: 15px;

  padding: var(--gw-wizard-sidebar-padding);
  text-align: left;
  background-color: var(--gw-wizard-sidebar-background-color);
  border-radius: var(--gw-wizard-sidebar-radius);
}
.darkColor {
  color: green;
  
}
.manualDiscount{
  font-weight: 300;
  font-size: 12px;
  color: #333333;
  padding: 10px;
  line-height: 125%;
}
.darkContentApp {
  font-weight: 300;
  font-size: 15px;
  color: #333333;
  
}

.gwDiscountHeading {
  height: 42px;
  padding: 5px 0;
  background-color: #ececeb;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  display: flex;
}
.discountDescHide{
  display: none;
}
.discountdiv{
  position: absolute;
  right: 2%;
  top: 100px;
  left:93%;
  width: 235px;
  border-radius: 4px;
  height: auto;
  box-shadow: 0px 2px 3px 2px rgba(0,0,0,0.5), inset 0 0px 0 0 rgba(255,255,255,0.4);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5), inset 0 1px 0 0 rgba(255, 255, 255, 0.4);
  -webkit-box-shadow: 01px 2px 0rgba(0, 0, 0, 0.5), inset 0 1px 0 0 rgba(255, 255, 255, 0.4);
 @include gw-breakpoint-down(tablet) {
    display: none;
}
@media only screen and (min-width: 1003px) and (max-width: 1191px) {
  width: 215px;
}

}
.discountDivQuote{
  position: absolute;
  right: 2%;
  top: 100px;
  left:93%;
  width: 235px;
  border-radius: 4px;
  height: auto;
  box-shadow: 0px 2px 3px 2px rgba(0,0,0,0.5), inset 0 0px 0 0 rgba(255,255,255,0.4);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5), inset 0 1px 0 0 rgba(255, 255, 255, 0.4);
  -webkit-box-shadow: 01px 2px 0rgba(0, 0, 0, 0.5), inset 0 1px 0 0 rgba(255, 255, 255, 0.4);
 @include gw-breakpoint-down(tablet) {
    display: none;
}
@media only screen and (min-width: 1003px) and (max-width: 1191px) {
  top:150px;
  width: 215px;
}
}

.headerText{
  margin: 10px 0px 0px 40px;
  font-size: 14px;
  color: var(--gw-grey-dark-90-color);
}

.gwsocialimage {
  // width :20px;
  // height:20px;
  display: inline-block;
    width: 32px;
    height: 32px;
    left: 10px;
    bottom: 5px;
    //background: url(../images//common/piggy_bank_32.png?v=2492285742) no-repeat 0 0;
    position: absolute;
}


.buttonContainer{ 
  //1003 1181
    top: 1%;
   right: 5%;
   left:95%;
  position: absolute;
  @include gw-breakpoint-down(tablet) {
    right: 0;
    left:81%;
    top: 1rem;
  }
  @media only screen and (max-width: 760px) {
    display:none;
  }
  
}
.step {
  --gw-wizard-sidebar-active-step-color: var(--GW-COLOR-DARK-CONTRAST);
  --gw-wizard-sidebar-active-step-bg: var(--GW-COLOR-BRAND-1);
  --gw-wizard-sidebar-active-step-indicator-color: var(--GW-COLOR-DARK-CONTRAST);
  --gw-wizard-sidebar-indicator-size: .8rem;
  --gw-wizard-sidebar-step-padding: .4rem;
  --gw-wizard-sidebar-step-gap: .1rem;
  --gw-wizard-sidebar-step-color-not-visited: var(--GW-COLOR-STROKE);
  font-weight: bold;

  display: inline-block;
  cursor: pointer;
  padding: var(--gw-wizard-sidebar-step-padding);
  margin-bottom: var(--gw-wizard-sidebar-step-gap);

  &.active {
    color:var(--gw-wizard-sidebar-active-step-color);
    background-color: var(--gw-wizard-sidebar-active-step-bg);
    position: absolute;
  }

  &.active::after {
    content: "";
    width: 0;
    height: 0;
    content: "";
    z-index: 2;
    border-right: var(--gw-wizard-sidebar-indicator-size) solid var(--gw-wizard-sidebar-active-step-indicator-color);
    border-bottom: var(--gw-wizard-sidebar-indicator-size) solid transparent;
    border-top: var(--gw-wizard-sidebar-indicator-size) solid transparent;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &.notVisited {
    color: var(--gw-wizard-sidebar-step-color-not-visited);
    cursor: not-allowed;

    .navigationButton {
      pointer-events: none;
    }
  }

  .navigationButton {
    border: none;
    background: inherit;
    text-align: inherit;
    color: inherit;
    font: inherit;
    cursor: inherit;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--GW-FONT-SIZE-SM);
    font-weight: normal;
  }
}

.warningIssue {
  color: var(--GW-COLOR-ALERT-WARNING);
}

.errorIssue {
  color: var(--GW-COLOR-ALERT-ERROR);
}

.stepsNav {
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0;
}


.sideBarTitle {
  font-size: var(--gw-wizard-sidebar-title-font-size);
  line-height: var(--gw-wizard-sidebar-title-line-height);
  font-weight: bold;
  padding: var(--gw-wizard-sidebar-title-padding);
  margin-bottom: var(--gw-wizard-sidebar-title-margin-bottom);
  margin-top: var(--gw-wizard-sidebar-title-margin-top);
  border-bottom: var(--gw-wizard-sidebar-title-border-bottom);
  display: block;
}
.liStyle {
  display: inline-block;
}
.container {
  
  margin-top: 2rem;
  
  
  @include gw-breakpoint-down(tablet) {
    // margin-left: -15%;
  }
  ul {
    @include gw-breakpoint-up(desktop) {
      padding-inline-start: 0;
    }
  }
}
.progressbar {
  min-width: 65rem;
  max-width:90rem;
  margin-bottom: 3rem;
  
  @include gw-breakpoint-down(tablet) {
    // width: 100%;
    max-width:100%;
    min-width: 80%;
  }
  @include gw-breakpoint-down(phone) {
    margin-left: 15%;
  }
}
.progressbar li {
  list-style: none;
  display: inline-block;
  width: 17rem;
  @include gw-breakpoint-down(tablet) {
    width: 21%;
  }
  @media only screen and (min-width: 1003px) and (max-width: 1191px) {
    width: 15rem;
  }
  
  position: relative;
  text-align: center;
  color: #373737
}
.progressbar li:before {
  content:'';
  width: 20px;
  height: 20px;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 20px;
  background: #bbbdbf;
  text-align: center;
  font-weight: bold;
  margin-left: 7rem;
  @include gw-breakpoint-down(phone) {
    width: 20px;
    height: 20px;
  }
  @include gw-breakpoint-only(tablet) {
    width: 20px;
    height: 20px;
  }
}

.progressbar li:after {
  content: '';
  position: absolute;
  width:76%;
  height: 2px;
  background: #333;
  top: 9px;
  left: -42%;
  @include gw-breakpoint-down(tablet) {
    width:70%;
    top: 7px;
    left: -28%;
    height: 2px;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    left: -32%;
  }
}

.progressbar li:first-child {
  @include gw-breakpoint-up(desktop) {
  margin-left: -5rem;
  }
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li {
  &.active::before {
    background: #287D9F;
    height: 30px;
    width: 30px;
    margin: -5px auto 5px 6.5rem;

  }
}
.progressbar li {
  &.visited:not(.active)::before {
    background: #4D9CBC ;
    content: '\f00c';
    font-family: 'FontAwesome';
    color: white;
  }
}

.finalDetailClass {
  @include gw-breakpoint-up(desktop) {
  margin-left: 0 !important;
  }
  margin-left: 10%;
}
.buttonStyle {
    font-weight: bold;
    border: 1px solid #287D9F;
    color: #287D9F;
    background: unset;
  @include gw-breakpoint-down(tablet) {
    width: 155px;
  }
}
.progressbarStyle {
  text-align: center;
  padding-left: unset;
}