@import 'gw-common-styles';

:root {
    --gw-tiles-transition-time: .2s;
    --gw-tiles-border-radius: 2px;

    --gw-tiles-align-height: 1.2;
    --gw-tiles-align-margin: -16px;
    --gw-tiles-active-height: 100%;

    --gw-tiles-large-width: 205px;
    --gw-tiles-large-margin: 16px;
    --gw-tiles-large-height: 38px;

    --gw-tiles-medium-left: -6px;
    --gw-tiles-medium-top: -8px;

    --gw-tiles-title-width: 120px;
    --gw-tiles-title-height: 50px;
    --gw-tiles-title-min-height: 4.2rem;
    --gw-tiles-content-margin: 32px;
    --gw-tiles-content-height: 0.7;
    --gw-tiles-height: 104px;

    --gw-tiles-title-left: 6px;
    --gw-tiles-title-top: 8px;
    --gw-tiles-medium-title-height: 3.2rem;
    --gw-tiles-medium-content-margin: 16px;
}

$tile-colors: (
  grey: '--gw-grey-light-60-color',
  blue: '--gw-blue-light-2',
  lightblue: '--gw-blue-light-15',
  turquoise: '--gw-turquoise-2',
  lightgreen: '--gw-lime-green',
  darkgreen: '--gw-dark-green-3',
  orange: '--gw-orange-3',
  green: '--gw-green-4',
  red: '--gw-red-6',
  pink: '--gw-pink-2',
  darkgrey: '--gw-grey-light-21',
  darkblue1: '--gw-blue-6',
  darkblue2: '--gw-blue-light-3'
) !default;

// TILE COLORS
@each $color-name, $color-var in $tile-colors {
    .gwTileColor#{$color-name} {
        background-color: var(unquote(#{$color-var}));
    }
}

.gwTiles {
    overflow: hidden;
    margin-bottom: var(--line-height-xxl);
    margin-right: var(--line-height-xs);

    a {
        text-decoration: none;
        color: var(--gw-tiles-anchor-color);
        transition: color var(--gw-tiles-transition-time);
    }

    .gwTilesAligner {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        text-transform: uppercase;
    }

    .gwTile {
        cursor: pointer;
        display: flex;
        flex: 0 1 auto;
        flex-direction: column;
        text-align: center;
        color: var(--gw-grey-light-9);
        background-color: var(--gw-white-opaque-5);
        border-radius: var(--gw-tiles-border-radius);
        overflow: hidden;
        min-height: var(--gw-tiles-height);
        transition: background-color var(--gw-tiles-transition-time), color var(--gw-tiles-transition-time);
        position: relative;

        &:hover {
            color: var(--gw-grey-dark-80-color);
            background-color: var(--gw-grey-light-8);

            a {
                color: var(--gw-grey-dark-80-color);
            }
        }

        &:after {
            content: "";
            clear: both;
            display: table;
            border-spacing: 0;
        }

        &.gwActive {
            color: var(--gw-white);
            background-color: var(--gw-grey-light-22);
      
            a {
              color: var(--gw-white);
            }

            .gwTileColor {
                height: var(--gw-tiles-active-height);
            }
        }
    }
}
.gwTilesLarge {
    .gwTilesAligner {
        font-size: var(--font-l);
        line-height: var(--gw-tiles-align-height);
        margin: var(--gw-tiles-align-margin);
    }
    .gwTile {
        width: var(--gw-tiles-large-width);
        margin: var(--gw-tiles-large-margin);

        &:not(.gwActive):hover {
                .gwTileColor{
                    height: var(--gw-tiles-large-height);
                }
        }
    }
    .gwTileColor {
        height: var(--line-height-m);
    }
    .gwTileTitle {
        margin-top: var(--gw-tiles-title-height);
        padding-left: var(--line-height-m);
        padding-right: var(--line-height-m);
        min-height: var(--gw-tiles-title-min-height);
        margin-bottom: var(--line-height-s);
    }
    .gwTileContent {
        font-size: var(--font-xxxl);
        margin-bottom: var(--gw-tiles-content-margin);
        padding-left: var(--line-height-m);
        padding-right: var(--line-height-m);
    }
}

.gwTilesMedium {
    .gwTilesAligner {
        font-size: var(--font-m);
        font-weight: var(--font-weight-medium);
        line-height: var(--font-ml);
        margin-left: var(--gw-tiles-medium-left);
        margin-right: var(--gw-tiles-medium-left);
        margin-top: var(--gw-tiles-medium-top);
        margin-bottom: var(--gw-tiles-medium-top);
    }
    .gwTile {
        width: var(--gw-tiles-title-width);
        margin-left: var(--gw-tiles-title-left);
        margin-right: var(--gw-tiles-title-left);
        margin-top: var(--gw-tiles-title-top);
        margin-bottom: var(--gw-tiles-title-top);

        &:not(.gwActive):hover {
                .gwTileColor{
                    height: var(--line-height-s);
                }
        }
    }
    .gwTileColor {
        height: var(--gw-tiles-title-top);
    }
    .gwTileTitle {
        margin-top: var(--line-height-l);
        padding-left: var(--gw-tiles-title-top);
        padding-right: var(--gw-tiles-title-top);
        min-height: var(--gw-tiles-medium-title-height);
        margin-bottom: var(--gw-tiles-title-top);
    }
    .gwTileContent {
        font-size: var(--font-xlg);
        margin-bottom: var(--gw-tiles-medium-content-margin);
        padding-left: var(--gw-tiles-title-top);
        padding-right: var(--gw-tiles-title-top);
    }
}
.gwTileColor {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: height var(--gw-tiles-transition-time);
}
.gwTileTitle {
    flex: 0 0 auto;
    position: relative;
}
.gwTileContent {
    flex: 1 0 auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    line-height: var(--line-height-s);
    position: relative;

    .gwIcon {
        line-height: inherit;
        transition: color var(--gw-tiles-transition-time);
        font-size: var(--font-xl);
    }
}
