@import 'gw-common-styles';

.gwMediaSidebarTextContainer {
  --gw-media-sidebar-text-margin: 10px;
  --gw-media-sidebar-text-padding: 5px;

  background-color: var(--gw-white);
  margin-bottom: var(--gw-media-sidebar-text-margin);
  padding: var(--gw-media-sidebar-text-padding);

  @include media("<=tabletLandscape") {
    display:none;
  }
}
