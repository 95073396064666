@import 'gw-common-styles';
.gwToolTip {
  min-width: fit-content;
  background-color: none;
  background: none;
  width: fit-content;
  i{
      font-size: small;
  }
  

  text-align: center;
}
.wizardGroupLabel {
  font-size: 12px;
  color: $alfa-grey-color;
}
.phoneContainer {
  display: flex;
 
}
.phoneLabel{

    color: var(--gw-grey-dark-90-color);
    font-size: 12px;
    margin-left: 10px;
}
.paHeader {
 

    h2 {
      font-size: 30px !important;
      color:#333;
    }

    span {
      font-size: 24px;
      color: #333;
      font-weight: normal !important;
    }

    padding: 30px 0 0 0;  
    
  }

  .mobileShow {
    @include media('>=phoneLandscape') {
      display:none;
      
    }
  }

  .alfa-contact-header {
    h2 {
      font-size: 30px;
      color: #333333;
      @include media('<=phoneLandscape') {
        font-size: 24px;  
        color: #000000;      
      }
      @include media('>=phoneLandscape','<=tabletLandscape') {
        color: #000000;   
      }
    }

    tooltipComponent {
      @include media('>=phoneLandscape') {
        display:none;        
      }
    }
  
    span {
      font-size: 20px;
      color: #333333;
      font-weight: normal !important;  
      @include media('<=phoneLandscape') {
        display: none;
      }
    }
    padding: 30px 0;

    @include media('<=phoneLandscape') {
      display: flex;
      display: -webkit-flex;
      padding: 30px 15px !important;
      }    
  }
  .emailDiv {
    padding-bottom: 5%;
    width: 30rem;
    div[id="floatingContainer"] > div {
      padding-bottom: unset;
    }
    @include media('<phoneLandscape') {
      width: 28rem;
    }
  }

  .gw-more-detail-header{
    font-size: 30px;
    color: #333 !important;
    font-weight: bold;
    @include media('<phoneLandscape') {
      font-size: 24px;
    }
  }

  .mockError {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom: 2px solid #c30717;

 
  }
  .mock:focus{
    border-color: none;
  box-shadow: none;
  }
  .errordiv{
    position: relative;
    top: -30px;
    right: -225px;
    @include media("<=phoneLandscape") {
      display: none;
      }
  }
  .mobileerrordiv{
    @include media(">=phoneLandscape") {
      display: none;
      }
  }

  .addressBlock30 {
    @include media(">=desktopSmall") {
      
    width: 50%;
      }
      @include media('>phoneLandscape','<desktopSmall') {
        width: 65%;
        }
    margin-left: 60px;
    @include media("<=phoneLandscape") {
      width: auto;
      margin-left: 20px;
      }
  }
  .phoneClass {
    padding: 0 5px;
    @include media(">phonePortrait") {
     width: 140px;
    }
  }
  
  .driverErrorMessage {
    font-size: 14px;
    display: block;
    color: $alfa-red-color;
  }

  .phoneErrorMessage {
    font-size: 14px;
    display: block;
    color: $alfa-red-color;
  
  }
  .contactCaption {
    
    padding-top: 30px;
    margin-bottom: 20px;
    font-size: 12px;
    color: #000000;   
    width: 58rem;
  
    &.quote {
      width: 100%;
      padding: 10px;
    }
  
    a {
      text-decoration: underline !important;      
      font-size: 12px;
    }

    @include media('<=tabletPortrait'){
      width: 55rem;
      margin-left: -15px;
      margin-bottom: 0px !important;
    }
    @include media('<=phoneLandscape') {
      width: 95%;
      -webkit-text-size-adjust: none;
      margin: 20px 10px;
      padding-top: 0px;
    }
  }

 
  

  .divContainer {
    background-color:  #f2f2f2;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 10px;
    // display:flex;
    // flex-direction: row;
  }
  .dropWidth{
      width:200px;
  }

 

.paMessageHeader {
  padding: 10px 0;  
  h3 {
    font-size: 19px;
    font-weight: normal;
    width:80%;

    @include media('<=phoneLandscape') {
      display: none;
    }
  }
}
.phoneContainer input {
  color: #333333;
}
.phoneContainerMobile {
  @include media('<=phoneLandscape') {
    width: 13rem;
  }
}
.phoneDivWidth {
  @include media(">=desktopSmall") {
    width: 30rem;
  }
}
.alfa-contact-caption {
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    color: #000000;
    font-family: 'coresansn';
    margin-bottom: 0px !important;
}
  