@import 'gw-common-styles';

:root {
    --gw-modal-title-padding-top: 20px;
    --gw-modal-title-padding-bottom: 25px;
    --gw-modal-width: 420px;
    --gw-button-container-margin-top: 20px;
    --gw-cancel-button-margin-right: 10px;
}

.alfa-add-std-modal {
    //display: flex;
    padding-top: 0;
    min-width: 300px;
    width: 420px;
    height: auto;
    max-height: 460px;
    overflow-y: auto;
    border-radius: 5px;
    box-shadow: 0 3px 0 0 #F4F2EA;
    @include media('<=phoneLandscape') {
        width:325px;
    }
    @include media('>phoneLandscape','<=tabletLandscape') {
        width: 420px;
    }  
}

.alfaAddStdModal {
    //display: flex;
    top: 0, 0, 0, 0;
    padding-top: 0;
    min-width: 300px;
    width: 420px;
    height: auto;
    max-height: 474px;
    //overflow-y: auto;
    border-radius: 5px;
    box-shadow: 0 3px 0 0 #F4F2EA;
    @include media('<phoneLandscape') {
        width:295px;
    }
    @include media('>=phoneLandscape','<=tabletLandscape') {
        width: 420px;
    }  
}

.gw-alfa-heading {
    display: flex;
    margin-left: -17%;
    font-size: 1.9rem;
    font-weight: 300;
    width: 418px;
    border-radius: 5px 5px 0px 0px;
    background: #F4F2EA;
    height: 55px;
    word-spacing: 10px;
    letter-spacing: 1px;
    @include media('<phoneLandscape') {
        width:297px;
        margin-left: -3%;
    }
    @include media('>=phoneLandscape','<=tabletLandscape') {
        width: 419px;
        margin-left: -26%;
    }  
}

.alfa-header-icon {
    font-size: 2.5rem;
    font-weight: 300;
    word-spacing: 10px;
    letter-spacing: 1px;
    margin: 13px 0 0 10px;
    height: 45px;
    color:#4d9cbc;
    @include media('<phoneLandscape') {
    }
    @include media('>=phoneLandscape','<=tabletLandscape') {
    }  
}

 .alfa-header-text {
    font-size: 28px;
    font-weight: 600;
    word-spacing: 10px;
    letter-spacing: 1px;
    padding-left: 20px;
    margin-top: 17px;
    font-family: 'coresansn';
    height: 45px;
    color: black;
    @include media('<phoneLandscape') {
    }
    @include media('>=phoneLandscape','<=tabletLandscape') {
    }  
}

.instructionText {
    margin-left: 17%;
    padding-top: 16px;
    font-size: 15px;
    font-family: 'coresansn';
    @include media('>tabletLandscape') {
        transform: translateX(-18%);
    }
     @include media('<phoneLandscape') {
        margin-left: 3%;
    }
    @include media('>=phoneLandscape','<=tabletLandscape') {
        width: 350px;
        margin-left:-7%;
        font-size: 110%;
    }  
}

.stdRadioButtonElement {
    --radioButton-label-left-margin: 1.0rem;
    --radioButton-label-padding: 4.0rem;
    font-family: 'coresansn';
    font-size: 16px;
    margin-top: 24px;
    padding: 0px 7px;
    margin-bottom: -24px;
    @include media('<phoneLandscape') {
        margin-left: 0%;
    }
    @include media('>=phoneLandscape','<=tabletLandscape') {
        width: 350px;
        margin-left:-10%;
        padding-top: 0px;
    }  
}

.themeRoot hr {
    margin: 2rem 0;
}

.hr {
    width: 85%;
    height: 1px;
    border-width:0;
    background-color:#999999;
    padding-left: 25px;
    margin: 24px 0px !important;
    margin-left: 8px !important;
    @include media('<phoneLandscape') {
        margin-left: 8px !important;
        width: 86%;
    }
    @include media('>=phoneLandscape','<=tabletLandscape') {
        width: 100%;
        margin-left: -20px !important;
    }  
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 34px;
    @include media('<phoneLandscape') {
    }
    @include media('>=phoneLandscape','<=tabletLandscape') {
    }  
}
.selectButton {
    min-width: 95px;
    margin: auto;
    margin-top: -10px;
    height: 56px;
    padding: 18px 36px 18px 36px;
    font-family: 'coresansn';
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
}

.cancelBtnMargin {
    margin-right: var(--gw-cancel-button-margin-right);
    @include media('<phoneLandscape') {
    }
    @include media('>=phoneLandscape','<=tabletLandscape') {
    }  
}

 div[class*="jut__RadioButton__radioButton"] {
    margin-left: 12px;
    @include media('<phoneLandscape') {
        margin-left: 0px;
    }
  }

.labelSpace {
    margin-left: 20px;
    margin-top: -24px !important;
  }
