@import "~gw-common-styles";

.addressLookupBottomItems {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.addressContainer {
  position: relative;
}

.clearButton {
  --gw-close-button-position-right: 10px;
  --gw-close-button-position-top: 5px;
  @include media("<phonePortrait") {
    --gw-close-button-position-top: 40px;
  }
  top: var(--gw-close-button-position-top);
  right: var(--gw-close-button-position-right);
  position: absolute;
}

.componentContainers {  
  padding:20px 0 0 0;
}

.addressLookupSearchButton {
  --gw-address-lookup-search-button-margin: 20px;
  margin-left: var(--gw-address-lookup-search-button-margin);
}
.stateReadOnly {
  width: 40px;
}
.smallInfo {
  font-size: 10px;
  left:80px;
}
.addressLookupContainer {  
    width: 50%;
    @include media("<=phonePortrait") {
      width: 60%;
      margin-right:10px; 
    }
    margin-right:20px; 
}
.addressBlock30 {
  width: 30%;
}
.zipBlock {
  width: 100px;
  @include media("<=phonePortrait") {
    width: 90px;
    margin-left: 0;
  }
}
.countyBlock{
  width: 30%;
  @include media("<=phonePortrait") {
  margin-left: 0 !important;
  width: 35%;
  }
}
.addressCityDiv {
  width: 30%;
  margin-bottom: 10px;
  @include media("<=phonePortrait") {
    width: 40%;
  }
}
.displayFlex {
  display: flex;
}
.addressBlock20 {
  width: 20%;
  @include media("<=tabletPortrait") {
  width: 30%;
  margin-left: 0px !important;
  }
}
.aptBlock {
  width: 20%;
  @include media("<=tabletPortrait") {
    width: 30%;
  }
}
.addressBlock10 {
  width: 10%;
}

.stateSelector {
  width: 70px;
  position: relative;
  margin-bottom: 10px;
  @include media("<=phonePortrait") {
    width: 60px;
  }
}
.licenseStateDrop {
  width: 42%;
  margin-left: -5%;
  @include media("<=phonePortrait") {
    width: 112%;
  }
}

.licenseStateDrop {
  width: 42%;
  margin-left: -5%;
  @include media("<=phonePortrait") {
    width: 112%;
  };
  @media screen and (min-width: 400px) and (max-width: 1400px) { //EH-21799: added min and max screen width for making sure state dropdown shows up as expected 
    width: 80px;
  }
}

.flexComponent {
  padding: 20px 0;
}
.overrideError {
  input {
  border-style: none none solid !important;
  border-bottom: 2px solid var(--gw-alfa-graphite) !important;
  color: var(--gw-alfa-graphite) !important;
  }
}

.noPOBoxesMessage {
  // margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.4rem;
  color: $alfa-red-color;
  @include media('<=phoneLandscape') {
    margin-top: 10px;
  }
  @include media('>phoneLandscape','<=tabletLandscape') {
    margin-top: 10px;
  }  

  h4 {
    color: $alfa-red-color;
  }

}
.zipErrorMessage {
  font-size: 1.4rem;
  display: block;
  color: $alfa-red-color;
  transform: translateY(-20%);

}
