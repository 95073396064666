@import 'gw-common-styles';

:root {
    --gw-modal-title-padding-top: 20px;
    --gw-modal-title-padding-bottom: 25px;
    --gw-modal-width: 420px;
    --gw-button-container-margin-top: 20px;
    --gw-cancel-button-margin-right: 10px;
}

.alfa-add-std-modal {
    
    top: 0, 0, 0, 0;
    padding-top: 0;
    min-width: 308px;
    width: 420px;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 3px 0 0 #F4F2EA;
    @include media('>=phoneLandscape') {
            max-height: calc(100vh - 80px);
           
    }
}

.wrapContainer{
    @include media("<=tabletPortrait") {
        margin: -10px;
    }

    @include media("<=phoneLandscape") {
      width: 297px;
    }
    min-width: 300px;
    height: auto;
  
}
.buttonSize{
  @include media(">=tabletPortrait") {
    .jut__IconButton__large {
      --iconButton-icon-size: 3rem !important;
    }
    
  }
}

.darkColor {
    color:  #000000;    
  }
  @include media(">=tabletPortrait") {
    .jut__IconButton__large {
      --iconButton-icon-size: 3rem !important;
    }
    
  }
  
  
  .darkColor:hover {
    background-color: none ;
    
  }
  .darkColorApp {
    color: green;
    
  }
  .darkContentApp {
    font-weight: 300;
    font-size: 15px;
    color: #333333;  
  }
  .gwDiscountHeading {
    height: 42px;
    padding: 5px 0;
    background-color: #ececeb;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    display: flex;
  }

  .headerText{
    margin: 10px 2px 2px 30px;
    @include media('<=phoneLandscape') {
      font-size: 14px !important;
      color: black !important;
      font-weight: bold !important;
  }
  
  }
  .mobileheaderText{
    margin: 10px 2px 2px 30px;
    font-size: 13px;
  }
  .headerTextContainer{
      margin-left: 120px;
      @include media('<=phoneLandscape') {
        margin-left: 60px;
      }
      @include media('>phoneLandscape','<=tabletLandscape') {
        position: absolute;
        bottom: 0;
        margin-left: 0;
        left: 50%;
        z-index: 999;
      }
  }
  .gwsocialimage {
     
    display: inline-block;
      width: 32px;
      height: 32px;
      left: 120px;
      bottom: 5px;
      
      position: absolute;
      @include media('<=phoneLandscape') {
        left: 60px;
       }
       @include media('>phoneLandscape','<=tabletLandscape') {
        left: -10px;
      }
      
  }
  
  .gwsocialimageHeader {
    
     display: inline-block;
      width: 32px;
      height: 32px;  
  }
  
  .gwDiscountDiv{
    @include media( ">tabletPortrait") {
       display: none;
      }
      @include media('<=phoneLandscape') {
      margin-left: 15px;
      position: relative;
      }
  }
  .gwsocialimageShake{
    /* Start the shake animation and make the animation last for 0.5 seconds */
    
    animation: shake 0.25s;
  
    /* When the animation is finished, start again */
    animation-iteration-count: 4;
  }
  
  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }



.mock {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom: 2px solid grey;
  width: 238px;
  

}
.mock:focus{
  outline: none;
  box-shadow: none;
}

.alfaAddStdModal {
    
    top: 0, 0, 0, 0;
    padding-top: 0;
    min-width: 308px;
    width: 540px;
    height: 330px;
    border-radius: 5px;
    box-shadow: 0 3px 0 0 #F4F2EA;
    @include media('>=phoneLandscape') {
            max-height: calc(100vh - 80px);
           
           
    }
    @include media('<=tabletPortrait'){
      height: 320px;
    }
    
    @include media('<=phoneLandscape') {
      width: 309px;
      min-width: auto;
      height: 415px;
       
}
    
}
   

.alfa-email-caption {
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    color: #000000;
    font-family: 'coresansn';
    margin-bottom: 0px !important;
}
.emailInputDiv {
  width: 50%;
  @include media("<=phoneLandscape") {
    width: 80%;
  }
  position: relative;
  div[class^="jut__FieldMessage__fieldMessage jut__FieldMessage__error"] {
    display: none !important ;
}
}
.emailFloatText {
  top: -20px;
  font-size: 12px;
  color: var(--gw-alfa-graphite);
  transition: 0.2s ease all;
  outline: none;
  position: absolute;
  font-weight: bold;
  font-family: 'coresansn';
}

.gw-alfa-heading {
    display: flex;
    top: 0, 0, 0, 0;
    margin-left: -70px;
    font-size: 1.9rem;
    font-weight: 300;
    width: 540px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    
    position: relative;
    
    background: #ddd;
    height: 55px;
    word-spacing: 2px;
    letter-spacing: 1px; 

    @media only screen and (min-width: 640px) and (max-width: 1025px) {
      margin-left: -92px;
  }

    @include media("<=tabletPortrait") {
        margin-left: -83px;
    }

    @include media("<=phoneLandscape") {
        word-spacing: 0px;
        margin-left: 0px;
        letter-spacing: 0px;
        width:309px;
    }

   
   
    
}

.captionContainer {
  
    @include media("<=phoneLandscape") {
        margin-right: 0px; 
        width:280px;
        line-height: 18px;
        
    }
    
}


.alfa-header-icon {
    font-size: 2.5rem;
    font-weight: 300;
    word-spacing: 10px;
    letter-spacing: 1px;
    padding-left: 5px;
    margin-top: 10px;
    height: 45px;
    color:#1a1a1a;

    @include media("<=phoneLandscape") {
        word-spacing: 0px;
        margin-left: 0px;
        letter-spacing: 1px;
    }
    button > i {
      @include media(">=tabletPortrait") {
         font-size: 3rem !important;
        
      }
    }
}
.activityContainer{
    @include media("<=phoneLandscape") {
        margin: 0 16px;
		    width: 280px;
    }
    @media only screen and (min-width: 640px) and (max-width: 1025px) {
      margin-left: -68px;
  }
    margin-left: -55px;
    @include media(">phoneLandscape") {
      min-width: 508px;
    }
    padding-top: 10px;
}

 .alfa-header-text {
    font-size: xx-large;
    font-weight: 300;
    word-spacing: 2px;
    letter-spacing: 1px;
    padding-left: 10px;
    margin-top: 10px;
    height: 45px;
    color: black;
}

.instructionText {
    margin-left: -50px;
    padding-left: 8px;
    padding-top: 8px;
    font-size: 120%;
}

.hr {
    width: 90%;
    height: 1.5px;
    border-width:0;
    color:gray;
    background-color:gray;
    padding-left: 25px;
}


.buttonContainer {
    display: flex;
    justify-content: center;
    @include media(">=phoneLandscape") {
      margin-top: 30px;
    }
    
}
.driverErrorMessage {
    font-size: 14px;
    display: block;
    color: $alfa-red-color;
    transform: translateY(-30%);
  
  }

  .closeContainer {
   font-size: medium;
   position: absolute;
   right: 10px;
   top: 10px;  
   @include media("<=phoneLandscape") {
    right: 2px;
    top: 2px;
   }
   
button > i {
  @include media(">=tabletPortrait") {
     font-size: 14px !important;    
  }
  font-size: 12px !important;
}  
  }


.cancelBtnMargin {
    
}


.emailLabel{
  font-size: 13px;
  font-weight: 300;
  font-size: 15px;
  color: #333333;
  font-family: 'coresansn';
  @include media("<=phoneLandscape") {
    font-size: 14px;
  }
}

.emailLabelDiv {
  padding: 0 5px 25px 0px;
}


.modalButtonContainer{
  padding: 16px 0;
  text-align: center;
  @include media("<=phoneLandscape") {
    margin-left: 10px;
    margin-top: 0px;
}



}


.emailHeader{
  font-size: 28px;
  word-spacing: 0px !important;
  font-weight: bold;
  margin: 13px;
  color: #333333;
  @include media("<=phoneLandscape") {
    margin: 0px;
    font-size: 16px;
}
}

div[id^="buttoncontainer"] button[class^="digitalButton"] {
    font-size: 16px;
    font-family: 'coresansn';
    color:#FFFFFF;
    min-width: 126px;
  }

.hyperLinkColor {
  color: #287D9F;
} 