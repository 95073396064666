@import 'gw-common-styles';
.gwToolTip {
  min-width: fit-content;
  background-color: none;
  background: none;
  width: fit-content;
  i{
      font-size: small;
  }
  

  text-align: center;
}
.paHeader {
  display: flex;
  
    h2 {
      font-size: 30px !important;
      color: var(--gw-alfa-graphite);
      @include media('<=phoneLandscape') {
        font-size: 24px !important;
      } 
    }
    padding: 30px 0 0 0;
    @include media('<=phoneLandscape') {
      margin-left: 20px;
    }  
    
  }
  .flexContainer{
      display: flex;
      margin-top: 10px;
  }
  .mobileContainer{
    display:none;
  }

  .claimDescription {
    div[class*="jut__GenericSelectControl__control"] {
      @include media('>phoneLandscape') {
      background-color: #f2f2f2;
      }
    }
    width:90%;
    @include media('<=tabletLandscape') {
      width: 240px;
    }
  }

  .divContainer {
    background-color:  #f2f2f2;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 10px;
    margin-top: 20px;
    @include media('<=phoneLandscape') {
      margin-left: 20px;
      background-color:  white;
    }
  }

  .subHeader{
    color: var(--gw-alfa-graphite);
    font-size: 20px;
  }
  .driverVehicleName {
    color: var(--gw-alfa-graphite);
    font-size: 16px;
    font-weight: 700;
  }
  .dynamicContainer{
    background-color:  #f2f2f2;
    
    @include media('<=phoneLandscape') {
      background-color:  white;
    }
    @include media('>phoneLandscape') {
      max-width: 62rem;
    }
  }

  .dateAmount {
    font-size: 15px;
    color: var(--gw-alfa-graphite);
    @include media('>phoneLandscape') {
      margin-left: 20%;
    }
  }

  .mobileDateOfIncident {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .mobileGrid{
    @include media('<=phoneLandscape') {
      //display: grid;
      grid-template-columns: 1fr;
    }
  }
  .dropWidth{
      width:200px;
  }
  .claimDetail{
    font-size: 15px;
    color: var(--gw-alfa-graphite);
  }
  .mobileSubHeader{
    @include media('<=phoneLandscape') {
     display: none;    
    }
    max-width: 58rem;
  }
  .mobileHide{
    @include media('<=phoneLandscape') {
      display:none;
      
    }
  }
  .mobileShow{
    @include media('>=phoneLandscape') {
      display:none;
      
    }
  }

  .mobilefontIncrease{
    font-size: 18px;
    @include media('>=phoneLandscape') {
      display:none;
      
    }
  }

  .driverErrorMessage {
    font-size: 15px;
    display: block;
    color: $alfa-red-color;
    transform: translateY(-20%);
  
  }

 

.paMessageHeader {
  padding: 10px 0;  
  h3 {
    font-size: 19px;
    font-weight: normal;
    width:80%;

    @include media('<=phoneLandscape') {
      display: none;
    }
  }
}





  