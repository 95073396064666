@import "~gw-common-styles";

.wizardSidebarWrapper {
  --gw-wizard-sidebar-radius: 4px;
  --gw-wizard-sidebar-background-color: var(--GW-COLOR-BACKGROUND-2);
  --gw-wizard-sidebar-padding: 1.5rem 0 1rem;
  --gw-wizard-sidebar-title-font-size: var(--GW-FONT-SIZE-LG);
  --gw-wizard-sidebar-title-line-height: var(--GW-LINE-HEIGHT-LG);
  --gw-wizard-sidebar-title-border-bottom: 1px solid var(--GW-COLOR-BRAND-2);
  --gw-wizard-sidebar-title-padding: 0 10px;
  --gw-wizard-sidebar-title-margin-bottom: 10px;
  --gw-wizard-sidebar-title-margin-top: 15px;

  padding: var(--gw-wizard-sidebar-padding);
  text-align: left;
  background-color: var(--gw-wizard-sidebar-background-color);
  border-radius: var(--gw-wizard-sidebar-radius);
}

.step {
  --gw-wizard-sidebar-active-step-color: var(--GW-COLOR-DARK-CONTRAST);
  --gw-wizard-sidebar-active-step-bg: var(--GW-COLOR-BRAND-1);
  --gw-wizard-sidebar-active-step-indicator-color: var(--GW-COLOR-DARK-CONTRAST);
  --gw-wizard-sidebar-indicator-size: .8rem;
  --gw-wizard-sidebar-step-padding: .4rem;
  --gw-wizard-sidebar-step-gap: .1rem;
  --gw-wizard-sidebar-step-color-not-visited: var(--GW-COLOR-STROKE);
  font-weight: bold;

  display: block;
  cursor: pointer;
  padding: var(--gw-wizard-sidebar-step-padding);
  margin-bottom: var(--gw-wizard-sidebar-step-gap);

  &.active {
    color: var(--gw-wizard-sidebar-active-step-color);
    background-color: var(--gw-wizard-sidebar-active-step-bg);
    position: relative;
  }

  &.active::after {
    content: "";
    width: 0;
    height: 0;
    content: "";
    z-index: 2;
    border-right: var(--gw-wizard-sidebar-indicator-size) solid var(--gw-wizard-sidebar-active-step-indicator-color);
    border-bottom: var(--gw-wizard-sidebar-indicator-size) solid transparent;
    border-top: var(--gw-wizard-sidebar-indicator-size) solid transparent;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &.notVisited {
    color: var(--gw-wizard-sidebar-step-color-not-visited);
    cursor: not-allowed;

    .navigationButton {
      pointer-events: none;
    }
  }

  .navigationButton {
    border: none;
    background: inherit;
    text-align: inherit;
    color: inherit;
    font: inherit;
    cursor: inherit;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--GW-FONT-SIZE-SM);
    font-weight: normal;
  }
}

.warningIssue {
  color: var(--GW-COLOR-ALERT-WARNING);
}

.errorIssue {
  color: var(--GW-COLOR-ALERT-ERROR);
}

.stepsNav {
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0;
}


.sideBarTitle {
  font-size: var(--gw-wizard-sidebar-title-font-size);
  line-height: var(--gw-wizard-sidebar-title-line-height);
  font-weight: bold;
  padding: var(--gw-wizard-sidebar-title-padding);
  margin-bottom: var(--gw-wizard-sidebar-title-margin-bottom);
  margin-top: var(--gw-wizard-sidebar-title-margin-top);
  border-bottom: var(--gw-wizard-sidebar-title-border-bottom);
  display: block;
}
