.lobContainer {
  --line-of-business-space: 10px;
  --line-of-business-width: 350px;
  --line-of-business-item-width: 150px;
  width: var(--line-of-business-width);
  padding: var(--line-of-business-space);
  display: grid;
  grid-template-rows: auto auto auto auto auto auto;
  grid-template-columns: 100%;
  justify-content: center;
  grid-row-gap: var(--line-of-business-space);
  grid-template-areas:
    "image"
    "title"
    "description"
    "quoteEntry";
}

.productImage {
  grid-area: image;
}

.productName {
  font-size: var(--font-xl);
  text-align: center;
  grid-area: title;
}

.productDescription {
  font-weight: var(--font-weight-light);
  text-align: center;
  grid-area: description;
}

.quoteEntry {
  grid-area: quoteEntry;
}

.startQuote {
  width: var(--line-of-business-item-width);
  margin: auto;
}