@import "gw-common-styles";
// @import "~@jutro/theme/assets/sass/helpers";
.gwToolTip {
  min-width: fit-content;
  background-color: none;
  background: none;
  width: fit-content;
  i{
      font-size: small;
  }
  

  text-align: center;
  margin-top: 5px;
}
.paHeader {
  display: flex;

    h2 {
      font-size: 30px !important;
    }
    padding: 30px 0 0 0; 
    i{
      font-size: medium !important;
  } 
    
  }

  .driverErrorMessage {
    font-size: 15px;
    display: block;
    color: $alfa-red-color;
    margin-bottom: 15px;
    width: 100%;
  
    @include media("<=phonePortrait")  {
      transform: translateY(-30%);
    }
  }

.spanInfoDiv {
  
  color: var(--gw-grey-dark-90-color);
  @include media(">phonePortrait")  {
    //width: 65%;
  }
  @include media("<=phonePortrait")  {
    font-size: 17px;
  }
}

.affinityDiscountLabel{
  font-weight: bold;
  padding-bottom: 10px;
}

.affinityDiv {
  background-color: var(--gw-grey-light-8);
  @include media(">phoneLandscape")  {    
  padding: 20px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  }
  @include media(">tabletLandscape")  { 
  margin: 0px;
  padding-left: 30px;
  padding-bottom: 10px;
  padding-top: 20px;
  max-width: 70rem;
  }
  @include media("<=phoneLandscape")  {
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
    margin: 0px;
  }
}



.affinityToggle {
  width:fit-content;
  background-color: var(--gw-grey-light-8);
  @include media("<=phoneLandscape")  {
    padding-top: 5px;
  }
  @include media(">phoneLandscape")  {
    width:fit-content;
  }
}


.affinityDropDown {
  max-width: 70rem;
  padding-left: 30px;
  padding-bottom: 10px;
  background-color: var(--gw-grey-light-8);
  @include media("<=tabletLandscape")  {
    padding-left: 20px;
  }
}


.affinityDropDown {
  div[class*="jut__GenericSelectControl__control"] {
    background-color: var(--gw-grey-light-8);
    width: 230px;
    padding: 0px; 
    @include media("<=phoneLandscape")  {
      width: 320px;
    }
    @media (max-width: 380px){
      width: 220px;
    }
  }
}

.hideAccordion {
@include media("<=phonePortrait")  {
  display: none;
}
}

.paMessageHeader {
  padding: 10px 0;  
  h3 {
    font-size: 19px;
    font-weight: normal;
    @include media(">tabletLandscape")  {
    width:90%;
    }
    @include media("<=tabletLandscape")  {
      width:90%;
    }
    @include media("<=phoneLandscape")  {
      display: none;
    }
  }
}
.driverContainers {
  max-width: 70rem;
  @include media("<desktopSmall")  {
    width: 100%;
    padding: 0px;
    margin-left: 2px;
  }
   @include media("<phoneLandscape")  {
    margin-left: 0px;
    width : 100%
  }
}
.newDriverContainers {
  margin-bottom: 30px;
  max-width: 70rem;
  @include media("<desktopSmall")  {
    width: 100%;
  }
}
.driverPanel {
  margin: -2px 0px;
  padding: 32px;
  background-color: white;
  border: 1px solid var(--greyscale-boulder-999999, #999);
  border-radius: 0px 0px 5px 5px;
  z-index: -1;

  @include media("<phoneLandscape")  {
    padding: 20px 30px;
  }
}
.addDriverPanel {
  margin: -2px 0px;
  padding: 32px;
  background-color: white;
  border: 1px solid var(--greyscale-boulder-999999, #999);
  border-radius: 0px 0px 5px 5px;
  z-index: -1;

  @include media("<desktopSmall")  {
    margin: -1px 0px 2px 0px;
  }

  @include media("<phoneLandscape")  {
    padding: 20px 30px;
    margin: -1px 0px 0px 0px;
  }
}
.buttonGroupClass {
  position: relative;
  padding: 20px 0 0 0;
}
.cancelButton {
  text-decoration: underline;
}
.addButton {
    position: absolute;
    right: 0;
  }

  .blockCollapse {
    display: block !important;
  }

  .allowCollapse {
    display: none !important;
  }

  .openTile {
    display: block !important;
  }


.driverAge {
  font-size: 12px;
  font-family: 'coresansn';
  font-weight: bold;
  margin-top: -4px
}

.driverName{
  font-size: 16px;
  font-family: 'coresansn';
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.driverNameContainer {
    span {
      display: block;
    }
    @include media("<desktopSmall")  {
      width: 340px;
    }  
    @include media("<phoneLandscape")  {
      width: 220px;
      margin-top: 0px;
    }
}

.driverOnlyNameContainer{
    margin-top: 8px;
    @include media("<desktopSmall")  {
      width: 340px;
      margin-top: 9px;
    }  
    @include media("<phoneLandscape")  {
      width: 220px;
      margin-top: 9px;
    }
}

div[id^="driverPageContainer"] div[class^="jut__Accordion__accordion"] {
   --accordion-border-width: 0px;
}