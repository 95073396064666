@import "gw-common-styles";

.wrapper {
    button {
        --button-primary-bg-color: var(--gw-dark-blue-3);
        --button-primary-hover-bg-color: var(--gw-black-opaque-2);
        --button-primary-active-bg-color: var(--gw-black-opaque-2);
        --button-primary-focus-bg-color: var(--gw-dark-blue-3);
    }

    --gw-custom-landing-page-spacing: 3.5rem;
    --gw-custom-landing-page-section-spacing: var(--gw-custom-landing-page-spacing) auto;

    .layer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .heading {
        font-size: var(--font-xxl);
    }

    .description {
        font-size: var(--font-xl);
    }
}

.firstLayer  {
    --gw-custom-landing-page-padding: 10rem 0;
    background-color: var(--gw-dark-blue-5);
    background: url(../../../public/segmentation-examples/night.jpg) no-repeat 0 0;
    background-attachment: fixed;
    padding: var(--gw-custom-landing-page-padding);

    .strapline {
        color: var(--gw-white);
        font-size: var(--font-xxxl);
    }
    .subline {
        color: var(--gw-white);
        font-size: var(--font-xl);
    }
}

.carousel {
    --gw-custom-landing-page-carousel-height: 500px;
    & > div  {
        height: var(--gw-custom-landing-page-carousel-height);

        & img {
            vertical-align: bottom;
        }
    }
}

.secondLayer {
    --gw-custom-landing-page-second-section-width: 30%;
    --gw-custom-landing-page-second-section-height: 200px;
    width: var(--gw-custom-landing-page-second-section-width);
    height: var(--gw-custom-landing-page-second-section-height);
    margin: var(--gw-custom-landing-page-section-spacing);
    &.layer {
        flex-direction: row;
    }

    .leftSection {
        --gw-custom-landing-page-second-section-image-width: 130px;
        --gw-custom-landing-page-second-section-image-height: 200px;
        width: var(--gw-custom-landing-page-second-section-image-width);
        height: var(--gw-custom-landing-page-second-section-image-height);
        background: url(../../../public/segmentation-examples/graph.svg) no-repeat 0 0;
        background-position-y: center;
    }

    .rightSection {
        --gw-custom-landing-page-second-section-text-width: 80%;
        width: var(--gw-custom-landing-page-second-section-text-width);
    }
}

.thirdLayer {
    background-color: var(--gw-dark-blue-9);
    color: var(--gw-white);
    padding: var(--gw-custom-landing-page-spacing);

    &.layer {
        flex-direction: row;
    }

    .blockTitle {
        --gw-custom-landing-page-third-section-block-width: 225px;
        --gw-custom-landing-page-third-section-block-height: 90px;
        width: var(--gw-custom-landing-page-third-section-block-width);
        height: var(--gw-custom-landing-page-third-section-block-height);
        @extend .layer;

        &:hover {
            background-color: var(--gw-white-opaque-1);
        }

        .blockTitleIcon {
            --gw-custom-landing-page-third-section-block-icon-margin: 2rem;
            font-size: var(--font-xxxl);
            margin-bottom: var(--gw-custom-landing-page-third-section-block-icon-margin);
        }

        .blockTitleText {
            font-size: var(--font-xl);
        }
    }
}

.fourthLayer {
    background-color: var(--gw-blue-light-13);
    color: var(--gw-white);
    padding: var(--gw-custom-landing-page-spacing);

    .fourthLayerContent {
        --gw-custom-landing-page-second-section-max-width: 900px;
        --gw-custom-landing-page-second-section-width: 90%;
        max-width: var(--gw-custom-landing-page-second-section-max-width);
        width: var(--gw-custom-landing-page-second-section-width);

        .heading {
            color: var(--gw-white);
            text-align: center;
        }
    }
}

.fifthLayer {
    margin: var(--gw-custom-landing-page-section-spacing);

    .quoteMark {
        color: var(--gw-dark-blue-9);
        font-size: var(--font-xxs);
        vertical-align: top;
    }
    .quotation {
        --gw-custom-landing-page-quote-margin: 5px;
        margin-left: var(--gw-custom-landing-page-quote-margin);
        font-size: var(--font-xlg);
    }

    .quotationCite {
        font-size: var(--font-m);
        font-style: italic;

        &::before {
            content: '-'
        }
    }
}

.sixthLayer {
    color: var(--gw-white);
    position: relative;
    background-color: var(--gw-blue-light-13);
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        background-image: url(../../../public/segmentation-examples/house_sunset.jpg);
        background-position: center center;
        background-attachment: fixed;
    }

    .sixthLayerContent {
        padding: var(--gw-custom-landing-page-spacing);
    }

    .heading {
        color: var(--gw-white);
        text-align: center;
    }

    .bulletpoint {
        --gw-custom-landing-page-bulletpoint-container-width: 40%;
        --gw-custom-landing-page-bulletpoint-container-columns: 2;
        column-count: var(--gw-custom-landing-page-bulletpoint-container-columns);
        width: var(--gw-custom-landing-page-bulletpoint-container-width);
        margin: auto;

        .bulletPointValue {
            color: var(--gw-white);
        }
    }
}

.seventhLayer {
    margin: var(--gw-custom-landing-page-section-spacing);
}

.eightLayer {
    padding: var(--gw-custom-landing-page-spacing);
    background-color: var(--gw-blue-light-opaque);
}

.ninthLayer {
    background-color: var(--gw-brown);
    padding: var(--gw-custom-landing-page-spacing);
    color: var(--gw-white);
}