@import "gw-common-styles";

.headerContainer {
    width: 100%;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    align-items: center;

    @include media("<phonePortrait") {
        flex-direction: column;
        align-items: center;
    }
}

.disabled {
  opacity: 1;
}

.headerDetails {
    font-size: var(--base-font-size);
    font-family: var(--base-font-family);
    align-items: center;
    color: var(--gw-grey-dark-90-color);
    padding-left: 0;
}

.chevronIcon {
    font-size: var(--font-xxl);
}

.hideActions {
    display: none;
}

.chevron {
    display: none;
}
.checkMark {
  i {
    color: green !important;
  }
}


.headerTitle {
   // display: table-row;;
    font-size: 18px !important;
  }
  .tileHeadingColumn {
   // display: table-cell;
    width: 100%;
    text-align: center;
   // font-weight: 400;
   color: #000000;
   font-weight: bold;

  }
  .selectedHeader {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 16px;
    height: 71px;
    font-family: 'coresansn';
    border-radius: 5px 5px 0px 0px;
    border: 1px solid var(--greyscale-boulder-999999, #999);
    background-color: var(--greyscale-moondust-f-6-f-6-f-6, #F6F6F6);
    position: relative;
    outline: none;
    color: #333333;
    h2 {
      font-size: 18px !important;
  
      @include media('<=phoneLandscape') {
        font-size: 17px !important;
      }
  
    }
    
  }
  .accordionOpen{
    border-radius: 5px 5px 0px 0px;
    background-color: #FFF;
  }
  .accordionClose {
    border-radius: 5px;
    background-color: #FFF;
  }
  .disableTile {
    pointer-events: none;
  }
  .selectedHeaderTitle {
    font-size: 18px;
    display:flex;
    align-items: center;
    color: var(--gw-grey-dark-90-color);
    i {
      margin: 5px 0 0 10px;
      font-size: 18px;
      color: var(--gw-grey-light-4);
      display: table-cell;
      left: 0;
      padding: 5px 16px 5px 0px;
    }
  }
  .headingColumn {
    display: table-cell;
    width: 100%;
    text-align: center;
    font-weight: bold;
  }
  .editRemoveLink {
    display: table-cell;
    right: 1px;
    top: 2px;
    position: absolute;
    font-family: 'coresansn';
    color: var(--gw-alfa-graphite);
    @include media('<=tabletLandscape') {
      bottom: 0px;
      right: 2px;
      display: inline-flex;
      font-size:14px;
  
    }
  
    @include media('>tabletLandscape') { 
      margin: 20px;
    }  
  
    a {
      cursor: pointer;
      text-decoration: none;
      font-size: 14px;
      margin: 20px;
      font-weight: normal;
    }
  
  }