@import "~gw-common-styles";

:root {
    --gw-policy-change-message-margin-top-bottom: 4rem;
    --gw-table-spacing: 20px;
    --gw-policy-change-info-icon-margin-top: 3rem;
    --gw-policy-change-info-icon-margin-left: 2rem;
    --gw-policy-change-info-icon-size: 2rem;
    --gw-policy-change-message-padding: 2rem;
    --gw-policy-change-value-padding-right: 2rem;
    --gw-policy-change-premium-margin-left: 3rem;
    --gw-current-premium-value-padding-right: 4rem;
    --gw-policyperiod-date-vale-width: 183px;
    --gw-policyperiod-range-width: 36px;
    --fieldLabel-text-align-left: left;
}

.gwPolicyChangeMessageContainer {
    background-color: var(--gw-blue-light-9);
    border-color: var(--gw-blue-light-10);
    margin-top: var(--gw-policy-change-message-margin-top-bottom);
    margin-bottom: var(--gw-policy-change-message-margin-top-bottom);
}
.premiumValue {
    display: inline;
    & > div {
        display: inline;
    }
}
.tabMargin {
    @include media("<=tabletPortrait") {
        margin: var(--gw-table-spacing);
    }
}
.gwPolicyChangeInfoIcon {
    color: var(--gw-blue-light-10);
    background-color: var(--gw-blue-light-8);
    height: var(--gw-policy-change-info-icon-size);
    width: var(--gw-policy-change-info-icon-size);
    border-radius: var(--gw-policy-change-info-icon-size);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--gw-policy-change-info-icon-margin-top);
    margin-left: var(--gw-policy-change-info-icon-margin-left);
}
.gwPollicyChangeMessageDetails {
    padding: var(--gw-policy-change-message-padding);
}
.gwPolicyChangeValue {
    text-align: right;
    padding-right: var(--gw-policy-change-value-padding-right);
    font-size: var(--font-xl);
}
.currentPremiumValue {
    padding-right: var(--gw-current-premium-value-padding-right);
}
.changeInCost {
    [class*="CurrencyField"] {
        font-size: var(--font-xl);
    }
}
.gwPolicyChangeLabel {
    color: var(--gw-grey-dark-90-color);
}
.gwPolicyChangePremiumValue {
    --fieldComponent-control-readonly-font-size: var(--font-xxxl);
    font-size: var(--font-xxxl);
    text-align: right;
}
.iconClass {
    border-radius: var(--gw-quoteContainer-icon-border-radius);
    width: var(--gw-quoteContainer-icon-border-width);
    height: var(--gw-quoteContainer-icon-border-height);
    background-color: var(--gw-white-opaque-5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.gwPolicyChangeInfoMessage {
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-m);
    color: var(--gw-grey-dark-90-color);
    background-color: transparent;
    padding: 0;
}

.policyPeriodStyle {
    white-space: nowrap;
    width: var(--gw-policyperiod-range-width);
}

.policyPeriodDateField {
    width: var(--gw-policyperiod-date-vale-width);
}
