// Typeahead
// ---------
.gw-typeahead {
  // --gw-typeahead-zindex: 1051;
  // --gw-typeahead-border-radius: 2px;
  --gw-typeahead-popup-nonselected-text: var(--gw-gray-dark-4);
  --gw-typeahead-active-match-color: var(--gw-grey-dark-90-color);
  --gw-typeahead-highlighted-match-color: var(--gw-black);
  --gw-typeahead-icon-color: var(--gw-grey-light);
  --gw-typeahead-active-match-bg: #e9e9e9;
  --gw-typeahead-icon-hover-color: var(--gw-grey-2);

  --gw-digital-field-component-padding: 0;

 // z-index: var(--gw-typeahead-zindex);
 // margin-top: 2px; // give it some space to breathe
 // border-radius: var(--gw-typeahead-border-radius);
  display: block;
  position: relative;

  &__results {
    display: block;
    right: 0;
    max-height:625px;
    overflow-y: scroll;
  }

  &__match {
    cursor: pointer;
    padding: var(--gw-unit) calc(2 * var(--gw-unit));
    color: var(--gw-typeahead-popup-nonselected-text);

    &_active {
      background: var(--gw-typeahead-active-match-bg);
      color: var(--gw-typeahead-active-match-color);
    }
  }

  &__highlight {
    color: var(--gw-typeahead-highlighted-match-color);
  }

  &__wipeIcon,
  &__searchIcon {
    color: var(--gw-typeahead-icon-color);
  }

  &__wipeIcon {
    cursor: pointer;

    &:hover {
      color: var(--gw-typeahead-icon-hover-color); //Only for clickable icon
    }
  }

  &__loader {
    display: block;
    height: 1px; //Should have some height for text flow purposes, but loader inside is bigger than 1 line, so want it to overflow without affecting height
    font-size: calc(var(--base-font-size) / 2);
  }

  &__loaderInner {
    transform: translateY(-50%);
  }

  &__selection {
    textarea {
      white-space: nowrap;
      overflow-x: hidden;
    }
  }
}

.icon {
  --type-ahead-icon-position: 10px;
  background-color: var(--gw-white);
  border: none;
  color: var(--gw-grey-light-4);
  position: absolute;
  top: var(--type-ahead-icon-position);
  right: var(--type-ahead-icon-position);
  font-size: var(--font-l);
  padding: 0;
}

.clearIcon {
  cursor: pointer;
}

button.icon {
  cursor: pointer;
}

.zeroPadding {
  padding: 0;
}
.autoCompleteLoad {
  background-color: lightgrey !important;
  width: 100%;
  text-align: center;
  color:black;

}

.inputField {
border: none;
position: relative;
width: 100%;
padding-bottom: 1px;

input {
  border-style: none none solid !important;
  border-bottom: 2px solid #999999;
  border-radius: unset !important;
  outline: none;
  -moz-appearance:none;
  width: 100%;
  box-shadow: none !important;
}
}

.highlight {
  color: black;
}