.stepContainer {
    --gw-wizard-mobile-navigation-size: 9px;
    --gw-wizard-mobile-navigation-margin: 7px;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

.navigationButton {
    background-color: var(--GW-COLOR-BACKGROUND-1);
    border: 0;
    padding: 0;
}

.step {
    margin-right: var(--gw-wizard-mobile-navigation-margin);

    &::before {
        content: '';
        display: inline-block;
        min-width: var(--gw-wizard-mobile-navigation-size);
        height: var(--gw-wizard-mobile-navigation-size);
        background-color: var(--GW-COLOR-BRAND-1);
        border-radius: 50%;
        vertical-align: middle;
    }
}

.notVisited::before {
    background-color: var(--GW-COLOR-STROKE);
}

.active::before {
    background-color: var(--GW-COLOR-BRAND-2);
}

.visited::before {
    background-color: var(--GW-COLOR-BRAND-1-GRADIENT);
}

.container {
    margin-left: -5rem;
    margin-top: 3rem;
}
.containerSecondWiz {
 
}
.progressbar li {
    list-style: none;
    display: inline-block;
    width: 23%;
    position: relative;
    text-align: center;
  }
  .progressbar li:before {
    content:"";
    width: 16px;
    height: 16px;
    display: block;
    margin: 0 auto -6px auto;
    border-radius: 50%;
    line-height: 27px;
    background: #bbbdbf;
    text-align: center;
    font-weight: bold;
  }
  .progressbar li:after {
    content: '';
    position: absolute;
    width:58%;
    height: 2px;
    background: #333;
    top: 6px;
    left: -32%;
  }
  .progressbar li:first-child:after {
    content: none;
  }
  .progressbar li {
    &.active::before {
      background: #287D9F;
      height: 20px;
      width: 20px;
      margin: 0 auto -9px auto;
    }
  }
  .progressbar li {
    &.visited:not(.active)::before {
      background: #4D9CBC ;
      width: 16px;
      height: 16px;
      content: '\f00c';
      color: white;
      font-family: 'FontAwesome';
      line-height: 15px;
      font-size: 11px;
    }
  }
  .progressbarStyle {
    text-align: center;
    padding-left: unset;
  }