@import 'gw-common-styles';

.paymentToggle {
    --gw-quote-clause-payment-toggle-width: 50%;
    width: var(--gw-quote-clause-payment-toggle-width);
    margin: auto;
    @include media("<phonePortrait") {
        width: 100%;
    }
}

.quoteTableHeader {
    // position: sticky;
    top: 0;
    z-index: 101; // select icons have a z-index of 100
    display: flex;
    justify-content: space-around;
   // border-bottom: 8px solid var(--gw-blue-light-8);
    //background-color: var(--gw-white);
}

.quoteTableHeaderSection {
    --gw-quote-table-header-section-spacing: 22px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  //  border: 1px solid var(--gw-grey-light-30-color);
    padding: var(--gw-quote-table-header-section-spacing);
    position: relative;

    &:not(:last-child) {
        border-right: none;
    }
}

.quotePriceDifference {
    --gw-quote-clause-table-position: 10px;
    --gw-price-difference-transition-time: 2.5s;
    background-color: var(--gw-orange-3);
    position: absolute;
    top: var(--gw-quote-clause-table-position);
    left: var(--gw-quote-clause-table-position);
    animation: hidePriceDifference 0.5s 1;
    animation-fill-mode: forwards;
    animation-delay: 3s;
}

@keyframes hidePriceDifference {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.paymentText {
    display: inline-block;
}

.quoteTitle {
    --gw-quote-title-margin-left: 0.25rem;
    text-align: center;
    color: var(--gw-grey-dark-90-color);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-ml);
    padding: 0;
    width: auto;
    margin-left: var(--gw-quote-title-margin-left);
}

.warningIconStyle {
    color: var(--gw-orange);
}

.priceContainer {
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 100%;
}

.quoteMonthlyCost {
    text-align: center;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-xlg);
    padding: 0;
    width: auto;
}

.gwQuoteTable {
   // --gw-quote-table-border: 1px solid var(--gw-grey-light-30-color);

    @include media("<phonePortrait") {
        & > div {
            border: var(--gw-quote-table-border);
            border-bottom: none;

            &:last-child {
                border-bottom: var(--gw-quote-table-border);
            }
        }
    }

    & > div:first-child {
         --gw-quote-table-title-bar-sizing: 0px;
        border: none;
        background-color: white;
       // font-weight: 600;
       // font-size: 18px;
         padding: var(--gw-quote-table-title-bar-sizing);
        
        --table-title-color: black;
      //  --table-title-font-size: 18px;

    }

    table {
        background-color: var(--gw-white);
        border-collapse: unset !important;
        border-spacing: 0 !important;

        thead, th {
            display: none;
        }

        tr {
            border: none;

            &:hover {
                background-color: unset;
            }
        }
        td {
            @include media("<=phonePortrait") {
                --card-padding-x: 0px !important;
                padding: 0px !important;
            }
        }
    }
}

td.gwTableCell {
    --table-padding-y: 0;
    --table-padding-x: 0;
  //  --table-cell-border-width: 1px;
   // --table-border-color: var(--gw-grey-light-30-color);
    --table-cell-width: 200px;
    --table-cell-color: black;
    
    width: var(--table-cell-width);
    vertical-align: top;
  //  border: var(--gw-quote-table-border);
  @include media("<=phonePortrait") {
    padding: 0px;

}
  
}

// Phone styles
.accordionContainer {
    --gw-quote-table-phone-sizing: 15px;
    background-color: var(--gw-white-opaque-5);
}

.quoteContainer {
    --gw-quote-table-phone-margin: 10px;
    margin-bottom: var(--gw-quote-table-phone-margin);
}

.accordionStickyHeader {
    --gw-quote-table-border: 1px solid var(--gw-grey-light-30-color);
    --gw-quote-table-phone-border-size: 3px;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-template-areas: "quoteTitle quoteButton"
                                    "quotePrice quoteReset";
    grid-gap: var(--gw-quote-table-phone-sizing);
    padding: var(--gw-quote-table-phone-sizing);
    border: var(--gw-quote-table-border);
    border-bottom: var(--gw-blue-light-2) var(--gw-quote-table-phone-border-size) solid;
}

.mobileStickyHeader {
    position: sticky;
    top: 0;
    background-color: var(--gw-white);
}

.accordionChevron {
    --chevron-margin-y: 0;
}

.accordionQuoteNameContainer {
    grid-area: quoteTitle;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
}

.accordionQuotePriceContainer {
    grid-area: quotePrice;
}

.accordionButtonContainer {
    grid-area: quoteButton
}

.accordionResetContainer {
    grid-area: quoteReset
}

.accordionQuoteName {
    font-size: var(--font-ml);
    font-weight: var(--font-weight-normal-bold);
    padding: 0;
}

.accordionQuotePrice {
    font-size: var(--font-xlg);
    font-weight: var(--font-weight-bold);

    @include media("<phonePortrait") {
        width: auto;
    }
    div[class^='digitalContentContainer'] {
        @include media("<phonePortrait") {
            font-size: var(--font-xxl);
        }
    }
}

.selectedCoveragesContainer {
    padding: var(--gw-quote-table-phone-sizing);
}

.coverageContainer {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.coverageCheck {
    --gw-quote-table-phone-check-margin: 5px;
    color: var(--gw-grey-light-60-color);
    margin-right: var(--gw-quote-table-phone-check-margin);
}

.moreInfoContainer {
    border-top: 2px var(--gw-grey-light-30-color) solid;
    padding: var(--gw-quote-table-phone-sizing);
}

.moreInfo {
    cursor: pointer;
    color: var(--gw-blue-light-8);
}
.gwAlert{
	background-color: var(--gw-orange-6);
	border: 1px solid var(--gw-orange-7);
	padding: 16px;

	.referToUWFormActions {
		display: flex;
		justify-content: space-between;
	}

	.UWFormActions {
		display: flex;
		justify-content: flex-end;
	}
}

.accordionCardQuote {
    margin: 0;
}

.quoteOuter{
    max-width: 64rem;
    padding-top: 25px;
    @include media('<=tabletLandscape') {
        width: 100%;
      }
      @include media('<=phonePortrait') {
        width: 100%;
        padding: 0 10px;
      }
  }

  .quoteTableCaption {
    padding: 20px;
    display:contents;
    font-size: 12px;
    color: #333333;
    text-align: left;
    @include media('>tabletLandscape') {        
        margin-left: 10px;
    }
  }
  .monthStyle {
      font-size: 25px;
  }
  

  .quoteInfo{
    background:linear-gradient(45deg,lightblue,white);
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    color: #000000;
    height: 300px;
    font-weight: 600;
    box-shadow: -2px 1px 8px rgba(94,90,90,0.5);
    @include media('<phoneLandscape') {
        height: 250px;
        padding: 5px;
      }
  }
  .quoteDiv{
    padding: 5px;
    @include media('<phoneLandscape') {
    padding: 1px;
    }
  }
  .quoteMainDiv {
      padding: 10px;
  }
  .quoteAmount{
    font-size: 35px;
    font-weight: bold;
    @include media('<phoneLandscape') {
       font-size: 25px;
      }
  }
  
  .quoteDesc{
    font-size: 25px;
  }
  .tableClass {
      display: flex;
  }
  
  .quoteAmountDiv{
    font-size: 20px;
    padding: 5px;
    @include media('<phoneLandscape') {
      font-size: 15px;
    }
  }
  .quoteButtons{
    display: flex;
    padding: 5% 20%;
    @include media('<phoneLandscape') {
      padding: 5% 5%;
        }
  }
  .quoteButton{
    padding: 0px 20px;
    @include media('<phoneLandscape') {
        padding: 0px 15px;
        }
  }
  .quoteSecondaryButton {
      border: 1px solid #287D9F;
      color: #287D9F;
  }
  .quoteTableContainer {
    //border: 1px solid lightblue;
    border-top: none;
    padding: 0 10px;
}
.tableDiv {
    // padding:10px;
}
.quoteError {
    color: #C30717;
    @include media('<=phonePortrait') {
        font-size: 12px;
    }
}
.quoteTableHeader {
    font-size: 21px;
    font-weight: 600;
    text-align: left;
    justify-content: left;
    padding-top: 20px;
    color:  #000000
}
.gwQuoteTableHeader {
    border: none;
    background-color: white;
    font-size: 18px;
    font-weight: 600;
    padding-top:10px;
    color: black;
    float: left;
}

.quoteButtonGrid {
    --clause-top-margin: 20px;
    --clause-side-margin: 25%;
    //padding: 5% 20%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include media('<=phoneLandscape') {
        --clause-side-margin: 20px;
        margin: 10px var(--clause-side-margin) 10px var(--clause-side-margin);  
      }
      @include media('>phoneLandscape', '<=tabletLandscape') {
        --clause-side-margin: 20%;  
      }
      @media only screen and (min-width: 1005px) and (max-width: 1090px) {
        margin: var(--clause-top-margin) var(--clause-side-margin) 20px var(--clause-side-margin);
      }
    grid-template-rows: auto;
    grid-template-areas: 'left right';
    margin: var(--clause-top-margin) var(--clause-side-margin) 40px var(--clause-side-margin);

}

.quoteGridSlotLeft {
    grid-area: left;
    width:120px;
    min-width: 64px;
    margin: auto;
    font-weight: bold;
    @include media('<=phoneLandscape') {
        width: 120px;
      }
    
}

.resetQuoteGridSlotLeft {
    grid-area: left;
    width:120px;
    min-width: 64px;
    margin: auto;
    font-weight: bold;
    @include media('<=phoneLandscape') {
        width: 120px;
      }
    span{
        margin-right: 18%;
    }
    
}

.recalculateSpan {
    span{
        margin-right: 18%;
    } 
}

.quoteGridSlotRight {
    grid-area: right;
    width:120px;
    min-width: 64px;
    margin: auto;
    font-weight: bold;
    @include media('<=phoneLandscape') {
        width: 120px;
      }
    @media only screen and (min-width: 1006px) and (max-width: 1191px) {
        margin-left: 10px
    }
}
.loaderStyle {
    position: absolute;
    margin-left: 48rem;
    @include media('<=phoneLandscape') {
        margin-left: 27rem;
    }
    @include media('>phoneLandscape', '<=tabletLandscape') {
        margin-left: 42rem;
    }
}
.viewCovStyle {
    margin-top: 2%;
    background: linear-gradient(45deg, white, white);
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    color: #287D9F;
    box-shadow: -2px 1px 8px rgba(94, 90, 90, 0.5);
    div > div > span[class*="jut__Accordion__header"] {
        left: unset;
        top: unset;
    }
    div + div > div[class*="jut__Accordion__cardBody"] {
        margin: unset;
    }
}
.openAccordion {
    display: block;
}
.collapaseAccordion {
    display: none !important;
}
.textAlignStyle {
    text-align: center;
}