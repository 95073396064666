.policyValues {
    display: flex;
    justify-content: space-between;
}

.oldValue {
    color: var(--GW-COLOR-TEXT-2);
    text-decoration: line-through;
    margin-right: var(--GW-SPACING);
}

.newValue {
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
}