@import "~gw-common-styles";

.stepsNav {
  display: flex;
  justify-content: center;
}

.navigationButton {
  --gw-nav-buttons-margin: 4px;
  --gw-nav-buttons-padding: 8px;
  --gw-nav-buttons-size: 16px;
  --gw-nav-buttons-active-step-color: var(--gw-white);
  --gw-nav-buttons-active-step-bg: var(--gw-lime-green);

  display: block;
  color: inherit;
  cursor: pointer;
  font-weight: bold;
  border-radius: 50%;
  background: inherit;
  text-align: inherit;
  width: var(--gw-nav-buttons-size);
  height: var(--gw-nav-buttons-size);
  margin: var(--gw-nav-buttons-margin);
  padding: var(--gw-nav-buttons-padding);
  background-color: var(--gw-grey-light-2);
  border-color: var(--gw-grey-light-31-color);

  &.active {
    position: relative;
    color: var(--gw-nav-buttons-active-step-color);
    background-color: var(--gw-nav-buttons-active-step-bg);
  }
}
