@import 'gw-common-styles';

.landingPageContainer {
  
  --gw-qnb-landing-page-content-width:  100%;
 width: var(--gw-qnb-landing-page-content-width);
  margin: 0 auto;
  @include media('<=phonePortrait') {
    margin-top: 20px;
  }
  @include media('>=tabletLandscape') {
    margin-top: 20px;
    
  --gw-qnb-landing-page-content-width: 80%;
  }
}

.lobTypeToggle {
  --landing-page-toggle-width: 50%;
  --landing-page-toggle-padding: 25px;
  --landing-page-toggle-margin: auto;
  @include media("<phonePortrait") {
    --landing-page-toggle-width: 100%;
    --landing-page-toggle-margin: 0;
  }
  width: var(--landing-page-toggle-width);
  padding: var(--landing-page-toggle-padding) 0;
  margin: auto;
}

.lobTypeContainer {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  div > form > div > div > input {
    font-size: 16px;
    color: var(--gw-alfa-graphite) !important;
  }
}
.displayNone {
  display: none;
}

.retrieveQuoteContainer {
  --retrieve-quote-container-padding: 64px 16px 0;
  --retrieve-quote-container-width: 250px;

  height: auto;
  display: flex;
  margin: 0 auto;
  text-align: center;
  flex-direction: column;
  padding: var(--retrieve-quote-container-padding);
  width: var(--retrieve-quote-container-width);
  @include media("<=phoneLandscape") {
    --retrieve-quote-container-width: 100%;
  }
  
}

.imageContainer{
  --retrieve-quote-container-width: 250px;

  height: 165px;
  width: 340px;

  @include media('<phonePortrait'){
    width: 240px;
    height: 140px;
    margin-bottom: 20px;;
  }
  height: auto;
  display: flex;
  margin: 0 auto;
  text-align: center;
  flex-direction: column;
  @include media('>phonePortrait') {
    padding-bottom: 100px;
  }
 }

.gwPageTitle {
  text-align: center;
  margin-top:40px;

  h1 {
    font-size: 36px;
    color: var(--gw-alfa-graphite);
    @include media('<tabletPortrait') {
      font-size: 35px;
    }

    @include media('>=tabletPortrait','<=tabletLandscape') {
      margin: 30px;
    }
  }

  h3 {
    font-size: 24px;
    color: var(--gw-alfa-graphite);
    margin: 32px;
    @include media('<tabletPortrait') {
      margin: 25px 25px 0 25px;
    }
  }
}
.alphaVehicleImg{
  height: 165px;
  width: 340px;

  @include media('<phonePortrait'){
    width: 240px;
    height: 140px;
    margin-top: 30px;
  }
}

.cookieMessageContainer {
  margin-left: -10%;
  margin-right: 0%;
  margin-top: 0%;
  margin-bottom: 0%;
  position: fixed;
  z-index: 1000;
  bottom: 0px;
  height: 187px;
  width: 100%;
  background: #D7E9F4;
  @include media('<tabletPortrait') {
    margin-left: 0px;
  }
  @include media('>=tabletPortrait','<=tabletLandscape') {
    margin-left: 0px;
  }  
}

.cookieMsgCloseLink {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  width: 15px;
  height: 15px;
  outline: none;
  outline-color: initial;
  outline-style: none;
  outline-width: initial;
  &::before{
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    position: absolute;
    content: ' ';
    height: 1px;
    width: 21px;
    background-color: black;
  }

  &::after{
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    position: absolute;
    content: ' ';
    height: 1px;
    width: 21px;
    background-color: black;
  }
}

.cookieMessageTextDiv {
  max-width: 100%;
  position: absolute;
  top: 25%;
  padding: 24px 60px;
  line-height: 1.4;
  font-weight: 300;
  font-size: 16px;
  color: #333333;
  margin-top: 0%;
  margin-right: 0%;
  margin-bottom: 0%;
  margin-left: 0%;
  word-spacing: 1px;
  -webkit-font-smoothing: antialiased;
  @include media('<tabletPortrait') {
    left: 5%;
    font-size: 12px !important;
    padding: 0px;
    max-width: 80%;
    // line-height: 1.43;    
  }
  @include media('>=tabletPortrait','<=tabletLandscape') {
    left: 10%;
    max-width: 70%;
    padding: 0px;
    margin: 0;
  }  
}

.privacyPolLink {
  font-style: normal;
  line-height: 1.4;
  font-weight: light;
  font-size: 16px;
  text-decoration: underline;
  color: #333 !important;
  text-underline-position: under;
  @include media('<tabletPortrait') {
    font-size: 12px !important;
    line-height: 1.43;    
  }
  @include media('>phoneLandscape','<=tabletLandscape') {
  }  
}
