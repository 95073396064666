@import "~gw-common-styles";

.imageContainer {
  --gw-vehicle-image-container-padding: 1.5rem;
  padding-bottom: var(--gw-vehicle-image-container-padding);
}

.vehicleImage {
  --gw-vehicle-image-min-width: 360px;
  --gw-vehicle-image-width: 360px;
  --gw-vehicle-image-height: 270px;
  --gw-vehicle-padding-bottom: 20px;

  @include media("<phonePortrait") {
    --gw-vehicle-image-min-width: 100%;
  }

  min-width: var(--gw-vehicle-image-min-width);
  width: var(--gw-vehicle-image-width);
  height: var(--gw-vehicle-image-height);
  padding-bottom: var(--gw-vehicle-padding-bottom);
}

.yearErrorMessage {
  font-size: 14px;
  display: block;
  color: $alfa-red-color;
  width: 200%;
  @include media("<=phonePortrait") {
    width: 150%;
  }
   
}
.floatInputError {
  div[class*='jut__GenericSelectControl__control'] {
    border-bottom: 2px solid $alfa-red-color !important;
}
}
.imageInfoContainer {
  grid-area: field;
}

.vinInfoText {
  --vin-info-text-top-margin: 24px;
  margin-top: var(--vin-info-text-top-margin);
}
.vinSelection {
  text-align: center;
}

.vinInfoSpan {
  width: 320px !important; 
  padding-bottom: 4px;
  @include media("<=phoneLandscape") {
    width: 100% !important
  }
  &:first-child {
  border-bottom: 2px solid grey;
  font-weight: normal;
  font-size: 18px;
  width:21rem;
  letter-spacing: 0.5px;
  color: #373737;
 
  }
}

.vinInfoDivText {
  margin-top: 4px;
  color: var(--gw-alfa-graphite);
  font-size: 14px;
  display: inline-block;
  transform: translateY(-20%);
}


.vinContainer {
  width:100%;
    font-size: 11px;
    color: var(--gw-alfa-graphite);
  a {
    font-size:  11px;
    color: #287D9F;
    margin-bottom: 1px;
  }
}
.gwToolTip {
  @include media("<=phoneLandscape") {
    display: none;
  }
  min-width: fit-content;
  background-color: white;
  margin: 10px 10px 0px 10px;
  background: none;
  width: fit-content;
  i{
      font-size: small;
  }
  text-align: center;
}
.iconFontStyle {
  font-size: unset;
  margin: 10px;
}


.vinButton {
  margin-left: 5px;
  @include media('<=phonePortrait') {
  margin-left: 0px;
  margin-right: 10px;
  padding: 10px 20px 10px 20px;
  }
  
}
.errorRef {
  font-size: 14px !important;
  text-decoration: underline;
  cursor: pointer;
}

.errorMessage {
  font-size: 13px;
  display: block;
  color: $alfa-red-color;
  transform: translateY(-20%);
  width: 340px;
  font-family: 'coresansn';
  @include media("<=desktopSmall")  {
      width: 300px;
    }
  @include media('<=phoneLandscape') {
    transform: translateY(-30%);
    width: 280px;
    margin-top: 10px;
    margin-bottom: -20px;
  }
}
.vinLabel {
  font-size:11px;
  color: var(--gw-grey-dark-90-color);
}

.vinAddContainer {
  display: flex;
  display: -webkit-flex;
  padding: 20px 0 0 0;
  input {
    text-transform: uppercase;
    @include media("<tabletPortrait"){
      width: 260px;
    }
    @include media(">=tabletPortrait","<tabletLandscape"){
      width: 260px;
    }
    @include media(">=tabletLandscape","<desktopSmall"){
      width: 245px;
    }
   @include media(">=desktopSmall","<desktopLarge"){
      width: 275px;
    }
   @include media(">=desktopLarge"){
    width: 325px;
    }
  }
}
.addressControlVehicle{
  display: block;
}
.buttonGroupClass {
  position: relative;
}
.cancelButton {
  
  font-weight: normal;
  font-size: 15px;
  text-align: left;
  >span{
    text-decoration: underline;
    display: flex;
    margin-left: -25px;
  }
}
.addButton {
    position: absolute;
    right: 0;
    font-weight: bold;
    color: #FFFFFF;
    font-size: 16px;
}
.toggleControlClass {
  width: 240px;
  button[class*="digitalToggleActive"] {
    background-color: #CCCCCC ;
   }
  @include media('>phoneLandscape') {
  width: 240px;
  }
}
.radioContainer {
  @include media(">phoneLandscape") {
    display: flex;
    display: -webkit-flex;
  }    
  @include media(">tabletLandscape") {
  width: 80%;
  }
  padding: 10px 0;
  position: relative;
}
.spanInfoDiv {
  position: relative;
  margin-right: 15px;
  font-family: 'coresansn';
  color: #333333;
}
.radioSpan {
  color: var(--gw-gray-dark-7);
  width: 160px;
  margin-top: 30px;
  display: flex;
  button[class*="digitalToggleActive"] {
    background-color: #CCCCCC ;
   }
}
.toggleOtherControlClass {
  @include media('>phoneLandscape') {
  width:30%;
  }
}
.toggleLabelName {
  font-size: 15px;
  font-family: 'coresansn';
  margin-bottom: 10px;
}
.garageAddressSpan {
  position: relative;
  bottom: 3rem;
  display: none;
  @include media('<=phoneLandscape') {
    position: unset;
    bottom: unset;
  }
  @include media(">phoneLandscape") {
     display: block;
  }
  @include media('>tabletLandscape') {
   display: flex;
  }
}
.garageAddressMobileSpan {
  position: relative;
  bottom: 3rem;
  @include media('<=phoneLandscape') {
    position: unset;
    bottom: unset;
  }
}
.mobileGarage{
  background-color: #F6F6F6;
  width: fit-content;
}
.mobileGarageOpacity{
  background-color: #F6F6F6;
  width: fit-content;
  opacity: 0.6;
}
.mobileAddressVisible {
  @include media(">phoneLandscape") {
    display: none;
}
margin-top: 16px;
margin-bottom: 20px;
padding: 16px;
}
.mobileRadio {
  padding: 0px !important;
}
.garagingAddressSpan {
  background-color: #F6F6F6;
  font-size: 18px;
  text-transform: uppercase;
  font-family: 'coresansn';
  max-width: 284px;
  color: #333333;
  @include media('>phoneLandscape') {
    max-width: 382px;
    display: block;
    padding: 16px;
    margin-top: 16px;
    margin-right: 10px;
  }
  @include media('>tabletLandscape') {
    margin-top: 16px !important;
    max-width: 382px;
  }
}

.garagingAddressSpanOpacity {
  background-color: #F6F6F6;
  font-size: 18px;
  text-transform: uppercase;
  opacity: 0.6;
  max-width: 284px;
  color: #333333;
  @include media('>phoneLandscape') {
    padding: 16px;
    max-width: 382px;
    margin-top: 16px;
    margin-right: 10px;
  }
  @include media('>tabletLandscape') {
    margin-top: 16px !important;
    max-width: 382px;
  }
}

.vinYmmInputDiv {
  width:30rem !important;
  @include media('<=phoneLandscape') {
   width: 100% !important;
    padding-right: 0;
  }
}

.desktopDisplay {
  @include media('<phoneLandscape') {
  display: none;
  }
}

div[id^="vinInfoDiv"] button[class^="digitalButton"] {
        min-width: 121px
  }
