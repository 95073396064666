@import "breakpoint";
@import "~font-awesome/css/font-awesome.min.css";


$alfa-red-color: #c30717 !default;
$alfa-btn-active: #4d9cbc !default;
$alfa-btn-hover: #387289 !default;
$alfa-btn-click: #58b1d5 !default;
$alfa-btn-disable: #8db6c7 !default;
$alfa-btn-box-shadow: #abc9da !default;
$alfa-btn-box-hover: #f6f6f6 !default;

$alfa-font-color : #333 !default;
$alfa-grey-color : #999999 !default;
$alfa-grey-font-color: #5f574f !default;

$alfa-font-light-color : #999999 !default;