@import "~gw-common-styles";

:root {
    --gw-quote-change-accordion-title-margin: 30px;
    --gw-font-size-accordion-header: 2.4rem;
}
.chevronStyle {
    --gw-chevron-top: .4rem;
    top: var(--gw-chevron-top);
}
.accordionTitle {
    margin-left: var(--gw-quote-change-accordion-title-margin);
}
.accordionMainTitle {
    margin-right: var(--gw-quote-change-accordion-title-margin);
    font-size: var(--font-xlg);
}
.renderAccordionHeader {
    .title {
        font-size: var(--gw-font-size-accordion-header);
    }
}
.titleSeparator {
    --gw-quote-change-separator-margin-top-bottom: 1rem;
    margin: var(--gw-quote-change-separator-margin-top-bottom) 0;
}
.cardBodyMainMargins {
    --gw-quote-change-accordion-card-body-margin: 0.5rem;
    margin: var(--gw-quote-change-accordion-card-body-margin);
}
.cardBodyMargins {
    --gw-quote-change-accordion-card-body-margin: 0.5rem;
    --gw-quote-change-accordion-card-body-margin-left: 2rem;
    margin: var(--gw-quote-change-accordion-card-body-margin) 0;
    margin-left: var(--gw-quote-change-accordion-card-body-margin-left);
}
.gridMargin {
    --gw-quote-change-grid-margin-left: 3rem;
    --gw-quote-change-grid-width: 99%;
    margin-left: var(--gw-quote-change-grid-margin-left);
    width: var(--gw-quote-change-grid-width);
    
    .rootLabel{
        font-weight: var(--font-weight-bold);
    }
}
.gridColumn {
    --gw-quote-change-grid-column-padding-right: 2.5rem;
    text-align: right;
    padding-right: var(--gw-quote-change-grid-column-padding-right);

    &:last-child {
        font-weight: var(--font-weight-bold);
    }
}
.gridTitle {
    --gw-quote-change-title-border-bottom: 1px;
    --gw-quote-change-title-margin-bottom: 1rem;
    --gw-quote-change-title-padding-bottom: 0.5rem;
    --gw-quote-change-title-padding-left: 2rem;
    border-bottom: var(--gw-quote-change-title-border-bottom) solid var(--gw-grey-light-30-color);
    margin-bottom: var(--gw-quote-change-title-margin-bottom);
    padding-bottom: var(--gw-quote-change-title-padding-bottom);

    &:first-child {
        padding-left: var(--gw-quote-change-title-padding-left);
    }

    &:not(:first-child) {
        text-align: right;
    }
}