@import "~gw-common-styles";

.dateDivContainer {
display: flex;
display: -webkit-flex;
}

.dateInput {
  input {
  width: 70px !important;
  ::-webkit-input-placeholder { /* Chrome */
    color: --gw-grey-2 !important;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: --gw-grey-2 !important;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: --gw-grey-2 !important;
  }
  :-moz-placeholder { /* Firefox 4 - 18 */
    color: --gw-grey-2 !important;
    opacity: 1;
  }
  }
  width: auto !important;
  div[class*="jut__FieldMessage__error"] {
    display: none;
  }
  input[class^="jut__InputField__input invalid"] {
    color: unset;
    border-color: #999999;
  }
  
}




.dateSeparator {
  font-weight: 300;
  font-size: 16px;
  margin: 5px;
  color: #000000;
}