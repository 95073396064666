@import '~gw-common-styles/foundation';
@import '~gw-common-styles/components/widgets';


:root {
  --gw-tablet-sidebar-width: 20rem;
}

html {
  --gw-header-image: url("../../public/Alfa_Insurance_logo.svg");
  --gw-mobile-header-image: url("../../public/Alfa_Insurance_logo.svg");
  font-size: 10px;
}

.qnbMain {
  background-color: var(--GW-COLOR-BACKGROUND-1);
  min-height: 100vh;
}


.qnbMainContent {
  min-height: 100vh;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;

  &::before, &::after  {
    display: none
  }
}
.tabletView{
  @include media('<=phoneLandscape') {
    display: none;
    }
}
.phoneView{
  @include media('>phoneLandscape') {
    display: none;
    }
    
}
.discountText{
  font-size: 14px;
  font-weight: bolder;
}
.verticalLine{
  :after {
    background-color: #333;
    content: '';
    position: absolute;
    width: 1px;
    height: 12%;
    margin-left: 0.60%;
      @include media('<=phoneLandscape') {
        height: 2% !important;
        margin-left: 2.5% !important;
        margin-top: 1% !important;
        }
        @include media('<=tabletLandscape') {
          margin-left: 1.5%; 
          height: 12%; 
        }

	}
}
.manualDiscount{
  font-weight: 300;
  font-size: 12px;
  color: #333333;
  padding: 10px;
  line-height: 125%;
}


.mobiledisDiv{
  position: absolute;
    top: 50px;
    right: -2%;
    width: 225px;
    margin: 10px 20px;
    height: auto;
    border: 1px solid grey;
    background-color: white;
    z-index: 9999;
    color: black;
}
.basicTableCell {
  border: none;
  color: black;
}

.mobileGrid{
  display:flex
}
.mobiledisplayHeader{

  margin-left: 40px;
}



.faqPage {
  width: 70%;
  margin: auto;
}
.gw-check-answers {
  padding:24px 5px;
}
.gwDiscountHeading {
  height: 42px;
  padding: 5px 0;
  background-color: #ececeb;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  display: flex;
  
}
.gwDiscountDiv {
  width: 90%;
  margin-left: 50px;
  border-style: groove;
  box-shadow: 1px 1px 3px #c9c9c9;
  border-radius: 8px;
  @include media( ">tabletLandscape") {
    display: none;
   }
   @include media('<=phoneLandscape') {
   margin: 15px;
   position: relative;
   }
   margin-bottom: 10px;
   @include media( ">tabletPortrait","<=tabletLandscape") {
      position: relative;
   }  
}

.gw-verified-img{
background : url("../../public/siteseal_verified_logo.gif") no-repeat 0 0;
width:180px;
height:40px !important;
cursor: pointer;
}
.buttonSave{
  //background-color: transparent;
  
  width: 150px;
  height:300px;
  border: none;
  background: none;
  color: #000;
  font-size: 1.7rem;
    
}
.mobileButtonSave{
  color:#26a9e0;
  font-size:24px;
  width: 150px;
  height:300px;
  border: none;
  background: none;
}

.alterButton{
color:black;
border:none;
height:50px;
background: none;
}

.toastContent{
  background: #99ddff;  
}

.gw-contactNumber{
  color: #26a9e0;
}

.phone-link-text {
  text-decoration: none !important;
}
.phone-link-text-gtm { 
  text-decoration: none !important; 
  pointer-events: none;
}

.gw-further-questions {
  padding:24px 5px;
  cursor: auto;
}
.gw-check-answers, .gw-further-questions {
  &:hover {
    background: lightgray !important;
}
}

.gw-check-answers a, .gw-further-questions a {
  color: #000;
  font-size: 1.7rem;
}

.gw-check-answers a:hover, .gw-check-answers span:hover {
  text-decoration : none;
}

.gw-further-questions a:hover, .gw-further-questions span:hover{
  text-decoration : none;
  cursor:auto;
}


.gw-header-border{
  @include media('<=tabletLandscape'){
    display: none;
   }
   background-color: $alfa-red-color;
   background-image: linear-gradient(to right, $alfa-red-color , white);
   height: 6px;
}

.mobile-header-border {
  border-bottom: 1px solid  $alfa-red-color !important;
  height: 79px !important;
}

.printQuote{
  @include media('>tabletLandscape'){
    display: none;
   }

}

.Toastify__toast-container--top-center {
  top: 4em !important;
  margin-left: 5px !important;
  width:100% !important;
    @include media('<=tabletLandscape'){
    top: 70px !important;
  }
  @include media('<=phoneLandscape'){
  	width: 100% !important;
    left: 0% !important; 
    top: 75px !important;
  }
}
.alfabody {
  text-align: center;
  font-weight: 300;
  font-size: 20px;
  color: #000000;

  @include media('<=phoneLandscape'){
      max-width: 320px;
    }
}

.mockalfa {
  background-color: #D7e9f4 !important;
  color: black !important;
  font-size: 1.5rem;

    @include media('<=phoneLandscape'){
      font-size: 1.2rem;
      height: 0px;
      margin-top: 0px;
      min-height: 68px !important;
      }

    @include media('<=tabletLandscape'){
      margin-top: 2px;      
    }
}

.desktop-header-border {
  @include media('<=tabletLandscape'){
    display: none;
  }
  background-image: linear-gradient(to right, #c30717 , white);
  margin-bottom: 2rem;
  padding-bottom: 1rem !important;
  background-size: 100% 7px;
  background-position: bottom 0 left 0,bottom 10px left 0;
  background-repeat: no-repeat;
}



.gwHamburger{
  color:#373737;
}


.gw-footer-grid{
  padding: 0px 40px;
  margin-top:-25px !important;
   a{
    text-decoration: none;
    font-size:78%;
    word-spacing:5px;
    color: #000!important;
  }
  a:hover{
    text-decoration: underline;
  }  
  @include media('>=phoneLandscape','<=tabletLandscape'){
    grid-template-columns: 60% 5% 35% !important;
    padding: 0px 40px 0 0;
  }
 
}

.gw-social-image {
  width :35px;
  height:35px;
}
.gw-piggy {
  margin-right: 20px;
}
.removeUnderline{
  a{
    text-decoration: none;
  }
}
.removeUnderlineSpan span{    
  text-decoration: none !important;
}

.gw-phone-security-logo {
  text-align:center;
  margin: auto;
}
.gw-connect-with-us{
  font-size:1.5rem;
  color: #000;
  font-weight: bold;
}
.check-answers {
  align-self: center;
}

// theme

$fonts-root: "../assets/fonts";
@import "../assets/fonts/fonts";

// ------------------------------------
//   GROUNDED CSS MODULES
// ------------------------------------
@import "~@jutro/theme/assets/fonts/fonts";
@import "~@jutro/theme/groundedStyles";

// ------------------------------------
//   COMMON CSS MODULES
// ------------------------------------
@import "~@jutro/components/hashedStyles";
@import "~@jutro/theme/hashedStyles";
@import "~@jutro/layout/hashedStyles";

@import "~@jutro/datatable/hashedStyles";
@import "~@jutro/router/hashedStyles";

// ------------------------------------
//   DIGITAL CSS MODULES
// ------------------------------------
@import "~gw-digital-theme-styles/DigitalTheme";

// ----------------------------------------
//   BRANDING CSS MODULES
// ----------------------------------------
@import "../styles/branding/millennials-demo.scss";
