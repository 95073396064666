@import "gw-common-styles";


.driverHeaderContainer {
    width: 100%;
    margin-top: 35px;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    align-items: center;

    @include media("<phonePortrait") {
        flex-direction: column;
        align-items: center;
    }

    @include media("<desktopSmall")  {
        width: 100%;
    }
}

.driverDetails {
    font-size: var(--base-font-size);
    font-family: var(--base-font-family);
    align-items: center;
    padding-left: 0;
}

.driverActions {
    --driver-actions-width: 125px;
    --driver-actions-margin: 70px;

    @include media("<phonePortrait") {
        --driver-actions-margin: 0;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: var(--driver-actions-width);
    margin-right: var(--driver-actions-margin);
}

.chevronIcon {
    font-size: var(--font-xxl);
}

.hideActions {
    display: none;
}

.chevron {
    display: none;
}
.checkMark {
  i {
    color: green !important;
  }
}

.driverHeader {
  justify-content: center;
  align-items: center;
  background-color: var(--greyscale-moondust-f-6-f-6-f-6, #F6F6F6);
  position: relative;
  outline: none;

  h2 {
    font-size: 18px !important;
  }
 
}

.driverHeaderClosed {
  border: 1px dashed var(--greyscale-boulder-999999, #999);
  border-radius: 5px;
  color: #757575;
  
  &:hover {
    border: 1px solid var(--greyscale-boulder-999999, #999);
    color: #333333;
  }
}

.driverHeaderOpen {
  border: 1px solid var(--greyscale-boulder-999999, #999);
  border-radius: 5px 5px 0px 0px;
  
}

.driverHeaderTitle {
    display: flex;
    font-size: 18px;
    padding: 16px;
    height: 71px;

    i {
      margin: 12px 16px 15px 0px;
      font-size: 18px;
      display: table-cell;
      left: 0;
    }
  }
  .driverHeadingColumn {
    display: flex;
    width: 100%;
    text-align: left;        
    font-weight: bold;
    @include media("<phoneLandscape")  {
    padding-top: 0px;
  }
  }
  .driverHeadingColumnNewDriver {
    display: flex;
    width: 100%;
    text-align: left;        
    font-weight: bold;
    font-size: 16px;
    font-family: 'coresansn';
    padding-top: 8px;
  }
  .selectedDriverHeader {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    outline: none;
    color: #333333;
    border: 1px solid var(--greyscale-boulder-999999, #999); 
    h2 {
      font-size: 18px !important;
  
      @include media('<=phoneLandscape') {
        font-size: 17px !important;
      }
  
    }
    
  }
  .selectedDriverHeaderClosed {
    border-radius: 5px; 
  }

  .selectedDriverHeaderOpen {
    border-radius: 5px 5px 0px 0px; 
  }
  
  .selectedDriverHeaderTitle {
    font-size: 18px;
    display:flex;
    height: 71px;
    padding: 16px;

    i {
      margin: 5px;
      font-size: 18px;
      display: flex;
      left: 0;
      margin: 10px 16px 15px 0px;
    }
  }
  .editRemoveLink {
    display: table-cell;
    right: 1px;
    position: absolute;
    color: var(--gw-alfa-graphite);
    font-family: 'coresansn';
    @include media('<=tabletLandscape') {
      bottom: 20px;
      display: inline-flex;
      font-size:14px;
      margin-right: 10px;
  
    }
  
    @include media('>tabletLandscape') {
      top: 0;    
      margin: 25px;
    }  
  
    a {
      cursor: pointer;
      text-decoration: none;
      font-size: 16px;
      margin: 3px;
      @include media('>tabletLandscape') {
        margin: 5px;
      }
      font-weight: 300;
    }
  
  }

  .newDriverHeader{
    flex-direction: row;
    background-color: var(--greyscale-moondust-f-6-f-6-f-6, #F6F6F6);;
    justify-content: center;
    align-items: center;
    height: 71px;
    position: relative;
    outline: none;

   
  
    h2 {
      font-size: 18px !important;
  
      @include media('<=phoneLandscape') {
        font-size: 17px !important;
      }
  
    }
  }

  .newDriverHeaderClosed{
    border: 1px dashed var(--greyscale-boulder-999999, #999);
    border-radius: 5px;
    color: #757575;
    
    &:hover {
      border: 1px solid var(--greyscale-boulder-999999, #999);
      color: #333333;
    }
  }

  .newDriverHeaderOpen{
    border: 1px solid var(--greyscale-boulder-999999, #999);
    border-radius: 5px 5px 0px 0px;
    
  }