/* ------------------------------------*\
   CSS Custom Properties to define the default color values
\*------------------------------------ */
:root {
    --gw-turquoise: #00acba;
    --gw-turquoise-darken: #007d87;
    --gw-turquoise-opaque: rgba(31, 146, 178, 0.05);
    --gw-black: #000;
    --gw-black-1: #28333f;
    --gw-black-2: #222222;
    --gw-black-opaque: rgba(0, 0, 0, 0.15);
    --gw-blue: #0ff;
    --gw-blue-2: #5ca1df;
    --gw-blue-3: #659c90;
    --gw-blue-4: rgba(92, 161, 223, 0.16);
    --gw-blue-5: #38c;
    --gw-blue-6: #657db6;
    --gw-white: #fff;
    --gw-white-1: #F2F2F2;
    --gw-white-opaque: rgba(255, 255, 255, 0.8);
    --gw-white-opaque-1: rgba(255, 255, 255, 0.1);
    --gw-white-opaque-2: rgba(255, 255, 255, 0.9);
    --gw-white-opaque-3: rgba(255, 255, 255, 0.5);
    --gw-white-opaque-4: rgba(255, 255, 255, 0.16);
    --gw-white-opaque-5: #ececeb;
    --gw-white-opaque-6: #dedede;
    --gw-white-opaque-7: rgba(255, 255, 255, 0.3);
    --gw-black-opaque: rgba(0, 0, 0, 0.16);
    --gw-black-opaque-1: rgba(0, 0, 0, 0.15);
    --gw-black-opaque-2: rgba(0, 0, 0, 0.3);
    --gw-black-opaque-3: rgba(0, 0, 0, 0.1);
    --gw-black-opaque-4: rgba(0, 0, 0, 0.25);
    --gw-black-opaque-5: rgba(8, 7, 7, 0.7);
    --gw-black-opaque-6: rgba(0, 0, 0, 0.08);
    --gw-black-opaque-7: rgba(0, 0, 0, 0.2);
    --gw-black-opaque-8: rgba(0, 0, 0, 0.5);
    --gw-navy-opaque: rgba(50, 63, 79, 0.16);
    --gw-grey-opaque: rgba(108, 125, 142, 0.3);
    --gw-grey-opaque-2: rgba(229, 229, 229, 0.8);
    --gw-grey: #808080;
    --gw-grey-2: #999;
    --gw-grey-3: #d1d9e2;
    --gw-grey-dark-blue: rgba(50, 63, 79, 0.16);
    --gw-grey-dark-80-color: #58595b;
    --gw-grey-dark-90-color: #373737;
    --gw-grey-dark-100-color: #3f3f3f;
    --gw-grey-dark: #58595b;
    --gw-grey-dark-2: #475158;
    --gw-grey-dark-3: #3f3f3f;
    --gw-gray-dark-4: #848484;
    --gw-gray-dark-5: #363636;
    --gw-gray-dark-6: #28333f;
    --gw-gray-dark-7: #677883;
    --gw-gray-dark-8: #a1a1a1;
    --gw-gray-dark-9: #7C7C7C;
    --gw-grey-light: #f4f4f6;
    --gw-grey-light-1: #ececeb;
    --gw-grey-light-2: #ccc;
    --gw-grey-light-4: #bbbdbf;
    --gw-grey-light-5: #aaa;
    --gw-grey-light-6: #848484;
    --gw-grey-light-7: #eee;
    --gw-grey-light-8: #f7f7f7;
    --gw-grey-light-9: #787878;
    --gw-grey-light-10: #d7dade;
    --gw-grey-light-11: #e2e2e2;
    --gw-grey-light-12: #fbfbfb;
    --gw-grey-light-13: #f4f5f6;
    --gw-grey-light-14: #f5f5f5;
    --gw-grey-light-15: #d4d4d4;
    --gw-grey-light-16: #c9c9c9;
    --gw-grey-light-17: #e0e1dd80;
    --gw-grey-light-18: #767c85;
    --gw-grey-light-19: #f1f1f1;
    --gw-grey-light-20: #ebebeb;
    --gw-grey-light-21: #7a8591;
    --gw-grey-light-22: #d6d6d6;
    --gw-grey-light-23: #EBF1F6;
    --gw-grey-light-24: #D7E4ED;
    --gw-grey-light-25: #f8f9fa;
    --gw-grey-light-26: #d3d3d3;
    --gw-grey-light-27: #f7ded8;
    --gw-grey-light-28: #eed3d7;
    --gw-grey-light-29: #eaeae8;
    --gw-grey-light-30-color: #ddd;
    --gw-grey-light-31-color: #d8d8d8;
    --gw-grey-light-60-color: #898989;
    --gw-grey-light-61-color: #f1f1f2;
    --gw-grey-light-62-color: #fbf2ff;
    --gw-blue-light-opaque: rgba(0, 126, 206, 0.1);
    --gw-blue-light-1: #f4f9fc;
    --gw-blue-light-2: #63a1d6;
    --gw-blue-light-3: #00739d;
    --gw-blue-light-4: #acb9ca;
    --gw-blue-light-5: #0086b6;
    --gw-blue-light-6: #08c;
    --gw-blue-light-7: #3388cc;
    --gw-blue-light-8: #26a9e0;
    --gw-blue-light-9: #d4eef9;
    --gw-blue-light-10: #c2e6f5;
    --gw-blue-light-11: #61dafb;
    --gw-blue-light-12: #e4eff8;
    --gw-blue-light-13: #27a4f4;
    --gw-blue-light-14: #067072;
    --gw-blue-light-15: #4dbbd9;
    --gw-blue-light-16: #647e9c;
    --gw-blue-light-17: #00b3c7;
    --gw-blue-light-18: #69a8e2;
    --gw-blue-light-19: #00a5b8;
    --gw-blue-light-20: #7db4b6;
    --gw-blue-light-21: #5081ac;  
    --gw-lime-green: #89be50;
    --gw-lime-green-1: #e8f2d6;
    --gw-orange: #fa9a45;
    --gw-orange-2: #fde2ca;
    --gw-orange-3: #f5861f;
    --gw-orange-4: #fef5ec;
    --gw-orange-5: #f78600;
    --gw-orange-6: #fdebbe;
    --gw-orange-7: #fdde91;
    --gw-orange-8: #df8f04;
    --gw-turquoise-2: #50c7a7;
    --gw-green: #4dc27a;
    --gw-green-1: #88bf48;
    --gw-green-2: #628d34;
    --gw-green-3:#97c664;
    --gw-green-4: #14b14b;
    --gw-light-green-1: #dceccb;
    --gw-light-green-2: #edf5e5;
    --gw-dark-green: #486827;
    --gw-dark-green-2: #779e4e;
    --gw-dark-green-3: #6a8a49;
    --gw-light-blue: #acdef3;
    --gw-light-blue-2: #dceaf6;
    --gw-dark-blue: #1658a5;
    --gw-dark-blue-1: #323f4e;
    --gw-dark-blue-2: #04c;
    --gw-dark-blue-3: #5389b7;
    --gw-dark-blue-4: #282c34;
    --gw-dark-blue-5: #0000ff;
    --gw-dark-blue-6: #115876;
    --gw-dark-blue-7: #50657d;
    --gw-dark-blue-8: #5fa2dd;
    --gw-dark-blue-9: #007ece;
    --gw-dark-blue-10: #19a8ad;
    --gw-darker-blue: #124987;
    --gw-navy: #253746;
    --gw-brown: #2d2b2a;
    --gw-material-primary-color: var(--gw-dark-blue);
    --gw-material-primary-color-darken: var(--gw-darker-blue);
    --gw-material-primary-header-color: var(--gw-grey-light-2);
    --gw-material-primary-background-color: var(--gw-white);
    --gw-active-background-color: rgba(255,255,255,0.3);
    --gw-active-link: var(--gw-blue);
    --gw-input-border-bottom-color: var(--gw-grey-light-2);
    --gw-lines: var(--gw-grey-light-2);
    --gw-facebook-share-background-color: #4267b2;
    --gw-red-1: #df3938;
    --gw-red-2: #dca5a4;
    --gw-red-3: #b94a48;
    --gw-red-4: #faf2f2;
    --gw-red-5: #f1dcdc;
    --gw-red-6: #ed1c24;
    --gw-red-7: #f54849;
    --gw-qqdriver-terms-color: #26a9e0;
    --gw-grey-dark-10-color: rgba(255,255,255,0.3);
    --gw-yellow-1: #FFFF00;
    --gw-pink-1: #b900b1;
    --gw-pink-2: #976190;
    --gw-pink-3: #9f6998;
    --gw-violet-1: #7919ba;
    --gw-yellow-2: #fcbf14;
    --gw-alfa-graphite: #333333;
}
