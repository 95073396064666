@import "~gw-common-styles";

.inputContainer {
display: flex;
display: -webkit-flex;
}

.componentInput {
  input {
  width: 70px !important;
  ::-webkit-input-placeholder { /* Chrome */
    color: --gw-grey-2 !important;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: --gw-grey-2 !important;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: --gw-grey-2 !important;
  }
  :-moz-placeholder { /* Firefox 4 - 18 */
    color: --gw-grey-2 !important;
    opacity: 1;
  }
  }
  input[type="password"]::placeholder {
    font-size: 14px !important;
  }
  width: auto !important;

  input[type="password"] {
    font-size: 2rem;
  }

  
}
div[class^="jut__FieldMessage__error"] {
  display: none;
}

.separatorClass {
  font-weight: bold;
  margin-top: 9px
}

.componentContainer {
  padding: 20px 0;
}

.inputLabel {
 // font-size: 16px;
}