@import "~gw-common-styles";
@import "~@jutro/theme/assets/sass/helpers";

.quoteInfoBar {
  --gw-quote-info-bar-color: var(--gw-white);
  --gw-quote-info-bar-background: var(--gw-blue-light-2);

  --gw-quote-info-bar-title-color: var(--gw-dark-blue-1);

  --gw-quote-info-bar-block-max-width: 25rem;
  --gw-quote-info-bar-block-min-height: 6rem;
  --gw-quote-info-bar-block-margin: .2rem 4rem;

  --gw-quote-info-bar-title-badge-margin: 0 0 0 .5rem;

  background: var(--gw-quote-info-bar-background);

  display: flex;
  justify-content: center;
  width: 100%;
}

/* .infoBlock {
  margin: var(--gw-quote-info-bar-block-margin);
  max-width: var(--gw-quote-info-bar-block-max-width);
  min-height: var(--gw-quote-info-bar-block-min-height);
  display: flex;
  flex-direction: column;
  @include media("<phonePortrait") {
    align-items: center;
    justify-content: center;
  }
} */

.infoBlockTitle {
  color: var(--gw-quote-info-bar-title-color);
}

.infoBlockContent {
  color: var(--gw-quote-info-bar-color);
}

.coverableItem {
  display: block;
}

.infoBlockBadge {
  margin: var(--gw-quote-info-bar-title-badge-margin);
}
.quoteIdStyle {
  position: absolute;
  font-weight: bold;
  z-index: 9999;
  font-size: 14px;
  top: 1.8rem;
  left: 27rem;
  color: var(--gw-black);
  @include gw-breakpoint-down(tablet) {
    top: 2.5rem;
    left: 28rem;
  }
  @include gw-breakpoint-down(phone) {
    top: 5rem;
    font-size: 14px;
    margin-left: 2.5rem;
    left: 0;
  }
}