@import "gw-common-styles";

.headerLinkcomponent {
    --gw-header-link-component-padding-left: 2rem;
    --gw-header-link-component-description-font-size: var(--font-xs);
    --gw-header-link-component-description-margin-top: 1rem;
    --gw-header-link-component-description-margin-bottom: 1rem;
    --gw-header-link-component-title-font-size: var(--font-l);
    --gw-header-link-component-title-margin-top: 0;
    --gw-header-link-component-title-margin-bottom: 0;
    --gw-header-link-component-icon-padding-right: 0.6rem;

    @include media(">phonePortrait", "<=tabletLandscape") {
        --gw-header-link-component-container-tablet-font-size: var(--font-xl);
        --gw-header-link-component-container-tablet-padding: 0;
        --gw-header-link-component-container-tablet-width: 14rem;
    }

    color: var(--gw-white);
    padding-left: var(--gw-header-link-component-padding-left);
    text-align: right;
}

.gwDescription {
    font-size: var(--gw-header-link-component-description-font-size);
    margin-top: var(--gw-header-link-component-description-margin-top);
    margin-bottom: var(--gw-header-link-component-description-margin-bottom);
}

.gwTitle {
    font-size: var(--gw-header-link-component-title-font-size);
    margin-top: var(--gw-header-link-component-title-margin-top);
    margin-bottom: var(--gw-header-link-component-title-margin-bottom);
}
.gwTitle:hover {
    text-decoration: underline;
}

.headerLinkcomponent a {
    color: var(--gw-white);
    cursor: pointer;
    text-decoration: none;
}

.headerLinkcomponent i {
    padding-right: var(--gw-header-link-component-icon-padding-right);
    display: inline-block;
}

@media print{
    .headerLinkcomponent{
        display:none;
    }
}
@include media("<=tabletPortrait") {
    .headerLinkcomponent {
        width: var(--gw-header-link-component-container-tablet-width);
        padding: var(--gw-header-link-component-container-tablet-padding);
        font-size: var(--gw-header-link-component-container-tablet-font-size);
    }
}
