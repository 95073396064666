.digitalFieldComponent {
    --gw-digital-field-component-padding: 1.5rem;
    padding-bottom: var(--gw-digital-field-component-padding);
    width: 100%;
    border: none !important;
}

.digitalFieldComponentAliginLeft {
    // Override Field Component Variables
    --fieldComponent-control-readonly-font-weight: var(--font-weight-regular);

    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: auto;
    grid-template-areas: 'label field';
    align-items: baseline;
    grid-column-gap: 10px;
    width: 100%;

    .digitalControlReadOnly {
        overflow: visible;
    }

    .digitalContentContainer {
        width: auto;
    }

    @include media("<phonePortrait") {
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        grid-template-areas: 'label' 'field';
    }
}

.digitalReversed {
    grid-template-columns: 3fr 1fr;

    @include media("<phonePortrait") {
        grid-template-columns: 100%;
    }
}

.digitalContentContainer {
    grid-area: field;
    width: 100%;
    align-self: center;
}

.digitalFullWidth {
    display: inline-block;
    width: 100%;
}

.floatContainer {
  position: relative;
}
.floatInput {
border: none;
position: relative;
width: 100%;

input {
  border-style: none none solid !important;
  border-bottom: 2px solid var(--gw-alfa-graphite);
  border-radius: unset !important;
  outline: none;
  -moz-appearance:none;
  width: 100%;
  box-shadow: none !important;
}
input:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: white !important;
  border-bottom: 2px solid $alfa-font-color  !important;
  border-radius: unset !important;
 
}

}

.floatText {
  top: -20px;
  font-size: 12px;
  color: var(--gw-alfa-graphite);
  transition: 0.2s ease all;
  outline: none;
  position: absolute;
}

.floatInputError {
  input {
    border-bottom: 2px solid $alfa-red-color;
  }
}

.selectInput {
    
    
    border: none !important;  
    width: 40%; 
    .jut__GenericSelectControl__control {
      border: none !important;
    }
}
.errorSpan {
  color: $alfa-red-color;
  position: absolute;
  margin-top: -15px;
  font-size: 14px;
}