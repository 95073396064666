@import 'gw-common-styles';


:root {
   --gw-modal-title-padding-bottom: 25px;
    --gw-modal-width: 720px;
    --gw-button-container-margin-top: 20px;
    --gw-cancel-button-margin-right: 10px;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 55px;
}
.gwToolTip {
  min-width: fit-content;
  background-color: none;
  background: none;
  width: fit-content;
  i{
      font-size: small;
  }
  

  text-align: center;
}
.gw-social-image {
  width :30px;
  height:30px;
  @include media('<=phoneLandscape') {
    display: none;
  }
}
.modalWidth {
  grid-template-columns: none !important;
  padding-bottom: 20px;
}


.paHeader {
  display: flex;
  right: 20px;
  bottom: 20px;
  position: relative;
  width: 650px;
  border-radius: 5px 5px 0px 0px;
  background: #F4F2EA;
  min-width: 103%;
  @include media('<phoneLandscape') {
    width: 325px;
    right: 10px;
    bottom: 25px;
    min-width: 106%;
  }
  @include media('>=phoneLandscape','<=tabletLandscape') {
      right: 15px;
      min-width: 102%;
  } 
    
      h2 {
        font-size: 28px;
        color: var(--gw-alfa-graphite);
        margin-left: 10px;
        padding: 5px;
      }  
      
    }

.closeBtn {
  position: absolute;
  right: 10px;
  color: var(--gw-alfa-graphite);
  i {
    font-size: 18px;
    color: var(--gw-alfa-graphite);
  }
}

  .cancelBtnMargin {
    margin-right: var(--gw-cancel-button-margin-right);
    span {
      font-weight: bold;
    }
}
.fontThick{
    
    font-weight: bold;
    margin-left: 20px;
    @include media('<=phoneLandscape') {
      margin-left: unset;
    }
}
  .activityContainer {
    width: 60rem;
    height: 400px;
    color: var(--gw-alfa-graphite);
    @include media("<phoneLandscape") {
      width: 325px;
      overflow-y: scroll;
    }
  }
  
  .ContainerTextIndentStyle {
    margin-left: 50px;
    text-indent: -50px;
    @include media('<=phoneLandscape') {
      margin-left: unset;
      text-indent: unset;
      padding: 5px;
    }
  }
  