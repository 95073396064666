@import "~@jutro/theme/assets/sass/helpers";

.contactUsContainer {
    --gw-contact-us-container-width: 70%;
    --gw-contact-us-title-margin: 40px 0;
    --gw-contact-us-sizing: 10px;
    width: var(--gw-contact-us-container-width);
    margin: auto;
    @include gw-breakpoint-down(phone) {
        width: 84%;
    }
}

h1.pageTitle {
    margin: var(--gw-contact-us-title-margin);
    font-size: 24px;
    color: var(--gw-alfa-graphite);
    @include gw-breakpoint-down(phone) {
        width: 99%;
    }
}
h1 {
    @include gw-breakpoint-down(phone) {
        font-size: 24px;
    }
}
.icon {
    margin-right: var(--gw-contact-us-sizing);
}

.assistanceText {
    padding: var(--gw-contact-us-sizing) 0;
}
.contactSectionStyle {
    text-align: center;
    margin-bottom: 2rem;
}
.contactSectionAltStyle {
    text-align: center;
    margin-bottom: 5rem;
    @include gw-breakpoint-down(phone) {
        margin-bottom: 1rem;
    }
}
.fontCustomContactStyle {
    font-weight: normal !important;
    font-size: 1.4rem !important;
    white-space: unset;
}
.fontStyle {
    font-weight: normal !important;
    color: var(--gw-alfa-graphite) !important;
}
.whiteSpaceContactStyle {
    white-space: nowrap;
}
.mobileStyle {
    @include gw-breakpoint-down(phone) {
        font-size: 1.7rem !important;
        white-space: nowrap;
    }
}
.lineStyle {
    line-height: 2.7rem !important;
}
.contactNumberSectionStyle {
    display: inline-flex;
    align-items: center;
    padding-top: 20px;
}
.contactNumberSectionStyleNoAgent{
    display: inline-flex;
    padding-top: 20px;
}
.textStyle {
    text-align:  left;
    color: var(--gw-alfa-graphite) !important;
}
.imageStyle {
    transform: rotate(90deg);
    height: 45px !important;
    margin-top: 1rem;
}
.divMarginStyle {
    margin-left: 1rem;
    margin-top: 2rem;
}
.quoteidTextStyle {
    text-align: center;
}
.customImageStyle {
    height: 100px !important;
    width: 84px;
    margin-top: 1rem;
}
.spanFontStyle {
    font-size: 14px;
}
.customLineStyle {
    line-height: 1.8rem !important;
    @include gw-breakpoint-down(phone) {
        white-space: nowrap;
    }
}
.phoneIconColorStyle {
    color: black !important;
}
.quoteidCustomStyle {
    text-align: center;
    margin-top:  5rem !important;
    color: #000000;
    font-size: 14px;
    @include gw-breakpoint-down(phone) {
        margin-bottom:  10rem;
    }
}
.customGridOneCenterStyle {
    margin-left: 5rem;
    @include gw-breakpoint-down(phone) {
        margin-left: unset;
    }
}
.spanFont {
    font-size: 18px !important;
}
.textFont {
    font-size: 16px !important;
}
.fontColor {
    color: #000000 !important;
}
.rightSpanSpace {
    padding-right: 4px;
}
.leftSpanSpace {
    padding-left: 2px;
}
.hideIcon {
    visibility: hidden;
}
.adjustWidth {
    width: 76%;
}
.alterFontSize {
    font-size: 16px;
}
.docuSignWidth {
    width: 77%;
}
.docuSignMobileWidth {
    width: 32rem;
}
.quoteidCustomMarginStyle {
    @include gw-breakpoint-only(desktop) {
        margin: 15rem;
    }
}
.fontColorPhone {
    color: #287D9F !important;
}