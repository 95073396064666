@import '~gw-common-styles/foundation';
@import '~gw-common-styles/components/widgets';
@import "~@jutro/theme/assets/sass/helpers";

.agentContentLeft {
    padding: 15px 20px 15px 20px;
    margin-left: 0;
    font-size: 12px;
    text-align: left;
    line-height: 1.8rem;

    a {
        text-decoration: none;
    }
}

.agentDetails {
    color: black;

    h2 {
        font-size: 12px;
        margin: 0 !important;
        color: #000000;

        @include gw-breakpoint-down(phone) {
            font-size: 16px;
        }
    }

    p {
        font-size: 12px;
        margin-bottom: unset;
        margin-top: -1rem;
    }
}

.agentSpanMargin {
    margin-right: 5px;
}

.agentPhoneStyle {
    pointer-events: none;
    text-decoration: none !important;
    color: #000000 !important;

    @include gw-breakpoint-down(phone) {
        pointer-events: unset;
        cursor: pointer;
    }
}

.agentSearch {
    margin: 4px 12px;
    border-radius: 5px;
    height: 22px;
    background-color: #ececeb;
    border: 1px solid #ececeb;

    @media only screen and (min-width: 1025px) and (max-width: 1191px) {
        margin: 4px 2px;
    }

    @include gw-breakpoint-down(phone) {
        margin: 2% 0%;
    }
}

.agentSearch input {
    height: 19px !important;
    background-color: #ececeb !important;
    position: relative;
    font-size: 12px;
    padding-left: 5px;
    outline: none;
    box-shadow: none !important;
    border: none !important;
    color: var(--gw-alfa-graphite);
}

.agentsearchIcon {
    cursor: pointer;
    height: 20px;
    background-color: #ececeb;
    border: none;
    color: #5f574f;
    outline: none;
    padding-left: 10px;
    float: right;

    @include gw-breakpoint-down(phone) {
        padding-left: unset;
        float: right;
    }
}

.agentModalClose {
    position: absolute;
    cursor: pointer;
    right: 7px;
    top: 7px;
    width: 12px;
    height: 12px;
}

.agentModalClose::after {
    position: absolute;
    content: ' ';
    height: 2px;
    width: 12px;
    margin-top: 5px;
    background-color: #000000;
}

.agentModalInner {

    ul {
        max-height: 220px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    ul::-webkit-scrollbar {
        width: 4px;
    }

    ul::-webkit-scrollbar-thumb {
        background-color: lightgrey;
    }

    li {
        outline: none;
    }

    li::after {
        content: ' ';
        height: 1px;
        width: 190px;
        margin-left: 5px;
        background-color: lightgrey;
        display: inline-block;
        margin-bottom: 5px;
    }
}

.agentModalState {
    display: none;
}

.agentModalState:not(:checked) + .agentModal {
    opacity: 0;
    visibility: hidden;
    transition: 0.2s, opacity 0s;
    -webkit-transition: 0.2s, opacity 0s;
}

.agentModalState:checked + .agentModal {
    opacity: 1;
    visibility: visible;
    transition: 0.2s, opacity 0s;
    -webkit-transition: 0.2s, opacity 0s;
}

.agentModalState:checked + .agentModal .agentModalInner {
    top: 0;
    position: relative;
}

.agentBtn {
    cursor: pointer;
    display: inline-block;
    position: relative;
}

.agentImg {
    top: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    height: 80px !important;
    width: 80px;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

.agentImgMobile {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    height: 50px !important;
    width: 50px;
    margin: 5px 5px;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    image-rendering: -o-crisp-edges;
    image-rendering: -moz-crisp-edges;

    @include gw-breakpoint-down(tablet) {
        margin: 5px 5px;
    }

    @media only screen and (max-width: 1023px) and (min-width:850px) {
        margin: 0px 10px;
    }

    @media only screen and (max-width: 850px) and (min-width:800px) {
        margin: 0px 5px;
    }

    @media only screen and (max-width: 800px) and (min-width:720px) {
        margin: 8px 2px;
        height: 40px !important;
        width: 40px !important;
    }

    @media only screen and (max-width: 760px) and (min-width:720px) {
        margin: 7px 2px;
        height: 40px !important;
        width: 40px !important;
    }

    @media only screen and (max-width: 720px) and (min-width:460px) {
        margin: 7px 0px;
        height: 40px !important;
        width: 40px !important;
    }

    @media only screen and (max-width: 460px) and (min-width:330px) {
        margin: -3px 0px;
        height: 40px !important;
        width: 40px !important;
    }

    @media only screen and (max-width: 330px) and (min-width:100px) {
        margin: 0px 0px;
        height: 35px !important;
        width: 35px !important;
    }
}

.imgContainerMobile {
    position: fixed;
    width: 10rem;
    height: 7rem;
    left: 85%;
    top: 200px;
    right: 5%;
    background: #ECECEB;
    border-radius: 5rem;
}

.imgContainerTablet {
    height: 7%;
    width: 20rem;
    border-radius: 5rem;
    background-color: #ececeb;
    top: 200px;
    right: 5%;
    left: 83%;
    position: fixed;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @include gw-breakpoint-down(tablet) {
        height: 85px !important;
        left: 92%;
    }

    @media only screen and (max-width: 1023px) and (min-width:750px) {
        height: 75px !important;
        left: 90%;
    }

    @media only screen and (max-width: 750px) and (min-width:650px) {
        height: 75px !important;
        left: 90%;
    }

    @media only screen and (max-width: 650px) and (min-width:460px) {
        height: 75px !important;
        left: 88%;
    }

    @media only screen and (max-width: 460px) and (min-width:330px) {
        height: 55px !important;
        left: 83%;
    }

    @media only screen and (max-width: 330px) and (min-width:100px) {
        height: 55px !important;
        left: 83%;
    }
}

.agentImgTablet {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    height: 15px !important;
    width: 60px;
}

.agentModalFix {
    position: relative;
    display: block;
    bottom: -10px;
    width: 240px;
    min-height: 120px;
    max-height: 315px;
    text-align: left;
    box-shadow:  0px 4px 4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    transition: opacity 0.25s ease;
    background: #FFFFFF;
}

.agentModal {
    display: block;
    bottom: 120px;
    width: 240px;
    min-height: 120px;
    max-height: 315px;
    text-align: left;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    transition: opacity 0.25s ease;
}

.pImageStyle {
    margin-top: -1rem !important;
    padding-right: 2rem;
}

.agentModalState:checked ~ .pImageStyle:before {
    content: '';
    position: absolute;
    width: 11px;
    height: 11px;
    background: white;
    margin-top: -26px;
    margin-left: 30px;
    transform: rotate(45deg);
    box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.2);
}

.agentModalStateLocator:checked ~ .pImageStyle:before {
    content: '';
    position: absolute;
    width: 11px;
    height: 11px;
    background: white;
    margin-top: -26px;
    margin-left: 30px;
    transform: rotate(45deg);
    box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.2);
}

.defaultDisplay {
    display: flex;
    position: fixed;
    margin-bottom: 290px;
    left: 25px;
    top: 200px;
    right: 2rem;
    width: max-content;

    @include gw-breakpoint-only(tablet) {
        bottom: 5rem;
    }
}

.agentLocator {
    visibility: visible;
    min-height: 150px;
    max-height: 420px;
    background-color: white;
    text-align: left;
    border-radius: 5px;
    transition: opacity 0.25s ease;
    display: block;
    position: fixed;
    top: 200px;
    width: 215px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @include gw-breakpoint-down(phone) {
        display: inline-block;
        position: fixed;
        top: 200px;
        width: 250px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}

.agentModalStateLocator {
    display: none;
}

.agentModalStateLocator:not(:checked) + .agentLocator {
    opacity: 0;
    visibility: hidden;
    transition: 0.2s, opacity 0s;
    -webkit-transition: 0.2s, opacity 0s;
}

.agentModalStateLocator:checked + .agentLocator .locatorModalInner {
    opacity: 1;
    visibility: visible;
    transition: 0.2s, opacity 0s;
    -webkit-transition: 0.2s, opacity 0s;
}

.agentLocatorSearch {
    margin: 20px 0 0 32px;
    border-radius: 5px;
    width: 65%;
    height: 25px;
    background-color: #ececeb;
    border: 1px solid #ececeb;
}

.agentLocatorSearch input {
    height: 15px !important;
    background-color: #ececeb !important;
    width: 78% !important;
    position: relative;
    font-size: 10px;
    outline: none;
    box-shadow: none !important;
    margin: 1px;
    border: none !important;
}

.agentLocatorSearchIcon {
    cursor: pointer;
    background-color: #ececeb;
    border: none;
    color: #5f574f;
    position: absolute;
    outline: none;
    padding-bottom: 1px;
    padding-top: 4px;
    margin-top: 0px;
    border-radius: 5px;

    @include gw-breakpoint-down(phone) {
        padding-left: 6%;
    }
}

.locatorModalClose {
    position: absolute;
    right: 7px;
    cursor: pointer;
    width: 15px;
    height: 15px;
    outline: none;
    margin-top: -50px;
    margin-right: 5%;
}

.locatorModalClose:before {
    position: absolute;
    content: ' ';
    height: 1px;
    width: 12px;
    background-color: #5f574f;
    transform: rotate(45deg);
}

.locatorModalClose:after {
    position: absolute;
    content: ' ';
    height: 1px;
    width: 12px;
    background-color: #5f574f;
    transform: rotate(-45deg);
}

.locatorModalInner {
    display: inline-block;
    transition: top 0.25s ease;
    width: 215px;
    cursor: pointer;

    @include gw-breakpoint-down(phone) {
        width: 290px;
    }
}

.locatorModalInner ul {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    list-style: none;
    padding-left: unset;
}

.locatorModalInner ul::-webkit-scrollbar {
    width: 4px;
}

.locatorModalInner ul::-webkit-scrollbar-thumb {
    background-color: lightgrey;
}

.locatorModalInner li {
    outline: none;
    margin-bottom: -7px;
}

.locatorModalInner li:after {
    content: ' ';
    height: 1px;
    width: 200px;
    margin-left: 5px;
    background-color: #8AC0DB;
    display: inline-block;
    margin-bottom: 5px;

    @include gw-breakpoint-down(phone) {
        width: 290px;
    }
}

.lineSeperator {
    content: ' ';
    height: 1px;
    width: 200px;
    margin-left: 5px;
    background-color: #8AC0DB;
    display: inline-block;

    @include gw-breakpoint-down(phone) {
        width: 290px;
    }
}

.agentLocatordetails {
    display: flex;
    display: -webkit-flex;
    padding: 0 10px 0 10px;
    width: 220px;

    @include gw-breakpoint-down(phone) {
        padding: 0 30px 0 30px !important;
        width: 300px !important;
    }

    color: black;

    p {
        font-size: 12px;
        padding-top: 5px;
    }

    img {
        height: 40px;
        width: 35px;
        margin: 10px 10px 0 0;
        border-radius: 2px;
    }
}

.agentLocatordetails:focus {
    outline: none;
}

.displayStyle {
    display: inline-block;
    margin-right: 5%;
    margin-top: -18rem;
    margin-left: 10%;
}

.displayAgentLocStyle {
    display: inline-block;
    margin-right: 5%;
    margin-top: -1rem;
    margin-left: 10%;
}

.headerStyle {
    font-size: 16px !important;
    padding: 15px;
    text-align: left;
    color: #000000 !important;
    background-color: #ECECEB;
}

.locatorPhoneStyle {
    pointer-events: none;
    color: var(--gw-alfa-graphite);
    text-decoration: none !important;
}

.locatorContent {
    font-size: 11px;
    text-align: left;
    line-height: 11px !important;
}

.locatorContent h2 {
    font-size: 12px !important;
    font-weight: bold;
    text-decoration: underline;
    margin: 0 !important;
    text-align: left !important;
    line-height: 14px;
    padding-bottom: 5px;
    color: #000000;
}

.locatorContent span {
    color: var(--gw-alfa-graphite);
    font-size: 11px;
    text-align: left;
}

.noResultStyle {
    font-size: 12px;
    color: #C30717;
}

.inputErrorStyle {
    border: 1px solid red !important;
}

.rootDivStyle {
    text-align: right;
    width: max-content;

    @include gw-breakpoint-down(phone) {
        text-align: unset;
    }
}

.agentInfoMobileDisplay {
    @include gw-breakpoint-down(phone) {
        width: 90%;
        background-color: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        top: 200px;
        position: fixed;
        width: max-content;
    }
}

.agentInfoTabletDisplay {
    @include gw-breakpoint-down(tablet) {
        width: 90%;
        background-color: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        top: 200px;
        position: fixed;
        width: max-content;
    }
}

.agentInfoAddressSpan {
    margin-left: 17px;
    font-size: 12px;
    color: #000000;

    @include gw-breakpoint-down(phone) {
        margin-left: unset;
    }
}

.agentInfoFontStyle {
    font-size: 12px;
    color: #000000;

    @include gw-breakpoint-down(phone) {
        font-size: 12px;
    }
}

.agentInfoFontStyleLine1 {
    line-height: 0px !important;
    top: -6px;
    position: relative;
    font-size: 12px;
    color: #000000;

    @include gw-breakpoint-down(phone) {
        font-size: 14px;
    }
}

.agentInfoFontStyleLine2 {
    position: relative;
    top: -12px;
    margin-left: 30px;

    @include gw-breakpoint-down(phone) {
        font-size: 14px;
    }
}

.agentPhonenumStyle {
    position: relative;
}

.helpSpanStyle {
    font-size: 12px;
    color: #373737;

    @include gw-breakpoint-down(phone) {
        color: grey;
    }
}

.spanSpaceStyle {
    display: inline-block;
    margin-bottom: 3%;
}

.clearSpaceAlignStyle {
    position: relative;
    width: 76.3%;
    bottom: 15rem;

    @include gw-breakpoint-down(tablet) {
        width: 84%;
    }
}

.mainDivDisplayStyle {
    display: inline-block;
}

.agentName {
    background-color: #ECECEB;
    padding: 10px 10px 10px 10px;
    display: flex;
}

.agentIcon {
    width: 20px;
    margin-right: 10px;
}

.yourAgent {
    font-weight: bold;
    display: flex;
}

.agentIconUser {
    margin-top: 5px;
    width: 40px;
    margin-right: 10px;
}


.agentClose {
    margin-top: 16px;
    margin-left: 25px;
    color: #4D9CBC;
    left: 15px;
    position: relative;
}

.agentMobileImg {
    margin-top: 10px;
}

.agentPointer {
	width: 0;
    height: 0;
    border: 16px solid transparent;
    border-top: 0;
    border-bottom: 20px solid #ECECEB;
    margin-right: -5px;
    margin-left: -18px;
    margin-top: 35px;
    transform: rotate(270deg); 
    z-index: 1;
    position: relative;
    left: 7px;
}
.noResultLocator {
    text-align: center;
    margin-bottom: -20px;
    font-size: 12px;
    color: #C30717;
}