@import 'gw-common-styles';

.retrieveQuotePageContainer {
  --retrieve-quote-container-width: 500px;
  width:  600px;
  margin-left: -65%;
  margin-right: 0px;
  margin-top: -45px;
  margin-bottom:0px;
  padding: 0, 0, 0, 0;
  @include media('<=phoneLandscape') {
    width: 100%;
    margin-left: 10px;
  }
}

.retrieveQuotePageTitle {
  text-align: center;
  margin-top: 0px;
  width:  600px;
  margin-left: -10%;
  @include media('<=phoneLandscape') {
    margin-top: -30px;
    width: 90%;
    margin-left: 10px;
  }
  @include media('>phoneLandscape','<=tabletLandscape') {
    margin-top: -10px;
  }

  h1 {
    font-size: 40px;
    color: var(--gw-alfa-graphite) !important;
    @include media('<=phoneLandscape') {
      font-size: 35px;
    }

    @include media('>phoneLandscape','<=tabletLandscape') {
      margin: 12px;
    }
  }

  h3 {
    font-size: 30px;
    padding:10px;
    color: var(--gw-alfa-graphite) !important;
    @include media('<=phoneLandscape') {
      font-size: 25px;
      display: none;
    }
  }

  h4 {
    font-size: 22px;
    color: var(--gw-alfa-graphite) !important;
    @include media('<=phoneLandscape') {
      font-size: 20px;
    }
  }
}

.retrieveQuoteDescription {
  --retrieve-quote-container-width: 500px;
  width: 600px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 20px;
  margin-left: -85%;
  font-size: 24px;
  font-weight: bold;
  color: var(--gw-alfa-graphite) !important;
  @include media('<=phoneLandscape') {
    margin-top: 10px;
    width: 90%;
    margin-left: 10px;
    line-height: normal;
  }
}

.retrieveQuoteCantFindMessage {
  --retrieve-quote-container-width: 500px;
  width: 500px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -65%;
  font-size: 25px;
  font-weight: bold;
  color: $alfa-red-color;
  @include media('<=phoneLandscape') {
    --retrieve-quote-container-width: 100%;
    margin-top: 10px;
    font-size: 20px;
    width: 100%;
    margin-left: 0;
  }
  @include media('>phoneLandscape','<=tabletLandscape') {
    margin-top: 10px;
    font-size: 22px;
  }  

  h4 {
    color: $alfa-red-color;
  }
}

.retrieveQuoteBr {
  display: none;
  @include media('<=phoneLandscape') {
    display: inline;
  }  
}

.retrieveQuoteLinkActive {
  font-size: 22px;
  font-weight: bold;
  margin-top: -5px;
  text-decoration: underline;
  color: #115876;
  @include media('<=phoneLandscape') {
    font-size: 21px;
  }
  @include media('>phoneLandscape','<=tabletLandscape') {
    font-size: 22px;
  }  
}

.retrieveQuoteLinkInactive {
  font-size: 22px;
  font-weight: bold;
  margin-top: -5px;
  text-decoration: underline;
  color: #287D9F;
  @include media('<=phoneLandscape') {
    font-size: 21px;
  }
  @include media('>phoneLandscape','<=tabletLandscape') {
    font-size: 22px;
  }  
}

.retrieveQuoteInputContainer{
  text-align: left;
  margin-left: -6%;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 250px;
  @include media('<=phoneLandscape') {
    margin: 30px auto;
  }

  input {
    font-size: 16px !important;
    color: var(--gw-alfa-graphite) !important;
  }
  div > div > div > div > div > div {
    color: #B10615;
  }
}

.dobContainer{
  text-align: left;
  margin-left: -6%;
  margin-top: -10%;
  margin-bottom: 30px;
  width: 250px;
  @include media("<=phoneLandscape") {
    display: none !important;
  }
  div > div > div > div > input {
    color: var(--gw-alfa-graphite);
    font-size: 16px;
  }
}

.dobMobileContainer{
  text-align: left;
  margin: -31px auto 10px auto;
  width: 250px;
  @include media(">phoneLandscape") {
    display: none !important;
  }
}

.retrieveQuoteFieldLabel{
  color: var(--gw-alfa-graphite);
  font-size: 12px;
}
.mobileHeader{
  @include media('<=phoneLandscape') {
    display: none;
  }

  h1 {
    font-size: 36px;

}
}
.retrieveQuoteFieldDiv {
  margin-top: 10px;
  margin-bottom: 10px;
}
.multiQuoteMobileTable {

  @include media('>phoneLandscape') {
    display: none;
  }

div[class*="rt-tr jut__DataTable__tableRow"] {
  background-color: #f1f1f1 !important;
    box-shadow: 0 3px 0 0 #ccc;
    border-radius: 10px;
    margin: 10px 0;
    font-weight: 400;
    padding: 2px 0;
}
}
.quoteFoundDiv {
  @include media('<=phoneLandscape') {
  margin-top: -8%;
  }
}

.amountMobileHeader {
  text-align: right !important;
}
.multiQuoteMobileHeader {
  text-align: center;
  width: unset !important;
  color: var(--gw-alfa-graphite);
  font-size: 16px;
  font-weight: 300 !important;
  display: inline !important;
}

.recaptchaContainer {
  --retrieve-quote-container-width:  320px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -19%;
  width: 320px;
  @include media('<=phoneLandscape') {
    margin: 10px auto;
    padding-left: 10px;
  }
  @include media('>phoneLandscape','<=tabletLandscape') {
    margin-top: 10px;
  }    
}

.recaptchaValidationMsg {
  color: $alfa-red-color;
}

.retrieveQuoteButtonContainer {
  margin-top: 25px;
  margin-bottom: 25px;
}

.retrieveQuoteContinueButton{
      color: #FFFFFF;
      font-size: 16px;
      font-weight: bold;
}

.getNewQuoteLink {
  text-decoration: underline;
  color: #000000;
  margin-top: 15px;
  font-size: 16px;
  @include media('<=phoneLandscape') {
    font-size: 14px;
  }

  @include media('>phoneLandscape', '<=tabletLandscape') {
    font-size: 15px;
  }
}

table {
  border-collapse: separate !important;
  border-spacing: 0 1em !important;
}

.alfaMultiQuoteTable {
  width: 500px;
  margin-left: -64%;
  border-collapse: separate !important;
  border-spacing: 0 1em !important;
  border-left: none !important;
  border-right: none !important;
  text-align: center;
  --table-header-border-width: 0;
  @include media('<=phoneLandscape') {
    display: none;
  }

  @include media('<=phoneLandscape') {
    width: 100%;
    margin-left: 0;
  }

  th {
    text-align: left;
    background-color: #fff;
    color: var(--gw-alfa-graphite);
    padding-left: 5%;
    text-transform: none;
    font-size: 16px;
    font-weight: bold;

    @include media('<=phoneLandscape') {
      color: #000;
      font-size: 20px;
      font-weight: 400;
      padding-left: 20px;
    }

    @include media('<=tabletLandscape') {
      font-weight: 300;
    }
  }

  thead > tr {
    box-shadow: none;
    padding: var(--table-padding-y) var(--table-padding-x);
  }

  tbody > tr {
    background-color: #f1f1f1;
    box-shadow: 0 3px 0 0 #ccc;
    border-radius: 10px;
  }

  tbody > tr > td {
    background-color: #f1f1f1;
    text-align: center;
    padding: 11px;
    font-size: 17px;
  }
  tbody > tr > td + td {
    color: #000000;
  }
}

.multiQuoteQuoteIdCell {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: var(--gw-alfa-graphite);
  font-weight: bold;
  text-decoration: underline;
  @include media('<=phoneLandscape') {
    font-size: 17px;
    padding-right:0;
    width: unset !important;
  }

}

.multiQuoteAmountCell {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  @include media('<=phoneLandscape') {
    font-size: 17px;
    margin: 0 auto;
    color: #000000;
    width: unset !important;
  }
}
.multiQuoteDateMobileCell {

  @include media('<=phoneLandscape') {
    font-size: 17px;
    margin: 0 auto;
    color: #000000;
    width: unset !important;
  }
}

.multiQuoteLinksContainerDiv {
  width:  600px;
  text-align: left;
  margin-left: -62%;
  margin-top: 25px;
  margin-bottom: 25px;
  @include media('<=phoneLandscape') {
    width: 100%;
    margin-left: 10px;
  }
}

.multiQuoteBackLink {
  text-decoration: none;
  color: var(--gw-alfa-graphite);
  font-size: 16px;
}

.multiQuoteNewQuoteLink {
  text-decoration: underline;
  padding-left: 56%;
  color: #000000;
  font-size: 16px;
  @include media('<=phoneLandscape') {
    padding-left: 50%;
  }
}
.errorMessage {
  font-size: 14px;
  display: block;
  color: $alfa-red-color;
  @include media('<=phoneLandscape') {
    transform: translateY(-50%);
    width: 250px;
  }
}
.ssnError {
  color: #B10615;
  font-size: 1.4rem;
}
