@import 'gw-common-styles';

:root {
  --text-margin-top: 30px;
  --button-width: 230px;
  --icon-container-size: 100px;
  --max-width: 90em;
}

.gwKnockOutContainer {
  margin: 0 auto;
  max-width: var(--max-width);
  padding: 40px 0;
}

.gwKnockOutPage {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 70%;
  height: 100%;
  margin: 0 auto 5px;
  padding: 0;
  line-height: var(--line-height-m);
}

.gwKnockOutIcon {
  border: 1px solid var(--gw-green-1);
  color: var(--gw-green-1);
  width: var(--icon-container-size);
  height: var(--icon-container-size);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-xxxl);
  margin: auto;
}

.gwKnockOutTitle {
  font-size: var(--font-xxxl);
  font-weight: var(--font-weight-light);
  line-height: var(--line-height-xxl);
  margin-top: var(--text-margin-top);
}

.gwKnockOutMoreInfo {
  margin-top: var(--text-margin-top);
}

.gwKnockOutDescription {
  color: var(--gw-orange-5);
  font-size: var(--font-l);
  margin-top: var(--text-margin-top);
}

.gwKnockOutButton {
  width: var(--button-width);
  margin: var(--text-margin-top) auto 0;
}
