@import "gw-common-styles";
@import "~@jutro/theme/assets/sass/helpers";
:root{
    --gw-payment-radio-border-width: 5px;
    --gw-payment-radio-width-height: 20px;
    --gw-payment-border-width: 1px;
    --gw-payment-border-padding: 0;
}
.radioclass {
    border: var(--gw-payment-radio-border-width) solid var(--gw-blue-5);
    background-color: var(--gw-white);
    width: var(--gw-payment-radio-width-height);
    height: var(--gw-payment-radio-width-height);
}

.border {
    border-bottom: var(--gw-payment-border-width) solid var(--gw-grey-light-1);
    padding: var(--gw-payment-radio-width-height) var(--gw-payment-border-padding);
}
.show-div{
    display:block;
}
.hide-div{
    display:none;
}
.title-font {
    font-size: 30px !important;
    color: var(--gw-alfa-graphite) !important;
    margin-top: 35px !important;
    @include gw-breakpoint-down(phone) {
        font-size: 24px !important;
    }
}
.subtitle-style {
    font-size: 20px;
    line-height: 1.5;
    color: var(--gw-alfa-graphite) !important;
}
.divColumnStyle {
    background-color: white;
    color: var(--gw-alfa-graphite) !important;
    border-radius: 5px;
    padding: 20px;
    font-size: 150%;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    color: black;
    width: 46rem;
    box-shadow: -2px 1px 8px rgba(94,90,90,0.5);
    height: 7rem;
    outline: none;
    padding-right: 12rem;
    padding-top: 1rem;
    @include gw-breakpoint-down(phone) {
        width: 33rem;
        height: 8rem;
    }
}
.iconFontStyle {
    font-size: unset;
}
.activeDivStyle {
    border: 2px solid #4d9cbc;
}
.tooltipDivStyle {
    max-width: 38rem;
    padding: 10px;
    background: #f3f3f3;
    margin-top: 10px;
    cursor: pointer;
}
.hrStyle {
    margin: 40px 0 !important;
    border: 1px solid #999 !important;
    @include gw-breakpoint-down(phone) {
        margin: 25px 0 !important;
    }
}
.toggleControlClass {
    width: 30%;
}
.toggleLabelName {
    font-size: 15px;

    @include gw-breakpoint-down(phone) {
    font-size: 18px;
    }
}
.billingAddressDiv {
    color: var(--gw-alfa-graphite);
    @include gw-breakpoint-up(tablet) {
        margin-top: 20px;
    }  
}
.billingLabelDiv {
    max-width: 35rem;
}
.garagingAddressSpan {
    font-size: 15px;
    padding-bottom: 10px;
    text-transform: uppercase;
    color: var(--gw-alfa-graphite);
    @include gw-breakpoint-down('>tablet') {
      margin-top: 0px !important;
    }
}
.addressControlVehicle {
    display: block;
    div[id*="addressSecondComponent"] > div[class*="jut__Flex__gwWrap"] {
        flex-wrap: nowrap;
        @include gw-breakpoint-down(phone) {
            flex-wrap: wrap;
            display: grid;
            grid-template-columns: 50% 1fr 1fr;
            div[id*="cityDiv"] {
                width: 100%;
            }
            div[id*="countyDiv"] {
                width: 100%;
            }
        }
        @include gw-breakpoint-down(tablet) {
            div[id*="countyDiv"] {
                width: 32%;
            }
        }
    }
}
.divWidthStyle {
    width: 70%;
}
.spaceStyle {
    @include gw-breakpoint-up(tablet) {
    margin-left: 100px;
    }
    width: 30%;
    div > div > div > button {
        color: rgba(0, 0, 0, 0.83);
    }
    div > div > div > button + button {
        color: var(--gw-alfa-graphite);
    }
}
.iframeStyle {
    border: none;
    position: unset;
}
.iframeStyleAL {
    height: 1050px;
    @include gw-breakpoint-down(tablet) {
        height: 850px;
    }
    @include gw-breakpoint-down(phone) {
        height: 820px;
    }
}
.iframeStyleGAMS {
    height: 980px;

    @include gw-breakpoint-down(tablet) {
        height: 850px;
    }
    @include gw-breakpoint-down(phone) {
        height: 760px;
    }
}
.paymentInformationDiv > div {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding-bottom: unset !important;
}
.validateBtn {
    @include gw-breakpoint-down(phone) {
      display: none;
    }
   
    position: absolute;
    right: 0px;
    bottom: 2rem;
    background-color: $alfa-btn-active;
    box-shadow: 0 1px 1px 0 $alfa-btn-box-shadow;

    &:hover {
        background-color: $alfa-btn-hover;
    }
}
.tdStyle {
    @include gw-breakpoint-down(phone) {
        display: block;
        padding-left: 0px !important;
        padding-bottom: 10px;
    }
}
.tdTabletStyle {
    padding-left: 0px !important;
    @include gw-breakpoint-down(phone) {
        display: block;
       padding-left: 0px !important;
        padding-bottom: 10px;
    }
}
.mobileStyle {
    max-width: 23rem !important;
    padding-left: 7rem;
    text-align: start;
    @include gw-breakpoint-down(phone) {
        display: table;
        margin-top: -1rem;
        padding-left: 3rem;
    }
}
.h2Style {
    color: #000000 !important;
    font-weight: bold !important;
    font-size: 16px !important; 
    padding-top: unset !important;
    margin-top: -6rem !important;
    position: absolute;
    margin-left: 26rem;
    @include gw-breakpoint-down(phone) {
        padding-top: unset !important;
        margin-top: -7rem !important;
        margin-left: 17rem;
        position: absolute;
    }
}
.textFontStyle {
    color: var(--gw-alfa-graphite);
    font-size: 20px;
}
.addressGridStyle {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include gw-breakpoint-down(phone) {
        display: unset;
        grid-template-columns: unset;
    }
}
.spanStyle {
    font-size: 17px;
    font-weight: bold;
    @include gw-breakpoint-down(phone) {
        // padding-left: 3rem;
    }
}
.checkMark {
    color: #02C52D;
    font-family: 'FontAwesome';
    margin-left: -5rem;
    margin-bottom: -3.5rem;
    margin-top: 1rem;
    @include gw-breakpoint-down(phone) {
        margin-left: -3rem;
    }
}
.checkMarkHide {
    color: white;
    font-family: 'FontAwesome';
    margin-left: -25rem;
    margin-bottom: -3.5rem;
    margin-top: 1rem;
    @include gw-breakpoint-down(phone) {

    }
}
.paraStyle {
    color: var(--gw-alfa-graphite);
    font-size: 16px;
}
.selectedOptionSpace {
    padding-top: 10px;
}
.tabletStyle {
    @include gw-breakpoint-down(tablet) {
        margin-left: -7%;
    }
    @include gw-breakpoint-down(phone) {
        margin-left: 2%;
    }
}
.lineHeightStyle {
    line-height: 25px;
}