@import "~gw-common-styles";

.addressLookupBottomItems {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.addressContainer {
  position: relative;
}

.clearButton {
  --gw-close-button-position-right: 10px;
  --gw-close-button-position-top: 5px;
  @include media("<phonePortrait") {
    --gw-close-button-position-top: 40px;
  }
  top: var(--gw-close-button-position-top);
  right: var(--gw-close-button-position-right);
  position: absolute;
}

.componentContainers {
  padding: 16px;
  background-color: var(--gw-grey-light-8);
}

.addressLookupSearchButton {
  --gw-address-lookup-search-button-margin: 20px;
  margin-left: var(--gw-address-lookup-search-button-margin);
}
.stateReadOnly {
  width: 40px;
}
.smallInfo {
  font-size: 10px;
  left: 80px;
}
.addressLookupContainer {
  width: 100%;
  margin-top: 15px;
}
.addressBlock30 {
  margin-top: -10px;
  width: 65%;
}
.zipBlock {
  width: 80%;
}
.countyBlock {
  width: 30%;
  
  div[class^="jut__Flex__gwGapMedium"] {
    margin-right: 15px;
  }
  @include media("<=phonePortrait") {
    margin-left: 0 !important;
    width: 35%;
  }
}
.addressCityDiv {
  margin-top: -15px;
  margin-bottom: 18px;
  width: 100%;
}
.displayFlex {
  display: flex;
}
.addressBlock20 {
  width: 20%;
  @include media("<=tabletPortrait") {
    width: 30%;
    margin-left: 0px !important;
  }
}
.aptBlock {
  width: 45%;
}
.addressBlock10 {
  width: 10%;
}

.stateSelector {
  width: 50px;
  position: relative;
  margin-bottom: 10px;
  // @include media("<=phonePortrait") {
  //   width: 60px;
  // }
}

.licenseStateDrop {
  width: 42%;
  margin-left: -5%;
  @include media("<=phonePortrait") {
    width: 105%;
  }
  @media screen and (min-width: 400px) and (max-width: 1400px) {
    //EH-21799: added min and max screen width for making sure state dropdown shows up as expected
    width: 65px;
  }
}

.flexComponent {
  padding: 10px 0;
}
.overrideError {
  input {
    border-style: none none solid !important;
    border-bottom: 2px solid var(--gw-alfa-graphite) !important;
    color: var(--gw-alfa-graphite) !important;
  }
}

.noPOBoxesMessage {
  // margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.4rem;
  color: $alfa-red-color;
  @include media("<=phoneLandscape") {
    margin-top: 10px;
  }
  @include media(">phoneLandscape", "<=tabletLandscape") {
    margin-top: 10px;
  }

  h4 {
    color: $alfa-red-color;
  }
}
.zipErrorMessage {
  font-size: 1.4rem;
  display: block;
  color: $alfa-red-color;
  transform: translateY(-20%);
}
.addressClass {
  input[class^="jut__InputField__input"] {
    padding-left: 0px;
    background-color: var(--gw-grey-light-8);
  }
  div[class^="jut__GenericSelectControl__control"] {
    padding-left: 0px;
    background-color: var(--gw-grey-light-8);
  }
}

.garage {
  padding-bottom: 20px !important;
}

.stateMobile {
  width: 22%;
  margin-left: 7px;
}

.floatTextMobile {
  margin-left: -5px;
}
