// Core Sans N
// Regular
@font-face {
  font-family: 'coresansn';
  /* stylelint-disable */
  src: 
  url('#{$core-sans-pro-root}/WOFF/coresansn35-webfont.woff') format('woff'),
  url('#{$core-sans-pro-root}/WOFF2/coresansn35-webfont.woff2') format('woff2');
   /* stylelint-enable */
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
}

// Bold
@font-face {
  font-family: 'coresansn';
  /* stylelint-disable */
  src: 
  url('#{$core-sans-pro-root}/WOFF/coresansn65-webfont.woff') format('woff'),
  url('#{$core-sans-pro-root}/WOFF2/coresansn65-webfont.woff2') format('woff2');
  /* stylelint-enable */
  font-style: normal;
  font-stretch: normal;
  font-weight: 600;
}