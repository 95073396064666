@import "gw-common-styles";

.gwContactModule {
  --gw-media-sidebar-text-margin: 10px;
  --gw-contact-module-padding: 6px 0 6px 6px;

  display: flex;
  flex-direction: row;
  background-color: var(--gw-white);
  padding: var(--gw-contact-module-padding);
  margin-bottom: var(--gw-media-sidebar-text-margin);

  .gwContactInformationContainer {
    width: 100%;
    line-height: normal;
    font-size: var(--font-s);
    color: var(--gw-grey-light-60-color);
    word-break: break-word;
  }

  .gwImageContainer {
    --gw-image-container-width: 64px;
    --gw-image-container-height: 100%;

    width: var(--gw-image-container-width);
    height: var(--gw-image-container-height);

    img {
      max-width: fit-content;
    }
  }

  .gwDisplayName {
    color: var(--gw-black);
    font-weight: var(--font-weight-normal-bold);
    font-size: var(--base-font-size);
  }

  .gwEmail {
    cursor: pointer;
    color: var(--gw-blue-light-8);
  }
}
