@import "~@jutro/theme/assets/sass/helpers";

:root {
    --gw-wizard-page-tile-margin: 25px;
    --gw-wizard-page-category-margin-bottom: 20px;

    --gw-wizard-side-page-category-padding: 10px;
    --gw-wizard-side-page-separator-margin: 3px;
    --gw-wizard-side-page-separator-height: 18px;
    --gw-wizard-side-page-separator-border: 1px;
    --gw-wizard-side-page-tile-padding: 12px;
    --gwPageTitle-margin: 0 0 40px 0;
}

.gwWizardPageTitle {
    font-size: var(--GW-FONT-SIZE-XL);
    line-height: var(--GW-LINE-HEIGHT-XL);
    font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    color: var(--GW-COLOR-BRAND-2);
    margin: 0 0 var(--gw-wizard-page-tile-margin) 0;

    .gwWizardPageCategory {
        font-size: var(--GW-FONT-SIZE-LG);
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        margin-bottom: var(--gw-wizard-page-category-margin-bottom);
    }

    .gwWizardPageAssociated {
        color: var(--GW-COLOR-LINK-2);
        text-transform: uppercase;
        text-decoration: none;
        margin-left: var(--gw-wizard-side-page-separator-margin);
    }
}

.gwWizardSidePageTitle {
    display: flex;

    .gwWizardSidePageCategory {
        padding-right: var(--gw-wizard-side-page-category-padding);
    }

    .gwWizardSidePageAssociated {
        color: var(--GW-COLOR-FOCUS-1);
        text-decoration: none;
    }

    .gwWizardSidePageTitleSeparator {
        margin-top: var(--gw-wizard-side-page-separator-margin);
        height: var(--gw-wizard-side-page-separator-height);
        border: var(--gw-wizard-side-page-separator-border) solid var(--GW-COLOR-BACKGROUND-2);
    }

    .gwWizardSidepageTitle {
        padding-left: var(--gw-wizard-side-page-tile-padding);
    }
}

.gwPageTitle{
    font-size: var(--GW-FONT-SIZE-XL);
    line-height: var(--GW-LINE-HEIGHT-XL);
    font-weight: var(--GW-FONT-WEIGHT-LIGHT);
    color: var(--GW-COLOR-BRAND-2);
    margin: var(--gwPageTitle-margin);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include gw-breakpoint-up(tablet) {
        flex-direction: row;
    }
}
