.digitalStepper {
    --stepper-button-width: 20px;
    --gw-stepper-button-size: 20px;
    --stepper-input-margin: calc(var(--gw-stepper-button-size) / 2);
    align-items: center;
}

.digitalStepperButton {
    --gw-button-min-width: var(--gw-stepper-button-size);
    --button-height-sm: var(--gw-stepper-button-size);
    i[class*="jut__Button__icon"] {
        line-height: normal;
        font-size: 20px;
      }
}


