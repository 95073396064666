@import 'gw-common-styles';
.gwToolTip {
  min-width: fit-content;
  background-color: none;
  background: none;
  width: fit-content;
  margin-top: -10px;
  @include media('<phoneLandscape')  {
        margin-top: 25px;
      }
  i{
      font-size: small;
  }
  text-align: center;
}
.paHeader {
  display: flex;

    h2 {
      font-size: 30px;
      color: #333333;
      @include media('<phoneLandscape')  {
        font-size: 22px;
        padding: 30px 0 20px 0px;  
      }
    }
    
    padding: 30px 0 0 0px;  
    
    
  }
  .displayFlex {
    display: flex;
    display: -webkit-flex;
    padding: 14px 0;    
  }
  .displayVin {
    padding-top: 20px !important;
  }
  .desktopFlex {
    font-size: 15px;
    position: relative;
    font-family: 'coresansn';
    padding: 16px 0;
    @include media('>phonePortrait')  {
    display: flex;
    display: -webkit-flex;
    }
  }
  .noPadding {
    padding-top: 0 !important;
  }
  .gamsDesktopFlex {
    font-size: 15px;
    position: relative;
  }
  .questionInfoDiv {
 
    position: relative;
    margin-right: 15px;
    color: var(--gw-alfa-graphite);
    width:65%;
    display: flex;
    
    @include media('<=phonePortrait') {
    width: 80%;
    }
    
  }
  .spanInfoDiv {
 
    position: relative;
    margin-right: 15px;
    color: var(--gw-grey-dark-90-color);
    width:60%;
    
    @include media('<=phonePortrait') {
    width: 80%;
    }
    
  }
  .radioToggle {
    width: 128px;
    @include media('>tabletPortrait') {
      position: absolute;
      right: 0;
      left: 80%
    }
    @include media('>phoneLandscape', '<=tabletPortrait') {
      left: 71%;
      position: absolute;
    }
    @include media('<=phoneLandscape') {
      padding-top: 10px;
      width: 250px;
    }
  }
  .vinFieldDiv{
    @include media("<tabletPortrait"){
      width: 100%;
    }
    @include media(">=tabletPortrait","<tabletLandscape"){
      width: 85%;
    }
    @include media(">=tabletLandscape"){
      width: 70%;
    }
    margin-right:10px;
    position: relative;
  }
  .lookupContainer {
    position: relative;
    padding:10px 0 20px 0;
  }
  .secondaryLookupDiv {
    position: relative;
  }
  .secondaryLookupContainer {
    position: relative;
    padding:10px 0 20px 0;
    width: 80%;
  }
  .clearButton {
    color: black;
    position: absolute;
    right: 10%;
    bottom: 50%;
  }
  .phoneNumberDiv {
    &:focus {
      outline: none;
    }

  }
  .licenseFieldDiv {
   @include media("<=phoneLandscape"){
      display: none;
    }
    @include media(">phoneLandscape","<tabletLandscape"){
      width: 190px
    }
    @include media(">=tabletLandscape"){
      width: 250px;
    }
    
    position: relative;
    padding-top: 9px;
    div[class^="jut__FieldMessage__fieldMessage jut__FieldMessage__error"] {
      display: none !important ;
    }
  }

  .mobileLicenseFieldDiv {
    width: 190px;
    position: relative;
    margin-top: 1.5rem;
    div[class^="jut__FieldMessage__fieldMessage jut__FieldMessage__error"] {
      display: none !important ;
  }
  @include media('>phoneLandscape') {
    display: none
  }
  }
  .mvrlicenseNumberComponent{
    div[class^="jut__FieldMessage__fieldMessage jut__FieldMessage__error"] {
      display: none !important ;
  }
  }
 
  .buttonGroupClass {
    position: relative;
    padding: 0px 0px 20px 0px;
    margin: 30px 0 20px 0;
  }
  .licenseError{
    color: #b94a48;
  }
 


  .addButton {
      position: absolute;
      right: 0;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: bold;
  }
  .vinInfoDivText {
    
    font-size: 15px;
    font-weight: bold;
  }

  .garageAddrDiv {
    width: 70%;
    @include media('>phoneLandscape', '<=tabletLandscape') {
      width: 60%;
    }
  }

  .addressName {
    @include media('<=phonePortrait')  {
      display: none;
    }
    padding-bottom: 10px;
    
    @include media('>tabletLandscape') {
      margin-top: 0px !important;
    }
  }

  .mailingAddressName {
    @include media('<=phonePortrait')  {
      display: inline;
    }
    padding-bottom: 10px;
    
    @include media('>tabletLandscape') {
      margin-top: 0px !important;
    }
  }
  .addressNameLabel {
    text-transform: uppercase;
  }
  .vinInput {
    input {
    text-transform: uppercase !important;
    }
  }
  .additionalLink {
    color : black !important;
    text-decoration: underline;
  }

  .leaseContainer {
    padding: 20px 0 12px 0;
  }

  .leaseLookup {
    @include media("<tabletPortrait"){
      width: 100%;
    }
    @include media(">=tabletPortrait","<tabletLandscape"){
      width: 85%;
    }
    @include media(">=tabletLandscape"){
      width: 70%;
    }
  }  


  .addressNameMobile {
    @include media('>phonePortrait')  {
      display: none;
    }
    //font-size: 16px;
    padding-bottom: 10px;

  }
  .toggleControlClass {
    width: 128px;
    button[class*="digitalToggleActive"] {
    background-color: #CCCCCC ;
    }
   }
   .toggleLabelName {
     font-size: 15px;
   
     @include media('<=phoneLandscape') {
       font-size: 18px;
     }
   }
  .phoneErrorMessage {
    font-size: 14px;
    display: block;
    color: $alfa-red-color;
  
  }
  .errorMessage {
    font-size: 14px;
    display: block;
    margin-top: 12px;
    color: $alfa-red-color;
    transform: translateY(-100%);
    
  }
  .dateErrorMessage {
    font-size: 14px;
    display: block;
    margin-top: 10px;
    color: $alfa-red-color;
    transform: translateY(-100%);
    @include media('<=phoneLandscape') {
      transform: translateY(-30%);
    }
  }

  .iconFontStyle {
    font-size: unset;
    margin-top:10px;
  }
  .labelExcluded{
    color: #4D9CBC;
    font-size: 12px;
    font-weight: bold;
  }

.paMessageHeader {
  padding: 8px 0 40px 0px;  
  h3 {
    font-size: 20px;
    font-weight: normal;
    color:#333333;

    @include media('<=phoneLandscape') {
      display: none;
    }
  }
}
.driverContainers {
  background-color: var(--gw-grey-light-8);
  padding: 30px;
  width: 80%;
  margin-bottom: 30px;

  @include media('<=tabletPortrait') {
    width: 100%;
    padding: 5px;
  }

   @include media('>tabletPortrait', '<=tabletLandscape') {
    width: 86%;
    padding: 5px;
  }
}
.newDriverContainers {
  padding: 30px;
  width: 75%;
  margin: 30px 0 0 0;
  background-color: white;

  @include media('<=tabletPortrait') {
    width: 100%;
    padding: 5px;
  }

   @include media('>tabletPortrait', '<=tabletLandscape') {
    width: 86%;
    padding: 5px;
  }
}
.accordionPanel {
  margin: -2px 0px;
  padding: 32px;
  background-color: white;
  border: 1px solid var(--greyscale-boulder-999999, #999);
  border-radius: 0px 0px 5px 5px;
  z-index: -1;
  color: var(--gw-alfa-graphite);

  @include media('<=phonePortrait') {
    padding: 32px 16px;
  }
}

.accordionContainer {
    margin: 0px 0px 24px 0px;
}
.accordionContainerDisable {
  pointer-events: none;
}
.accordionSetClass {
  @include media('>=tabletLandscape') {
  width: 71rem;
  }
}
.driverName {
  font-weight: bold;
  font-size: 15px;
}
.mvrDriverName {
  font-weight: bold;
  font-size: 15px;
  position: relative;
}
.componentInput {
  input {
  width: 70px !important;
  ::-webkit-input-placeholder { /* Chrome */
    color: --gw-grey-2 !important;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: --gw-grey-2 !important;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: --gw-grey-2 !important;
  }
  :-moz-placeholder { /* Firefox 4 - 18 */
    color: --gw-grey-2 !important;
    opacity: 1;
  }
  }
  width: auto !important;
  div[class^="jut__FieldMessage__error"] {
    display: none;
  }
  
}
.driverDropDown {
  width: 303px;
  @include media('>phonePortrait') {
    width: 300px;
    div > div[class="jut__GenericSelectControl__control"] {
      width: 32rem;
    }
    div > div[class="jut__GenericSelectControl__control jut__GenericSelectControl__controlFocused"] {
      width: 32rem;
    }
  }

  div > div[class="jut__GenericSelectControl__control"] {
    width: 32rem;
    }
  div > div[class="jut__GenericSelectControl__control jut__GenericSelectControl__controlFocused"] {
    width: 32rem;
    }
  
  div > button[class*="clearButton"] {
    right: 10px;
    @include media('>phoneLandscape', '<=tabletLandscape') {
      right: 2em;
      position: relative;
    }
    @include media('<=phonePortrait') {
      right: 3em;
      position: relative;
    }
  }
}
.emailContact {
  width:230px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
    input[class^="jut__InputField__input"] {
      padding-left: 0px;
    }   
}
.policyStartDate {
    width:230px;
    input[class^="jut__InputField__input"] {
      padding-left: 0px;
    }   
  @include media('>phonePortrait') {
    width: 230px;
    }
    label[class*="jut__FieldLabel__fieldLabel"] {
     font-weight: bold;
    }
  div[class^="react-datepicker-popper"] {
     transform: translate3d(0, -100%, 0) !important;
    }
  div[class^="jut__GenericSelectControl__control"] {
    input {
      width: 100% !important;
    }
  }
}
.emailDiv {
  margin: 10px 0;
}

.licenseGroup {
  @include media('<tabletPortrait') {
    margin-top: 0px;
    desktop: flex;
  }
  @include media(">=tabletPortrait","<tabletLandscape"){
    margin-top: 20px;
  }
  @include media(">=tabletLandscape"){
    margin-top: 10px;
  }
}

.driverLicense {
  display:flex;
  @include media('<=phonePortrait') {
    margin-top: 3rem;
    display:block;
  }
}

.driverLicenseMoreDetails {
  display:flex;
  font-size: 'coresansn';
  @include media('<=phonePortrait') {
    margin-top: 3rem;
  }
  
}
.licenseLabel {
  width: 110px;
  @include media('>phonePortrait') {
    padding-top: 30px;
    margin-right:30px;
  }
}
.licenseStateDrop {
  width: 100px;
  @include media("<=phoneLandscape"){
      width: 190px;
  }
   // transform: translateY(-24%);
   // margin: 0 20px 0 0px
    margin: -10px 20px 0 0px
}
.mvrLicenseStateDrop {
    width: 100px;
    margin: -10px 20px 0 0px
}
.licenseStateLabel {
  font-size: 12px;
  top: 2px;
  @include media('>phonePortrait') {
    top: -8px;
    }

}
.emailError {
  transform: translateY(-50%) !important;
}
.leaseError {
  transform: translateY(0%) !important;
}
.mvrDate {

    position:absolute;
    right: 250px;
    font-weight: bold;
    @include media('>phoneLandscape', '<=tabletLandscape') {
      right: 135px;
    }
    @include media('<=phonePortrait') {
      right: 40px;
    }
}

.separatorClass {
  font-weight: bold;
  width: 20px !important;
  text-align: center !important;
}
.policyCaption {
  padding: 16px 10px 10px 0px;
  font-size: 12px;
  color: #5f574f;
  margin: 0 10px;
  line-height: normal;
}

.blockCollapse {
  display: block !important;
}

.allowCollapse {
  display: none !important;
}
.ssnStyle > div > div[id*="componentDiv"] > span {
  font-size: 12px;
  color: var(--gw-alfa-graphite);
}
.ssnStyle >  div > div[id*="componentDiv"] > div[id*="inputDiv"] input {
  font-size: 16px;
  color: var(--gw-alfa-graphite);
}
.ssnStyle >  div > div[id*="componentDiv"] > div[id*="inputDiv"] input[type="text"]::placeholder {
  font-size: 14px;
}
.ssnStyle > div > div[id*="componentDiv"]> div[id*="inputDiv"] > span[id*="firstSeparator"] {
  width: 20px;
  text-align: center;
}
.ssnStyle > div > div[id*="componentDiv"]> div[id*="inputDiv"] > span[id*="secondSeparator"] {
  width: 20px;
  text-align: center;
}

.mockError {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom: 2px solid #c30717;

 
}
.mock:focus{
  border-color: none;
  box-shadow: none;
}
.phoneContainerMobile {
  @include media('<=phoneLandscape') {
    width: 13rem;
  }
}
.phoneDivWidth {
  font-weight: bold;
  margin-top: 16px;
  @include media(">=desktopSmall") {
    width: 30rem;
  }
}
.phoneClass {
  input[class^="jut__InputField__input"] {
    padding-left: 0px;
  }  
    @include media(">phonePortrait") {
     width: 140px;
    }
}

  div[id^="vehicleButtons"] button[class^="digitalButton"] {
        min-width: 103px;
  }
  div[id^="driverButtons"] button[class^="digitalButton"] {
        min-width: 103px;
  }
  div[id^="contactButtons"] button[class^="digitalButton"] {
        min-width: 103px;
  }
  div[id^="moreButtons"] button[class^="digitalButton"] {
        min-width: 103px;
  }
  div[id^="bodyContainer"] div[class^="jut__Accordion__accordion"] {
      --accordion-border-width: 0px;
  }

.textAlign {
  top: -10px !important;
  font-size: 15px !important;
}

.mvrLicenseFieldDiv {
   @include media("<=phoneLandscape"){
      width: 190px
    }
    @include media(">phoneLandscape","<tabletLandscape"){
      width: 190px
    }
    @include media(">=tabletLandscape"){
      width: 250px;
    }
    
    position: relative;
    padding-top: 9px;
    div[class^="jut__FieldMessage__fieldMessage jut__FieldMessage__error"] {
      display: none !important ;
    }
  }

.gwToolTipVin {
  min-width: fit-content;
  background-color: none;
  background: none;
  width: fit-content;
  margin-right: 10px;
  i{
      font-size: small;
  }
  text-align: center;
}

.separation > div:last-child > hr{
    border-top: none;
}