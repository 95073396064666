@import "~gw-common-styles";
@import "~@jutro/theme/assets/sass/helpers";

.crcStyle {
    position: fixed;
    width: 30%;
    z-index: 100;
    margin-top: 120px;
}

.crcStyleMobile {
    position: fixed;
    left: 70%;
    width: 30%;
    z-index: 100;
    margin-top: 120px;
}

.crcPointer {
    position: relative;
    bottom: 109px;
    left: 117px;
    width: 0;
    height: 0;
    border: 16px solid transparent;
    border-top: 0;
    border-bottom: 20px solid #ECECEB;
    float: left;
    margin-top: 30px;
    margin-left: 4px;
    margin-right: -5px;
    transform: rotate(270deg);
    z-index: 1;
}

.phoneStyle {
    position: relative;
    top: -7px;
    left: 0px;
}

.crcImageStyle {
    position: relative;
    border-radius: 4px;
    top: -38px;
    left: 30px;

    @media (orientation: landscape) {
        display: block;
    }
}

.crcIconMobileClass {
    margin: 0px 0px;
    width: 25%;
    height: 7% !important;

    // @include gw-breakpoint-down(tablet) {
    //     margin: 10px 5px;
    //     height: 60px !important;
    // }

    @media only screen and (max-width: 1023px) and (min-width:750px) {
        margin: 10px 0px;
        width: 35%;
        height: 60px !important;
    }

    @media only screen and (max-width: 750px) and (min-width:460px) {
        margin: 10px 0px;
        width: 35%;
        height: 55px !important;
    }


    @media only screen and (max-width: 460px) and (min-width:330px) {
        margin: 10px 0px;
        width: 28%;
        height: 40px !important;
    }

    @media only screen and (max-width: 330px) and (min-width:100px) {
        margin: 10px 0px;
        width: 25%;
        height: 40px !important;
    }
}

.crcImageMobileStyle {
    position: relative;
    left: 95%;
    border-radius: 6rem;
    background-color: #ECECEB;
    width: 290px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @include gw-breakpoint-down(tablet) {
        left: 72%;
        width: 200px;
    }

    @media only screen and (max-width: 1023px) and (min-width:875px) {
        left: 70%;
        width: 150px;
    }

    @media only screen and (max-width: 875px) and (min-width:700px) {
        left: 65%;
        width: 150px;
    }

    @media only screen and (max-width: 700px) and (min-width:600px) {
        left: 60%;
        width: 150px;
    }

    @media only screen and (max-width: 600px) and (min-width:580px) {
        left: 60%;
        width: 125px;
    }

    @media only screen and (max-width: 580px) and (min-width:500px) {
        left: 55%;
        width: 125px;
    }

    @media only screen and (max-width: 500px) and (min-width:400px) {
        left: 50%;
        width: 125px;
    }

    @media only screen and (max-width: 400px) and (min-width:320px) {
        left: 45%;
        width: 125px;
    }

    @media only screen and (max-width: 320px) and (min-width:100px) {
        left: 40%;
        width: 125px;
    }
}

.cardHeader {
    padding: 0.2rem 5rem;
    margin-bottom: 0;
    background-color: #ECECEB;
    font-weight: bold;
    height: 70px;
}

.phoneContact {
    position: relative;
    bottom: 10px;
    font-size: 12px;
    color: #287D9F;
    text-decoration-line: underline;
    margin-left: 6px;
}

.phonetxt {
    font-size: 10px;
    padding: 3px;
    color: black;
}

.crcMargin {
    margin-left: 22px;
}

.crcDivQuote {
    position: relative;
    left: 110px;
    bottom: 110px;
    width: 266px;
    border-radius: 4px;
    float: left;
    height: auto;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @media (orientation: landscape) {
        display: block;
    }
}

.mobileCrcDivQuote {
    position: relative;
    width: 265px;
    border-radius: 4px;
    float: right;
    height: auto;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @include gw-breakpoint-down(tablet) {
        display: none;
    }
}

.headerText {
    font-size: 14px;
    color: var(--gw-grey-dark-90-color);
    position: relative;
    bottom: 140%;
    letter-spacing: -1px;
    width: 180px;
    padding-right: 10px;
    left: 18px;

    @include gw-breakpoint-down(tablet) {
        font-size: 12px;
        padding-right: 25px;
    }
}

.headerTextMobile {
    font-size: 14px;
    color: var(--gw-grey-dark-90-color);
    position: absolute;
    padding-right: 20px;
    top: 10%;
    letter-spacing: -1px;
}

.gwUserimage {
    display: inline-block;
    width: 80px;
    height: 40px !important;
    top: 20%;
    position: relative;
    margin-bottom: 6.2rem;
    right: 30%;

    @include gw-breakpoint-down(tablet) {
        right: 39%;
        top: 20%;
    }
}

.chevronStyle {
    position: relative;
    bottom: 25%;
    left: 63%
}