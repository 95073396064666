.gw-branding-millennials {
    body {
        --GW-COLOR-BRAND-1: var(--gw-pink-1);
        button {
            --GW-COLOR-BRAND-1: var(--gw-pink-1);
            --GW-COLOR-BRAND-1-GRADIENT: var(--gw-pink-1);
            --GW-COLOR-BRAND-1-ACTIVE: var(--gw-pink-1);
            --GW-COLOR-BRAND-1-GRADIENT-HOVER: var(--gw-pink-1);
            --GW-COLOR-BRAND-1-HOVER: var(--gw-pink-1);
        }
        a{
            color: var(--gw-pink-1);
            text-decoration: none;
        }
        a span:active,a span:hover{
            --GW-TEXT-DECORATION-UNDERLINE: none;
        }
        .jut__Button__tertiary{
            --GW-COLOR-BRAND-1: var(--gw-lime-green);
        }
        .digitalToggleButton {
            --toggleButton-color: var(--gw-grey-light-60-color); 
            background-image: var(--toggleButton-bg-image);
            background-color: var(--toggleButton-bg-color);
        }
        .digitalAccordionToggle h2, #adjusterTitleDiv h2 {
            --gw-millennials-accordion-toggle-border-spacing: 2px;
            border-bottom: var(--gw-millennials-accordion-toggle-border-spacing) solid var(--gw-pink-4);
        }
        .actionIcon{
            --GW-COLOR-BRAND-1: var(--gw-lime-green);
            --GW-COLOR-BRAND-1-GRADIENT: var(--gw-lime-green);
            --GW-COLOR-BRAND-1-ACTIVE: var(--gw-green-2);
            --GW-COLOR-BRAND-1-GRADIENT-HOVER: var(---gw-green-2);
            --GW-COLOR-BRAND-1-HOVER: var(--gw-green-2);
        }
    }

    .gw-header {
        --header-border-bottom-size: 10px;
        --gw-header-height: 10rem;
        --gw-header-image: url("../../public/branding/millennial_logo.png");
        background-color: var(--gw-white);
        span, a, select {
            color: var(--gw-pink-1);
        }
    }
    #startQuoteContainer {
        a{
            color: var(--gw-blue-light-8);
            text-decoration: underline;
        }
        a:active, a:hover, a:focus {
            color: var(--gw-dark-blue-6);
        }
    }
    div[class^='quoteandbind-common-styles_quoteInfoBar'] {
        --gw-quote-info-bar-background: var(--GW-COLOR-BRAND-1);
    }
    div[class^='QuoteClauseTable_quoteTableHeaderSection'] a{
        color: var(--gw-blue-light-8);
    }
    #editCoveragesLink{
        color: var(--gw-blue-light-8);
    }
    .clauseCategoryContainer button {
        color: var(--gw-blue-light-2);
    }
    
}