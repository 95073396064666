@import 'gw-common-styles';

.gwToolTip {
    min-width: fit-content;
    background-color: none;
    background: none;
    width: fit-content;
    i{
        font-size: small;
    }
    text-align: center;
}

.dobMobileVisible {
    @include media(">phoneLandscape") {
      display: none !important;
  }
  width:150px;
  }
.insuranceRadioSpan {
  @include media(">phoneLandscape") {
    width: 95% !important;
  } 
}
.currentInsuranceDiv {
  width: 250px !important;
}
.nameDisplayFlex {
  @include media(">phoneLandscape") {
    display: flex;
    display: -webkit-flex;
  }
}
.displayFlex {
  display: flex;
  display: -webkit-flex;
  margin-right: -10rem;
}
  
  
  .radioContainer {
    @include media(">phoneLandscape") {
      display: flex;
      display: -webkit-flex;
      width: 80%;
    }    
    padding: 10px 0;
    position: relative;
  }
  .toggleControlClass {
    width: 150px;
   }
   .toggleLabelName {
     font-size: 15px;
     color: #333333;

   }
  .driverDropDown {
    @include media('>phoneLandscape') {
    width: fit-content;
    padding-right: 80%;
    margin-left: -5px
    }
    @include media('<phoneLandscape') {
      width: 80%;
      }
    width: fit-content;
    padding:10px 0;
  }
  .driverMaritalDropDownStyle {
    @include media('>tabletLandscape') {
      width: 50%;
    }
  }
  .courseDropDown {
    @include media('>phoneLandscape') {
    width: 45%;
    }
    width: 70%;
    
    padding:10px 0;
  } 
  .relativeDiv {
    position: relative;
  }
  .wizardGroupLabel {
    font-size: 12px;
    color: #333333;
  }
  .displayBlock {
    display: block;
    color: #287D9F;
    font-size: 16px;
    font-family: 'coresansn';
    margin-top: -15px;
    font-weight: bold;
  }
  .dontWishDiv {
    display: block;
    div[class*="jut__FieldMessage__error"] {
      display: none;
    }
  }
  .validateBtn {
    @include media('<=phoneLandscape') {
      display: none;
    }
   
    position: absolute;
    right: 0px;
    bottom: 0;
    background-color: $alfa-btn-active;
    box-shadow: 0 1px 1px 0 $alfa-btn-box-shadow;

  &:hover {
    background-color: $alfa-btn-hover;
  }

  }

  .validateMobileBtn {
    @include media('>phoneLandscape') {
      display: none;
    }
   
    position: absolute;
    right: 0px;
    bottom: 0;
    background-color: $alfa-btn-active;
    box-shadow: 0 1px 1px 0 $alfa-btn-box-shadow;

  &:hover {
    background-color: $alfa-btn-hover !important;
  }

  }
  .digitalContentContainer {
    grid-area: revert;
    width: 100%;
    align-self: center;
}
.smallBlock {
  width: 40%;
  margin-right: 9rem;
}

.suffixStyle {
  width: 40%;
  margin-left: 20px;
  @include media('<=phonePortrait') {
    width: 80%;
    }
}
.nameMediumBlock{
  width: 40%;
}
.driverErrorMessage {
  font-size: 1.4rem;
  display: block;
  color: $alfa-red-color;
  transform: translateY(-20%);

}
.insuranceSpan {
  @include media('>tabletLandscape') {
  width: 90%;
  }
}
.spanInfoDiv {
 
  position: relative;
  margin-right: 15px;
  color: #333333;
  
  @include media('<=phonePortrait') {
  width: 90%;
  }
  
}
.radioSpan {
  color: var(--gw-gray-dark-7);
  width: 125px;
  @include media('<=phoneLandscape') {
    width: 200px;
    margin-left: 0;
  }
  div[class*="jut__FieldMessage__error"] {
    display: none;
  }
  button[class*="digitalToggleActive"] {
    background-color: #CCCCCC ;
  }
}
.radioSpanGender {
  display: flex;
  div[class*="jut__FieldMessage__error"] {
    display: none;
  }
}
.flexMargin {
  margin-left: 0px !important;
}
.componentSeparator {
  margin-right: 20px;
}
.genderSeparator {
  @include media('<=phoneLandscape') {
  margin-right: 20px;
  }
}
.dateCompDiv {
  padding-bottom: 24px;
}
.violationMain {
  position: relative;
  padding: 10px 0 20px 0;
}
.violationSpan {
  font-size: 12px;
  color: grey;
  position: absolute;
  top: 60px;
  width: 80%;
}
.violationLabelSpan {
  width: 250px;
}
.buttonGroupClass {
  position: relative;
  padding: 20px 0 0 0;
}
.cancelButton {
  text-decoration: underline;
  font-weight: normal;
  box-shadow: none !important;
  text-align: left;
  >span{
    display: flex;
    margin-left: -25px;
  }
}
.addButton {
    position: absolute;
    font-weight: bold;
    right: 0;
    color: #FFFFFF;
    font-size: 16px;
}
.commoditiesDiv {
  width:90%;
}