@import '~gw-common-styles';

.gwQuoteTable {
    --gw-quote-table-border: 1px solid var(--gw-grey-light-30-color);

    @include media("<phonePortrait") {
        & > div {
            border: var(--gw-quote-table-border);
            border-bottom: none;

            &:last-child {
                border-bottom: var(--gw-quote-table-border);
            }
        }
    }

    & > div:first-child {
        --gw-quote-table-title-bar-sizing: 8px;
        border-right: var(--gw-quote-table-border);
        border-left: var(--gw-quote-table-border);
        border-top: none;
        border-bottom: none;
        background-color: var(--gw-white-opaque-5);
        font-weight: var(--font-weight-bold);
        padding: var(--gw-quote-table-title-bar-sizing);
    }

    table {
        background-color: var(--gw-white);

        thead, th {
            display: none;
        }

        tr {
            border: none;

            &:hover {
                background-color: unset;
            }
        }
    }
}

td.gwTableCell {
    --table-padding-y: 0;
    --table-padding-x: 0;
    --table-cell-border-width: 1px;
    --table-border-color: var(--gw-grey-light-30-color);
    --table-cell-width: 200px;

    width: var(--table-cell-width);
    vertical-align: top;
    border: var(--gw-quote-table-border);
}

.accordionCardQuote {
    margin: 0;
}

.quoteContainer {
    --gw-quote-table-phone-margin: 10px;
    margin-bottom: var(--gw-quote-table-phone-margin);
}
