@import 'gw-common-styles';

:root {
    --gw-modal-title-padding-bottom: 25px;
    --gw-modal-width: 720px;
    --gw-button-container-margin-top: 20px;
    --gw-cancel-button-margin-right: 10px;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: var(--gw-button-container-margin-top);
}
.policyCoverageStyle {
  top: 0, 0, 0, 0;
    padding-top: 0;
    min-width: 300px;
    width: 720px;
    height: auto;
    max-height: 500px;
    border-radius: 5px;
    box-shadow: 0 3px 0 0 #F4F2EA;
    @include media('<phoneLandscape') {
        width:325px;
    }
    @include media('>=phoneLandscape','<=tabletLandscape') {
        width: 400px;
     }
}
.divContainerStyle {
  display: flex;
  margin-left: -17%;
  position: relative;
  width: 718px;
  border-radius: 5px 5px 0px 0px;
  background: #F4F2EA;
  height: 45px;
  padding: 5px;
  @include media('<phoneLandscape') {
      width:325px;
      margin-left: -3.5%;
  }
  @include media('>=phoneLandscape','<=tabletLandscape') {
      width: 400px;
      margin-left: -27%;
  } 
  
  h2 {
    margin-left:10px;
    font-size: 2.8rem;
    font-weight: bold;
    color: var(--gw-alfa-graphite);
  }
}
.closeBtn {
  position: absolute;
  right: 10px;
  height: 20px;
  width: 20px;
}
.descriptionSpanDiv {
  padding:10px;
}

.cancelBtnMargin {
    margin-right: var(--gw-cancel-button-margin-right);
    span {
      font-weight: bold;
    }
}

.fontThick {    
    font-weight: bold;
}
.displayFlex{
  display: flex;
}
.gw-social-image {
  height: 50px;
  width: 5%;
  padding-top: 20px;
  @include media('<=phoneLandscape') {
    display: none !important;
  }
  @include media('>=phoneLandscape','<=tabletLandscape') {
    width: 7%;
  }
  }

.modalWidth {
  grid-template-columns: none !important;
}

.activityContainer {
    width: 700px;
    height: 375px;
    margin-left: -15%;
    @include media('<phoneLandscape') {
      width:300px;
      margin-left: -3.5%;
  }
  @include media('>=phoneLandscape','<=tabletLandscape') {
      width: 375px;
      margin-left: -22%;
  }  
    overflow-y: scroll;
  }



