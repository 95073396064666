@import 'gw-common-styles';

:root {
    --gw-modal-title-padding-top: 20px;
    --gw-modal-title-padding-bottom: 25px;
    --gw-modal-width: 420px;
    --gw-button-container-margin-top: 20px;
    --gw-cancel-button-margin-right: 10px;
}

.alfa-add-std-modal {
    //display: flex;
    padding-top: 0;
    min-width: 300px;
    width: 420px;
    height: auto;
    max-height: 460px;
    overflow-y: auto;
    border-radius: 5px;
    box-shadow: 0 3px 0 0 #F4F2EA;
    @include media('<=phoneLandscape') {
        width:325px;
    }
    @include media('>phoneLandscape','<=tabletLandscape') {
        width: 400px;
    }  
}

.alfaAddStdModal {
    //display: flex;
    top: 0, 0, 0, 0;
    padding-top: 0;
    min-width: 300px;
    width: 520px;
    height: auto;
    max-height: 460px;
    //overflow-y: auto;
    border-radius: 5px;
    box-shadow: 0 3px 0 0 #F4F2EA;
    @include media('<phoneLandscape') {
        width:325px;
    }
    @include media('>=phoneLandscape','<=tabletLandscape') {
        width: 400px;
    }  
}
.activityContainer {
  padding-top: 10px;
  width: 485px;
  height: 350px;
  margin-left: -15%;
  @include media('<phoneLandscape') {
    width:300px;
    margin-left: 0%;
    //height: 375px;
    overflow-y: scroll;
}
@include media('>=phoneLandscape','<=tabletLandscape') {
    width: 375px;
    margin-left: -20%;
    //height: 375px;
    overflow-y: scroll;
}  
  
}
.darkColor {
  color: #000;
  @include media('<=phoneLandscape') { 
    margin-left: 70px;    
}
@include media('>phoneLandscape','<=tabletLandscape') {
    margin-left: 137px;
}
margin-left: 263px;
  
}

.gw-alfa-heading {
    display: flex;
    margin-left: -17%;
    font-size: 1.9rem;
    font-weight: 300;
    width: 518px;
    border-radius: 5px 5px 0px 0px;
    background: #F4F2EA;
    height: 45px;
    word-spacing: 10px;
    letter-spacing: 1px;
    @include media('<phoneLandscape') {
        width:324px;
        margin-left: -3%;
    }
    @include media('>=phoneLandscape','<=tabletLandscape') {
        width: 398px;
        margin-left: -26%;
    }  
}

.alfa-header-icon {
    font-size: 2.5rem;
    font-weight: 300;
    word-spacing: 10px;
    letter-spacing: 1px;
    padding-left: 5px;
    margin: 10px 0 0 10px;
    height: 45px;
    color:#4d9cbc;
    @include media('<phoneLandscape') {
    }
    @include media('>=phoneLandscape','<=tabletLandscape') {
    }  
}

 .alfa-header-text {
    font-size: 2.4rem;
    font-weight: 600;
    word-spacing: 10px;
    letter-spacing: 1px;
    padding-left: 20px;
    margin-top: 10px;
    height: 45px;
    color: black;
    margin-left: -13px;
    @include media('<phoneLandscape') {
        margin-left: -12px;
    }
    @include media('>=phoneLandscape','<=tabletLandscape') {
        margin-left: -5px;
    }  
}

.instructionText {
    margin-left: 1%;
    padding-top: 8px;
    
    @include media('>tabletLandscape') {
        transform: translateX(-18%);
    }

    @include media('>=phoneLandscape','<=tabletLandscape') {
        width: 350px;
        margin-left:-20%;
        font-size: 110%;
    }  
}

.stdRadioButtonElement {
    --radioButton-label-left-margin: 1.0rem;
    --radioButton-label-padding: 4.0rem;
    

    padding-right: 10px;
    padding-left: 10px;
    @include media('<phoneLandscape') {
        margin-left: 10%;
    }
    @include media('>=phoneLandscape','<=tabletLandscape') {
        width: 350px;
        margin-left:-10%;
        padding-top: 0px;
        padding-bottom: 0px;
    }  
}

.themeRoot hr {
    margin: 2rem 0;
}

.hr {
    width: 90%;
    height: 1.5px;
    border-width:0;
    color:gray;
    background-color:gray;
    padding-left: 25px;
    @include media('<phoneLandscape') {
        margin: 2rem 3rem !important;
        padding-left:0;
    }
    @include media('>=phoneLandscape','<=tabletLandscape') {
        width: 100%;
        margin-left: -2rem !important;
    }  
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: var(--gw-button-container-margin-top);
    @include media('<phoneLandscape') {
    }
    @include media('>=phoneLandscape','<=tabletLandscape') {
    }  
}
.selectButton {
    min-width: 75px;
}
.fontThick {    
  font-weight: bold;
}

.cancelBtnMargin {
    margin-right: var(--gw-cancel-button-margin-right);
    @include media('<phoneLandscape') {
    }
    @include media('>=phoneLandscape','<=tabletLandscape') {
    }  
}
