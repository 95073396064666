@import "gw-common-styles";
@import "~@jutro/theme/assets/sass/helpers";

.closeButtonStyle {
    border: none;
    float: right;
    margin-top: -4rem;
    height: 10px;
    width: 10px;

    @include gw-breakpoint-down(phone) {
        margin-top: 3rem !important;
    }
}

.titleStyle {
    width: 134%;
    height: 49px;
    left: 400px;
    top: 222px;
    font-weight: bold;
    font-size: 22px;
    line-height: 35px;
    display: flex;
    background-color: #F4F2EA;
    margin-left: -17%;
    padding-left: 18%;
    margin-top: -2rem;

    @include gw-breakpoint-down(phone) {
        width: 330px;
        height: 0px;
        font-weight: bold !important;
        font-size: 1.8rem !important;
        margin-left: -46%;
        padding-left: 25% !important;
    }
    @include gw-breakpoint-down(tablet) {
        margin-left: -25%;
        width: 150%;
        padding-left: 25% !important;
    }
}

.divContainerOneStyle {
    //padding-top: 5rem;

    @include gw-breakpoint-down(phone) {
        background: #F4F2EA;
        padding-right: 1rem;
        height: 65px;
    }
}

.panelStyle {
    background: #fff;
    border-radius: 2px;
    width: 700px;
    height: 580px;
    top: 211px;
    left: 360px;

    @include gw-breakpoint-down(phone) {
        width: 27rem;
        height: 47rem !important;
        min-width: unset;
    }

    @include gw-breakpoint-down(tablet) {
        height: 650px;
    }    
}

.mobileDivStyle {
    font-size: 12px;
    line-height: 2rem;
    font-size: small;
    max-height: 31rem;
    @include gw-breakpoint-down(phone) {
        max-height: 21rem;
        overflow-y: scroll;
    }
}

.mobileBtnStyle {
    @include gw-breakpoint-down(phone) {
        margin: auto;
        // display: block;
    }

}

.divTwoMobileStyle {
    @include gw-breakpoint-down(phone) {
        margin-top: 2rem;
    }
}

.divThreeMobileStyle {
    margin-top: 13rem;
    @include gw-breakpoint-down(tablet) {
        margin-top: 17rem;
    }
    @include gw-breakpoint-down(phone) {
        margin-top: 4rem;
    }
}

.rootDivStyle {
    @include gw-breakpoint-down(phone) {
        margin-top: -2.5rem;
    }
}