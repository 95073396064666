@import "~gw-common-styles";

.selectContainer {
  display: flex;
  display: -webkit-flex;
  position: relative;
  width:100%;
}

.selectInput {        
  border: none !important;  
  .jut__GenericSelectControl__control {
    border: none !important;
    border-bottom: 2px solid var(--gw-alfa-graphite) !important;
  }
 
}

.clearButton {
  right: 20px;
  top: 2px;
  position: absolute;
  outline: none;
  i[class*="jut__IconButton__dark"] {
    color: var(--gw-alfa-graphite);
  }
}