.stepContainer {
    --gw-wizard-mobile-navigation-size: 9px;
    --gw-wizard-mobile-navigation-margin: 7px;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

.navigationButton {
    background-color: var(--GW-COLOR-BACKGROUND-1);
    border: 0;
    padding: 0;
}

.step {
    margin-right: var(--gw-wizard-mobile-navigation-margin);

    &::before {
        content: '';
        display: inline-block;
        min-width: var(--gw-wizard-mobile-navigation-size);
        height: var(--gw-wizard-mobile-navigation-size);
        background-color: var(--GW-COLOR-BRAND-1);
        border-radius: 50%;
        vertical-align: middle;
    }
}

.notVisited::before {
    background-color: var(--GW-COLOR-STROKE);
}

.active::before {
    background-color: var(--GW-COLOR-BRAND-2);
}

.visited::before {
    background-color: var(--GW-COLOR-BRAND-1-GRADIENT);
}