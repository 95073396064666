.digitalRoot {

    // FONTS
    --GW-VALUE-FONT-FAMILY: var(--base-font-family);
    --GW-FONT-FAMILY: var(--base-font-family);
    --GW-FONT-SANS-SERIF: 'coresansn', Helvetica, Arial, sans-serif;

    // FONT SIZES
    --GW-FONT-SIZE-XS: var(--font-xs);
    --GW-FONT-SIZE-SM: var(--font-s);
    --GW-FONT-SIZE: var(--base-font-size);
    --GW-FONT-SIZE-MD: var(--base-font-size);
    --GW-FONT-SIZE-LG: var(--font-l);
    --GW-FONT-SIZE-XL: var(--font-xl);
    --GW-FONT-SIZE-XXL: var(--font-xxl);

    // COLORS
    --GW-COLOR-TEXT-1: rgba(0,0,0,0.7);
    --GW-COLOR-TEXT-2: var(--gw-alfa-graphite);
    --GW-COLOR-BRAND-1: #4D9CBC;
    --GW-COLOR-BRAND-1-ACTIVE: var(--gw-lime-green);
    --GW-COLOR-BRAND-1-HOVER: var(--gw-green-2);
    --GW-COLOR-BRAND-1-CONTRAST: var(--gw-dark-green);
    --GW-COLOR-BRAND-1-GRADIENT: var(--gw-lime-green);
    --GW-COLOR-BRAND-1-GRADIENT-HOVER: var(--gw-dark-green-2);
    --GW-COLOR-BRAND-1-GRADIENT-HOVER-ACTIVE: var(--gw-dark-green-3);
    --GW-COLOR-ALERT-ERROR: #C30817;

    // LINE HEIGHT
    --GW-LINE-HEIGHT-XS: var(--line-height-xs);
    --GW-LINE-HEIGHT-SM: var(--line-height-s);
    --GW-LINE-HEIGHT: var(--line-height-m);
    --GW-LINE-HEIGHT-MD: var(--line-height-m);
    --GW-LINE-HEIGHT-LG: var(--line-height-l);
    --GW-LINE-HEIGHT-XL: var(--line-height-xl);
    --GW-LINE-HEIGHT-XXL: var(--line-height-xxl);

    // SPACING
    --GW-SPACING-SM: var(--gw-spacing-sm);
    --GW-SPACING: var(--gw-spacing-md);
    --GW-SPACING-LG: var(--gw-spacing-lg);
    --GW-SPACING-XL: var(--gw-spacing-xl);

    // BORDER COLOR
    --GW-COLOR-BORDER: var(--gw-grey-2);

    // BORDER RADIUS
    --GW-BORDER-RADIUS: 2px;

    // BOX SHADOW
    --GW-BOX-SHADOW-4: 0 1px 1px 0 var(--gw-black-opaque-1);
}
.jut__InputField__input{
  color: var(--gw-grey-dark-90-color);
}
.jut__IconButton__dark {
    --iconButton-color: grey;
}

.jut__Button__tertiary, .jut__Button__tertiary:disabled, .jut__Button__tertiary.disabled, .jut__Button__tertiary.focus {
  --button-color: #5f574f;
}
.jut__Button__primary{
    --button-bg-image: none;
    --button-bg-color: #287D9F;
    --button-active-bg-color: none;
    --button-active-bg-image: none;
  --button-primary-bg-image: $alfa-btn-active !important;
  --button-primary-hover-bg-image: $alfa-btn-hover !important;
  --button-primary-active-bg-image: $alfa-btn-disable !important;
  font-size: 16px;
}
.jut__Button__primary:hover ,.jut__Button__primary:focus, .jut__Button__primary:active{
  --button-bg-color: #387289;
  --button-bg-image: none;
}
.jut__Button__primary:disabled{
  --button-bg-color: #8db6c7;
  --button-bg-image: none;
}
.jut__Button__primary:active {
  --button-active-bg-image:none !important;
  --button-active-bg-color: #387289 !important;
  --button-active-border-color: #387289 !important;
  box-shadow: 0 1px 1px 0 #abc9da;
}
.jut__Button__tertiary:focus {
  --button-focus-color: #5f574f !important;
}
.jut__Button__tertiary:hover, .jut__Button__secondary:hover {
 --button-hover-color: #5f574f !important;
 --button-bg-color: #f6f6f6 !important;
}
.jut__Button__tertiary:active {
 --button-active-color: #5f574f !important;
}
.jut__Link__link {
  --link-box-shadow-focus: none;  
  --link-icon-font-size: 14px;  
  --link-color: #287D9F;
}

.jut__GenericSelectControl__genericSelect {
--genericSelect-control-focus-bg-color: var(--gw-grey) !important;
--genericSelect-option-hover-bg-color: var(--gw-grey-light-4);
--genericSelect-option-color: var(--gw-alfa-graphite);
--genericSelect-option-selected-bg-color: none;
cursor: pointer;
--genericSelect-menu-bg-color: var(--gw-white);
.jut__GenericSelectControl__optionFocused:active{background-color:var(--gw-grey-light-4);}

}
.jut__GenericSelectControl__control {

    border: none;
    border-style: none none solid !important;
    border-bottom: 2px solid var(--gw-alfa-graphite);
    border-radius: unset !important;
    outline: none;
    -moz-appearance:none;
    width: auto;
    box-shadow: none !important;
    background-color: white;
    position: relative;    

    span[class*= "jut__GenericSelectControl__selectIcon"] {
      &:first-child {
        position: absolute;
        right: 1px;
        &::before  {
            content: "\e5c5";
            font-size: 30px;
            color: var(--gw-alfa-graphite);
        }
      }        
    }
    
    input {
        width: auto !important;
    }
  }
.desktopVisible {
    @include media("<=phoneLandscape") {
        display: none;
    }
}
.mobileVisible {
    @include media(">phoneLandscape") {
        display: none !important;
    }
}
.digitalStepperButton {
  height:30px;
  width: 30px;
  border-radius: 50%;
  font-weight: bold;
  color: #4d9cbc !important;
  border: 2px solid #4d9cbc !important;
}
.jut__IconButton__iconButton {
  --iconButton-box-shadow: none;
    --iconButton-border-color: none;
    --iconButton-bg-color-hover: none;
    --iconButton-bg-color-active: none;
}
.jut__RadioButton__radioButton{
  --radioButton-icon-checked-border-color: #4d9cbc !important;
}
.jut__Accordion__accordionFrame {
    border: none !important;
}
.jut__Accordion__accordion {
  --accordion-focus-box-shadow: none;
  --accordion-focus-border-color: none;  
  --accordion-card-color: #373737;
}
.jut__FieldLabel__fieldLabel {
  --fieldLabel-color: #373737;
}

.jut__Footer__footerCopyrightBasic {
  color: #000;
  padding-top: 40px;
}
.buttonNext {

    background-color: #287D9F !important;
    box-shadow: 0 1px 1px 0 $alfa-btn-box-shadow;
  
  
    &:hover {
      background-color: $alfa-btn-hover !important;
    }
  
    &:disabled {
      background-color: $alfa-btn-disable !important;
      color: white !important;
    }
  }
  
  .buttonBack {
  
    //background-color: $alfa-btn-active !important;
    color: var(--gw-alfa-graphite) !important;
    border: none;
    box-shadow: none;
    font-size: 16px;
    margin-left: -5%;
    background-color: var(--gw-white);
  
    &:hover {
      background-color: $alfa-btn-box-hover;
      outline: none;
    }
    &:focus {
        background-color: $alfa-btn-box-hover;
        outline: none;
      }
  
    &:disabled {
      background-color: white !important;
    }
  }
  .jut__ToggleField__toggle{
   border-radius: 4px;
   background-color: #f6f6f6;
    box-shadow: 0 2px 2px 0 #ccc;
    min-width: 120px;
    line-height: 23px;
    border: 1px solid #ccc;
    font-size: 1.5rem;
    font-weight: normal;

  }
  .jut__ToggleField__right{
    border: 0 solid #333 !important;
    border-left-width: 1px !important;
    }
  
  .jut__ToggleField__middle{
      border: 0 solid #333 !important;
      border-left-width: 1px !important;
  }  
  .wizardDiv {
   @include media('>desktopSmall') {
     padding-left: 10rem;
    }
  }
  div[class^="jut__FieldMessage__fieldMessage jut__FieldMessage__error"] {
    
    font-size: 1.4rem;
  }

  .digitalToggle .digitalToggleButton {

    overflow-x: unset;
    //padding: 0 5px;
    width: auto;
    display: inline-block;
    @include media('<=phonePortrait') {
      --toggleButton-height: 28px !important;
    }
    
    
    --toggleButton-height: 26px !important;
    --toggleButton-inset-shadow-box-width: var(--GW-BORDER-WIDTH-THICK);
    --toggleButton-bg-color: #f6f6f6;
    --toggleButton-color: var(--gw-alfa-graphite);
    //--toggleButton-hover-bg-color: var(--gw-blue-light-1);
    --toggle-focus-not-active-color: var(--gw-alfa-graphite);
    --toggleButton-box-shadow: 0 2px 2px 0 var(--gw-grey-light-2) !important;
  
    &:hover, &:focus {
      --toggleButton-box-shadow: 0 2px 2px 0 var(--gw-grey-light-2) !important;
    }
  
    &.digitalToggleActive {
      --gw-toggle-active-background: none !important;
      --gw-toggle-hover-active-background: none !important;
      --toggleButton-bg-image: var(--gw-toggle-active-background);
      --toggleButton-color: var(--gw-alfa-graphite);
      --toggleButton-active-bg-color: none;
      border: 1px solid #333 !important;
  
      &:hover {
        background-image: var(--gw-toggle-hover-active-background);
      }
    }
  }

  .jut__Tooltip__tooltip {    
    --tooltip-border-radius: 0px;
    --tooltip-bg-color:#666666 !important;
    --tooltip-line-height: 1.4;
    --tooltip-font-size: 1.4rem !important;
    --tooltip-padding-x: 8px;
    --tooltip-padding-y: 8px;
  }

.digitalStepperButton:disabled {

  background-color: white !important;
}

.jut__InputMaskField__inputMask:focus{
  --inputMask-focus-border-color: none;
  
}

.jut__InputField__input{		  
 --input-invalid-box-shadow:none;
 --input-focus-color:none
}

.jut__CheckboxField__input:checked + .jut__CheckboxField__checkboxElement {
  background: #58b1d5;
  border-color: grey;
}

.jut__CheckboxField__checkboxElement:focus {
  border-color: grey;
}

.jut__DataTable__dataTable{
  --dataTable-row-bg-color-hover:white;
  --dataTable-row-bg-color-odd: white;
  --dataTable-row-bg-color-even: white;
  --dataTable-body-border: none;
  --dataTable-font-size: 15px;
  @include media("<=phonePortrait") {
  --dataTable-font-size: 12px;
  }
  --dataTable-font-weight: 100;
  --dataTable-cell-spacing-y: 7px;
 
  .ReactTable .rt-thead.-header {
    box-shadow: none;
  }
}


.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableHeader {
  border-bottom: none;
  box-shadow: none;
}

.jut__Modal__modal {
  --modal-warning-color: #F4F2EA;;
}
.jut__Modal__modalStateWarning {
  background-color: #F4F2EA;
  height: 60px;
}
.jut__Modal__iconWrapper {
  display: none;
}

.jut__Modal__dismissButton {
  background-color: #F4F2EA;
}

.jut__Modal__dismissButtonIcon {
  font-size: 18px;
  color: #333333;
  background-color: #F4F2EA;
}

.jut__Modal__modalFooter {
  background-color: white;
 button[class^="digitalButton jut__Button__button jut__Button__tertiary"] {
    border: 1px solid #287D9F;
    box-sizing: border-box;
    border-radius: 3px;
    color: #287D9F;
    font-weight: bold;
    font-size: 16px;
}
button[class^="digitalButton jut__Button__button jut__Button__primary"] {
  font-weight: bold;
  border-radius: 3px;
}
}

.jut__Modal__modalTitle {
  margin-top: 50px;
  font-size: 28px;
  font-weight: 700;
  color: #333333;
  line-height: 25px;

}
.jut__Modal__modalBody{
  margin-top: 80px;
  font-size: 18px;
  color: #000000;
  @include media("<phonePortrait") {
    margin-top: 90px;
  }
}

.jut__Modal__modalInner {
  @include media(">=phonePortrait") {
    min-width: 500px;
  }
}

.phone-header-grid {
  
  width: 265px !important;
  min-height: 150px;
}



button[id="cancelButton"] {
  pointer-events: none;
  &:hover {
    background-color: white;
  }
  span {
    pointer-events: all;
  }
}
button[id="qb_back"] {
  pointer-events: none;
  &:hover {
    background-color: white;
  }
  span {
    pointer-events: all;
  }
}