@import "~gw-common-styles";

.addressLookupBottomItems {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.addressContainer {
  position: relative;
}

.clearButton {
  --gw-close-button-position-right: 10px;
  --gw-close-button-position-top: 5px;
  @include media("<phonePortrait") {
    --gw-close-button-position-top: 40px;
  }
  top: var(--gw-close-button-position-top);
  right: var(--gw-close-button-position-right);
  position: absolute;
}

.componentContainers {
  padding: 16px;
  background-color: var(--gw-grey-light-8);
}

.addressLookupSearchButton {
  --gw-address-lookup-search-button-margin: 20px;
  margin-left: var(--gw-address-lookup-search-button-margin);
}

.smallInfo {
  font-size: 10px;
  left: 80px;
}

.addressLookupContainer {
  @include media(">=tabletLandscape"){
      width: 284px;
     margin-right: 10px;
  }
  @include media(">=tabletPortrait","<=tabletLandscape"){
      width: 270px;
     margin-right: 10px; 
  }
  @include media("<tabletPortrait"){
    width: 270px;
     margin-right: 10px;
  }
}

.aptBlock {
  @include media(">=tabletLandscape"){
    width: 110px;
    margin-right: 10px;
  }
  @include media(">=tabletPortrait","<=tabletLandscape"){
    width: 100px;
  }
  @include media("<tabletPortrait"){
    width: 100px;
  }
}

.addressCityDiv {
  @include media(">=tabletLandscape"){
    width: 153px; 
  }
  @include media(">=tabletPortrait","<=tabletLandscape"){
    width: 145px;
  }
  @include media("<tabletPortrait"){
    width: 145px;
  }
}

.stateSelector {
  position: relative;
  margin-bottom: 10px;
  @include media(">=tabletLandscape"){
    width: 60px;
    margin-right: 16px !important;  
  }
  @include media(">=tabletPortrait","<=tabletLandscape"){
    width: 60px; 
  }
  @include media("<tabletPortrait"){
    width: 60px;
  }
}

.stateReadOnly {
  @media only screen and (min-width: 1025px) {
    width: 15%;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 15%;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 16%;
  }
}

.zipBlock {
  @include media(">=tabletLandscape"){
    width: 153px; 
  }
  @include media(">=tabletPortrait","<=tabletLandscape"){
    width: 145px;
  }
  @include media("<tabletPortrait"){
    width: 145px;
  }
}

.countyBlock {
  @include media(">=tabletLandscape"){
    width: 153px; 
  }
  @include media(">=tabletPortrait","<=tabletLandscape"){
    width: 145px;
  }
  @include media("<tabletPortrait"){
    width: 145px;
  }
}

.displayFlex {
  display: flex;
}

.addressBlock20 {
  width: 20%;
  @include media("<=tabletPortrait") {
    width: 30%;
    margin-left: 0px !important;
  }
}

.addressBlock10 {
  width: 10%;
}

.licenseStateDrop {
  //width: 42%;
  margin-left: -1%;

  @media only screen and (min-width: 1025px) {
    width: 42%;
    margin-left: -4.5%;
  }

  @media screen and (min-width: 481px) and (max-width: 768px) {
    //EH-21799: added min and max screen width for making sure state dropdown shows up as expected
    margin-left: -2%;
    width: 60px;
  }
}

.flexComponent {
  padding: 10px 0;
}
.overrideError {
  input {
    border-style: none none solid !important;
    border-bottom: 2px solid var(--gw-alfa-graphite) !important;
    color: var(--gw-alfa-graphite) !important;
  }
}

.noPOBoxesMessage {
  // margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.4rem;
  color: $alfa-red-color;
  @include media("<=phoneLandscape") {
    margin-top: 10px;
  }
  @include media(">phoneLandscape", "<=tabletLandscape") {
    margin-top: 10px;
  }

  h4 {
    color: $alfa-red-color;
  }
}
.zipErrorMessage {
  font-size: 1.4rem;
  display: block;
  color: $alfa-red-color;
  transform: translateY(-20%);
}
.addressClass {
  input[class^="jut__InputField__input"] {
    padding-left: 0px;
    background-color: var(--gw-grey-light-8);
  }
  div[class^="jut__GenericSelectControl__control"] {
    padding-left: 0px;
    background-color: var(--gw-grey-light-8);
  }
}

.garage {
  padding-bottom: 20px !important;
}

