@import 'gw-common-styles';


.shieldImage{
  height: 200px !important;
  padding-left: 43%;
  @include media('<=phoneLandscape') {
    display: none;
  }
  @include media('<=desktopSmall') {
   // padding-bottom: 10%;
  }
}
.linkImage{
    width: 30px;
    height: 30px !important;
  }

.gridItem3Div{
    @include media('>phoneLandscape') {
        display: flex;
      }  
}
.divContainer{
  @media only screen and  (min-width:1025px) {
    padding: 80px 10%;
  }
  @media only screen and (min-width: 769px) and (max-width:1024px) {
    padding: 80px;
  }
  @media only screen and (min-width: 712px) and (max-width:768px) {
    padding: 25px;
  }
  @media only screen and (max-width:711px) {
    padding-bottom: 30px;
  }
}  
.gw-conf-agent{
    display: flex;
    display: -webkit-flex;
    padding-left:60px;
    padding-bottom: 30px;
    @include media('<=phoneLandscape'){
      display: block;
      padding: 0 10px;
      text-align: center;
      border: 1px solid #ccc;
      border-width: thin;
      
      box-shadow: 2px 2px #ccc;
      /* padding: 23px; */
      margin: 45px;
    }
    @include media('<=tabletLandscape'){
      padding-left: 10px;
  }
    
  .emailLineStyle{
    padding-top: 5px; 
    padding-bottom: 5px;
  }

  }
  .mobilegrid3{
    @include media('>phoneLandscape'){ 
        display: none;
    }
  }
.newLink{
    text-decoration: none;
}
.gridItem3SubDiv{
    @include media('>phoneLandscape') {
        display: flex;
        margin-top: 40px;
        justify-content: space-around;
        -webkit-justify-content: space-around;
      }   
}
.gridItemDiv{
    @include media('<=phoneLandscape') {
        display: none;
    }  
    margin: -2px 1px 48px -2px;
    padding: 20px 30px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

.mobilegridItem1{
    @include media('>phoneLandscape') {
      display: none;
    }
    margin: 0px 15px 48px 15px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px;     
    // for ipad
    @include media('<=tabletLandscape') {
      padding: 10px;     
    }
    //for mobile
    @include media('<=phonePortrait') {
      padding: 10px;     
    }  
}

.darkColor {
    color: black;
    @include media('<=phoneLandscape') {
      font-size: 11px;
  }    
}

.mobileHeading{
    display: block;
    font-size: 20px;
    font-weight: bold;
    
}
.gw-alfaicons-message{
    max-width: 198px;
    text-align: center;
    padding: 16px;
    @include media('<=phoneLandscape') {
     
      display:flex;
      width:90%;
      max-width: 90%;
      margin-left: 5%;
     align-items: center;
    }  
  }

  .test{
    @include media('<=phoneLandscape') {    
        display:none;        
      } 
  }
  .agentMobileIcon {
     @include media('<=phoneLandscape'){
      --iconButton-padding: 1px;
    }
  }
  .gw-discovery-icon{ 
    @include media('<=phoneLandscape'){
      max-width: 200px;
      padding-left: 30px;
      text-align: center;
    }
  }
  .mobileperksContainer{
    @include media('<=phoneLandscape'){    
      padding-left: 10px;     
    }
  }
  .mobilePerksText{
    font-weight: bold;
    font-size: 1.61rem;
  }
.discountText{
    display:block;
    color: black;
    text-decoration: none;
    text-align: center;
    font-weight: normal;
}
.nameText{
  display:block;
  font-weight: bold;
  padding-top: 4px;
}


.agentText{
  font-weight: normal;
  color: #545454;
  font-size:22px ;
  margin-right: 3%;
   @include media('<=phoneLandscape') {
    font-size: 18px ;
   }
   @include media('>phoneLandscape','<=tabletLandscape'){
    font-size: 18px ;
   }
}


.agentInfoContainer{
  padding-left: 30%;
  @include media('<=phoneLandscape') {
    text-align: left;
    padding-left: 10px;
    line-height: normal;
  }
}
.imageContainer {
  padding-left: 40px;
  @include media('>tabletLandscape') {
    width: 80%;
    padding-left: 60px;
  }  
  @include media('<=phoneLandscape') {
    display: none;
  }
}
.thankMessage{
  
  @include media('<=phoneLandscape') {
    
  font-weight: bold;
  font-size: 11px;
  }
  
}
.perksText{
    font-weight: bold;
    font-size: 1.61rem;
}

.discountDiv{
  width: 198px;
  text-align: center;
    @include media('<=phoneLandscape') {
        display: flex;
        width: 80%;
      }
      @include media('>tabletLandscape') {
        width: 210px;
      }
}
.imageDiv{
    text-align: center;   
    @include media('<=phoneLandscape') {    
    width: 100% ;
      }
      @include media('>phoneLandscape','<=tabletLandscape') {
         width: 35%;
       }
}
.logoImage{
    width: 30px;
    padding-bottom: 10px;      
}
.badgeLogo{
 	width: 30px;
    padding-bottom: 10px;
}
.textPiggy{
  display: block;
    max-width: 200px;
    text-align: center;
    padding:16px;
    @include media('<=tabletLandscape'){
      width: 100%;
      max-width: 198px;
    }
}
.textbadge{
  display: block;
    max-width: 210px;
    text-align: center;
    padding:16px;
    @include media('<=tabletLandscape'){
      width: 100%;
      max-width: 198px;
    }
}
.textHome{
  display: block;
    max-width: 200px;
    text-align: center;
    padding:16px;
    @include media('<=tabletLandscape'){
      width: 100%;
      max-width: 198px;
    }
}

.shieldImageMobile{
  height: 170px !important;
  //padding-left: 20%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  @include media('<=desktopSmall') {
    //padding-bottom: 10%;
  }
}

.emailImage{
  padding-bottom: 20%;
  margin-left: 20%;

  @include media('<=phoneLandscape') {
    display: none;
  }
  @include media('<=tabletLandscape') {
    margin-left: 0%;
    padding-bottom: 50%;
  }
}
.numWidth{
  height: 70px;
}
.printDiv {
  position: relative;
}
.agentPhoneNumber{
  font-weight: bold;
  font-size: 24px;
  letter-spacing: -0.01em;
  color: #333333;
  text-decoration: underline;
   @include media('<=phoneLandscape') {
    font-size: 13px;
  }
  @include media('<=desktopSmall') {
    font-size: 15px;
  }
}
.agentNameText{
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  color: #545454;
  @include media('<=phoneLandscape') {
    color: #999999;
  }

}
.agentName{
  font-size: 36px;
  line-height: 125%;
  color: #333333;
  font-weight: bold;
}
.agentEmail{
  text-decoration: underline;
  text-transform: lowercase;
  color: #333333;
  cursor: pointer;
}

.gridNoBorder {
  border: none !important;
  box-shadow: none !important;
  margin-bottom:15px;
}
.printQuote{
  position: absolute;
  right:20px;
  color: #287D9F;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  
}

.printGAMSQuote{
  color: #287D9F;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  margin-top: 15px;
  
}
.infoTableEmail{
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 125%;
  color: #000000;
  // for ipad
  @include media('<=tabletLandscape') {
    padding: 10px;     
  }
  //for mobile
  @include media('<=phonePortrait') {
    padding: 10px 14px 0px 14px;
  }  
}
.infoTable{
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 125%;
  font-family: 'coresansn';
  color: #000000;
}
.policy{
  font-weight: 300;
  font-size: 16px;
  line-height: 125%;
  color: #000000;
}
.infoTableMobile{
  width: 100%;
  margin-left: 20%;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 125%;
  font-family: 'coresansn';
  color: #000000;
}
.tdTextAlign{
  text-align: right;
}
.printQuoteMobile{
  text-align: center;
  font-weight: bold;
  display: block !important;
  color: #287D9F;
  font-size: 15px;
}
.mobileEmailImage{
  width: 60% !important;
  padding-left: 25%;  
  @include media('<=phonePortrait') {
    position: relative;
    left: 20px;
  }  
}
.accountCreationTd{
  width: 20%;
}
.agentAccountCreationTd{
  width: 20%;
  text-align: center;
  vertical-align: top;
}
.infoTableMobileTd{
  padding-left: 10%;
}
.agentImage{
  width: 60% !important;
  max-width: 50% !important;
}
.alignText{
  vertical-align: top;
}
.meetMargin{
  margin-left: 50px;
  @include media ('<desktopSmall'){
    margin-left: 40px;
  }
}
.agentImageMobile{
  width: 80% !important;
  max-width: width 30%;
}
.agentImageMobileTd{
  width: 50%;
}
.textWelcome{
  font-weight: bold;
  display: block;
  font-size: 20px;
  line-height: 125%;
  text-align: center;
  text-transform: uppercase;
  color: #545454;
  margin-bottom: 20px;
}
.youAreAllSet{
  display: block;
  height: 80px;
  top: 140px;
  text-align: center;
  font-weight: 300;
  font-size: 64px;
  line-height: 125%;
  color: #000000;
}
.youAreAllSetMobile{
  height: 45px;
  font-weight: 300;
  font-size: 36px;
  line-height: 125%;
  text-align: center;
  color: #000000;
}

.bundleAndSave{
  @include media('<=phonePortrait') { 
    padding-top: 40px;
  }
}

.gridHeaderText{
  display: block;
  height: 60px;  
  @include media('<=tabletLandscape') {
    height: 60px;
  }
  @include media('<=phonePortrait') {
    height: 30px;
  }
  top: 140px;
  text-align: center;
  font-weight: 300;
  font-size: 36px;
  line-height: 125%;
  color: #000000;
  padding-top: 10px;

}
.emailText{  
  font-weight: bold;
  font-size: 20px;
  font-style: italic;
  font-family: 'coresansn';
  display: inline-block;
  @include media('<=phoneLandscape') {
    font-size: 16px;
  }
}
.whatNext {
  display: inline-block;
  margin-top: 8px;
}
.callMessage {
  display: inline-block;
  margin-top: 16px;
}
.activeMsg {
  padding-right: 20px;
}
.phoneUnderline{
  text-decoration: underline;
  margin-left: 6%;
}
.agentIcon{
  width: 30px;
  @include media('<=desktopSmall') {
    width: 20px;
  }
}

.agentEmailMobile{
  width: 15px;
}
.agentEmailText{  
  font-weight: bold;
  font-size: 24px;
  @include media('<=phoneLandscape') {
    font-size: 13px;
  }
  @include media('<=desktopSmall') {
    font-size: 15px;
  }
}   
.mobileAgentPhone{
  font-weight: bold;
  font-size: 15px !important;
  color: #333333 !important;
  margin-left: 6%;
}
.agentBioPage{
  text-decoration: underline;
  font-weight: bold;
  font-size: 24px ; 
  @include media ('<=desktopSmall') {
    font-size: 15px;
  }
  color: #333333 !important;
  cursor: pointer;
}
.mobileAgentBioPage{
  text-decoration: underline;
  font-weight: bold;
  font-size: 15px !important;
  color: #333333 !important;
  cursor: pointer;
}
.mobileTextWelcome{
  display: block;
  @include media('<=phoneLandscape') {
   // font-size: 14px;
  }
  height: 20px;
  top: 185px;
  color: #545454;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.bundleAndSaveImage{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 21rem;
  min-width: 195px;
  padding-bottom: 10px;
}
.relationshipDiscountMessage{
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: #000000;
  width: 50%;  
  @include media('<=tabletLandscape') {
    width:72%;
    text-align: left;
    line-height: 125%;
    font-weight: 300;
    padding: 14px 11px 14px 11px;  
  }
  @include media('<=phonePortrait', '<=tabletLandscape') {
    width:100%;
    text-align: left;
    line-height: 125%;
    font-weight: 300;
    padding: 14px 11px 14px 11px;  
  }
}
.mt5{
  margin-top: 5%;
}
.ml6{
  margin-left: 6%;
}
.mobileMarginTop{
  margin-top: 10px;
}