@import "gw-common-styles";

  .paHeader {
    display: flex;
  
      h2 {
        font-size: 20px;
        color: var(--gw-grey-dark-90-color);
      }
      padding: 20px 0 0 10px;      
    }

  .gwToolTip {
    min-width: fit-content;
    background-color: none;
    background: none;
    width: fit-content;
    margin-top: 5px;
    i{
        font-size: medium;
    }

    text-align: center;
  }

  .basicTableCell {
    border: none;
    color: var(--gw-grey-dark-90-color);
  }

  .tableGrid {
    div[class^="rt-thead -header jut__DataTable__tableHeader"]{
      display: none;      
    }    
  }

  .vehicleNameSpan {
    padding: 0 10px;
    font-size: 16px;
    font-weight: 600;
    color: #373737;
  }

  .quoteTableCaption {
    padding: 10px;
    display: contents;
    font-size: 12px;
    color: $alfa-grey-font-color;
    text-align: left;
    @include media('>tabletLandscape') {
      width: 62rem;
      }
  }

  .nameCell {
    width: 47% !important;
  }

  .termCell {
    width:35% !important;
  }

  .amountCell {
    width: 18% !important;
    padding: 0px !important;
    overflow: visible !important;
  }

  .vehicleContainerStyle {
    text-align: left;
  }