@import '~gw-common-styles';

.fieldLinkColor  {
  color: var(--gw-blue-light-8);
  text-decoration: none;
}

.fieldLinkColor:hover {
  color: var(--gw-dark-blue-6);
  text-decoration: none;
}