@import "~gw-common-styles";

.digitalSLQWizard {
    --gw-wizard-page-template-sidebar-width: 28rem;
    --gw-wizard-page-padding: 0 0;
    --gw-wizard-page-section-gap: 4rem;
    --gw-wizard-page-context-gap: 2rem;
    --gw-wizard-page-buttons-bar-padding: 2rem 1rem;
    --gw-wizard-page-buttons-margin-x: 2rem;
    --gw-wizard-page-main-width: 70%;
    --gw-wizard-page-mobile-width: 100%;
    --gw-wizard-page-mobile-padding-left-right: 20px;
    --gw-slq-wizard-max-width: 584px;
    --gw-slq-wizard-padding-top: 40px;

    padding-top:var(--gw-slq-wizard-padding-top);
    display: grid;
    grid-area: main;
    width: var(--gw-wizard-page-main-width);
    margin: 0 auto;
    grid-gap: var(--gw-wizard-page-section-gap);
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    grid-template-areas: "wiznavigation" "wizcontent" "wizbuttons";
    align-items: start;
    max-width: var(--gw-slq-wizard-max-width);

    @include media("<=phoneLandscape") {
        width: var(--gw-wizard-page-mobile-width);
        padding: var(--gw-wizard-page-mobile-padding-left-right);
    }
}

.wizardNavigation {
    grid-area: wiznavigation;
}

.wizardContent {
    grid-area: wizcontent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wizardButtons {
    grid-area: wizbuttons;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--gw-wizard-page-buttons-bar-padding);
}
