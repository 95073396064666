@import 'gw-common-styles';

.pmtConfirmationPage {
    --gw-success-details-page-max-width: 100%;
    margin: 0 auto;
    max-width: var(--gw-success-details-page-max-width);
    width: 100%;
}

.pmtConfirmationProgressDiv{
}

/* ------------------------------------*\
   WIZARD PROGRESS BAR
\*------------------------------------ */
$wizard-progess-current-bg-color: #97c664 !default;
$wizard-progess-current-border-color: #d1d1d1 !default;
$wizard-progess-not-visited-bg-color: #bbbdbf !default;
$wizard-progess-visited-bg-color: #4d9cbc !default;

// mobile-view wizard steps progress bar
$wizard-progress-size: 9px !default;


.gw-quote-wizard-progress {
    margin-left: auto !important;
    margin-right: auto;
    padding-bottom: 30px;
    width: fit-content;
    padding-left: 2.5%;
    padding-right: 0%;
    
    @include media('<=phonePortrait'){
        padding-left: 0%;
        padding-right: 10%;
    }   
    @include media('>phonePortrait','<=phoneLandscape') {
        padding-left: 0%;
        padding-right: 7%;
    }
    @include media('>phoneLandscape','<=tabletLandscape') {
        padding-left:   5%; //0%;
        padding-right:  0%;   //2.5%;
    }     
  
    ul {
      // progress bar displayed as table to allow it to be used with any number of steps
      // flexbox would be more suitable for this but browser support is limited
      display: inline-block;
    }
  
    li {
  
      a,
      span {
        &::before {
          content: '';
          display: inline-block;
          min-width: 20px;
          height: 20px;
          @include media('<=phoneLandscape') { 
            min-width: 17px;
            height: 18px;
          }
          background-color: $wizard-progess-visited-bg-color;
          border-radius: 50%;
          vertical-align: top;
          content: '\f00c';
          color: white;
          line-height: 20px;
          font-family: 'FontAwesome';
          text-align: center;
        }
      }
  
      display: inline-block;
      border-radius: 50%;
      border: 2px solid transparent;
      box-sizing: content-box;
      line-height: $wizard-progress-size;
      position: relative;
  
      p {
      width: 150px;
      background-color: $alfa-font-color;
      height: 2px;
      margin: 8px 10px;
      @include media('>phoneLandscape','<=tabletLandscape') {  
        width: 90px;
        }
      @include media('<=phoneLandscape') {  
      width: 65px;
      margin: 4px 10px;
      }
      }
    }
    @mixin wizard-progress-link($color) {
      a,
      span {
        &::before {
          background-color: $color;
          content: '';
        }
      }
    }

    .progressSpan {
        display: flex;
    }
  
    .gw-not-visited {
      @include wizard-progress-link($wizard-progess-not-visited-bg-color);
    }
  
    .gw-current {
      @include wizard-progress-link($wizard-progess-current-bg-color);
  
    }
  }
  
.gw-quote-wizard-label {
    padding-top: 12px;
    width: 100px;
    transform: translateX(-30%);
    @include media('<phoneLandscape') {
      // Hide for everything but smallest responsive size
      display: none;
    }
  }

  .gw-quote-wizard-label-center {
    padding-top: 12px;
    width: 100px;
    transform: translateX(-20%);
    @include media('<phoneLandscape') {
      // Hide for everything but smallest responsive size
      display: none;
    }
  }
    
.pmtConfirmationHeaderDiv {
    margin-left: auto !important;
    margin-right: auto;
    padding-bottom: 1%;
    width: fit-content;
    padding-left: 0%;
    padding-right: 0%;
    
    @include media('<=phonePortrait'){
        padding-left: 2%;
        padding-right: 2%;
    }   
    @include media('>phonePortrait','<=phoneLandscape') {
        padding-left: 0%;
        padding-right: 7%;
    }
    @include media('>phoneLandscape','<=tabletLandscape') {
        padding-left: 0%;   //0%
        padding-right: 0%; //11%
    }   
}
.pmtCheckMark {
    color: #02C52D;
    --link-icon-font-size: 60px;
    margin-top: -1%;
    padding-right: 0%;
    margin-left: 1%;
    margin-right: -1%;
    @include media('<=phoneLandscape'){
        --link-icon-font-size: 40px;
        margin-left: 1%;
        margin-right: -1%;
    }   
}
.pmtHeaderText {
    display: inline;
    font-size: 30px !important;
    padding: 10px;
    color: var(--gw-alfa-graphite) !important;
    @include media('<=phoneLandscape') {
        --GW-FONT-SIZE-XL: 17px;
        font-size: 18px !important;
        padding: 5px;
    }  
}

.pmtConfirmationDetailsDiv {
    margin-left: auto !important;
    margin-right: auto;
    padding-bottom: 0%;
    width: 379px;
    padding-left: 0%;
    padding-right: 0%;
    font-size: 110%;
    line-height: 1.5em;
    color: var(--gw-alfa-graphite);
    @include media('<=phonePortrait'){
      max-width: 267px;
    }  
    @include media('>phonePortrait','<=phoneLandscape') {
      max-width: 267px;
    }
}

.pmtConfirmationInstructionsDiv{
    margin-top: 5%;
    margin-bottom: 10%;
    margin-left: auto !important;
    margin-right: auto;
    font-size: 110%;
    padding-bottom: 0%;
    width: 379px;
    padding-left: 0%;
    padding-right: 0%;
    color: var(--gw-alfa-graphite);
    @include media('<=phonePortrait'){
      max-width: 267px;
      font-size: 110%;
    }
    @include media('>phonePortrait','<=phoneLandscape') {
        margin-bottom: 5%;
    }
}

.signDocumentsButtonDiv {
    margin-left: auto !important;
    margin-right: auto;
    width: fit-content;
    padding-left: 0%;
    padding-right: 0%;
    align-content: end;
    margin-bottom: 5%;
    @include media('<=phonePortrait'){
    }  
    @include media('>phonePortrait','<=phoneLandscape') {
    }

}
.signDocumentsButton {
    font-weight: bold;
    margin-left: 82% !important;
    margin-right: auto; 
    width: fit-content; 
    color: #FFFFFF;
    @include media('<=phonePortrait'){
        margin-left: 38% !important;
    }
}

