@import 'gw-common-styles';

.productInputContainer {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 50% 50%;
  justify-content: center;
  grid-row-gap: var(--line-of-business-space);
  grid-template-areas:
    "postalCode startQuote"
    "retrieveQuote retrieveQuote";

  @include media('<tabletPortrait') {
    display: block;
  }
  div[class^="jut__FieldMessage__fieldMessage jut__FieldMessage__error"] {
    width:241px;
    font-size: 14px;
    text-align: left;
    margin-left: 10px;
    color: #C30717;
  @include media('<tabletPortrait') {
    width:245px;
    text-align: center;
  }
  }
}

.postalCode {
  text-align: center;
  margin: auto;
  width: var(--line-of-business-item-width);
  grid-area: postalCode;

  @include media("<=phonePortrait") {
    margin-left: 0px;
  }

  @include media('>phoneLandscape', '<=tabletLandscape') {
    // margin-left: 0px;
  }

}



.postalCodeContainer {
  input {
    text-align: left;
    width: var(--line-of-business-item-width);
    position: relative;
    border: none;
    border-bottom: 2px solid var(--GW-COLOR-BORDER) !important;
    outline: none;
    box-shadow: none !important;
    @include media('>=phoneLandscape', '<tabletPortrait') {
    margin-left: 1rem;
    }
    @include media('<tabletPortrait') {
      margin:auto;
    }
    color: var(--gw-grey-dark-90-color) !important;
    
  }
  div[class*="jut__FieldMessage__fieldMessage jut__FieldMessage__error"] {
    display: none;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .postalCodeContainer .jut__FieldMessage__error {
    width: max-content;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  text-align: center;
  display: flex;
  display: -webkit-flex;

  @include media('<tabletPortrait') {
    display: block;
  }

  display: flex;
  display: -webkit-flex;
  justify-content: space-around;
  flex-flow: row wrap;
  -webkit-justify-content: space-around;
  -webkit-flex-flow: row wrap;

  @include media('<=phoneLandscape') {
    flex-direction: column;
    align-items: center;
  }

}

.postalCodeContainerRedFont {
  input {
    text-align: left;
    width: var(--line-of-business-item-width);
    position: relative;
    border: none;
    border-bottom: 2px solid var(--GW-COLOR-BORDER) !important;
    outline: none;
    box-shadow: none !important;
    @include media('>=phoneLandscape', '<tabletPortrait') {
    margin-left: 1rem;
    }
    @include media('<tabletPortrait') {
      margin: auto;
    }
    color: var(--gw-grey-dark-90-color) !important;
  }
  input[class*="invalid"] {
    border-bottom: 2px solid #C30717 !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .postalCodeContainerRedFont .jut__FieldMessage__error {
    width: max-content;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  text-align: center;
  display: flex;
  display: -webkit-flex;

  @include media('<tabletPortrait') {
    display: block;
  }

  display: flex;
  display: -webkit-flex;
  justify-content: space-around;
  flex-flow: row wrap;
  -webkit-justify-content: space-around;
  -webkit-flex-flow: row wrap;

  @include media('<=phoneLandscape') {
    flex-direction: column;
    align-items: center;
  }
}


.gw-quote-inner {
  input {

    width: var(--line-of-business-item-width);
    ;
    position: relative;
    border: none;
    border-bottom: 2px solid var(--GW-COLOR-BORDER) !important;
    margin-right: 5px;
    outline: none;
    box-shadow: none !important;
  }

  .gw-zip-forms {
    display: flex;
    display: -webkit-flex;
    justify-content: space-around;
    flex-flow: row wrap;
    -webkit-justify-content: space-around;
    -webkit-flex-flow: row wrap;
    @include media('<=phoneLandscape') {
      flex-direction: column;
      align-items: center;
    }

  }
}

.startQuote {
  grid-area: startQuote;
  @include media('>phoneLandscape') {
    width: var(--line-of-business-item-width);
  }
  @include media('<tabletPortrait') {
    display: block;
    margin: auto;
  }
  color: #FFFFFF;
  font-size: 16px;
  font-weight: bold;
}


.gwRetrieveQuoteLink {
  text-decoration: underline;
  color: var(--gw-alfa-graphite);

  @include media('<=phoneLandscape') {
    font-size: 14px;
  }

  @include media('>phoneLandscape', '<=tabletLandscape') {
    font-size: 15px;
  }
}

.retrieveQuote {
  grid-area: retrieveQuote;
  text-align: center;
  text-decoration: underline;
  color: #000000;
  font-size: 16px;
  margin-top:20px;
  @include media('<tabletPortrait') {
    display: block !important;
    margin: 20px auto auto auto;
  }

}


.alphaVehicleImg {
  height: 165px;
  width: 340px;
  @include media('<phonePortrait') {
    width: 240px;
    height: 140px;
    margin-top: 30px;
  }

  
}
