@import "gw-common-styles";

.startDate {
    width: auto;
    padding: 0;
    align-items: baseline;

    @include media("<phonePortrait") {
      display: flex;
      flex-direction: row;
    }
  }
  .quotePageHeader {
    @include media("<phonePortrait") {
      flex-direction: row;
      align-items: center;
    }
  }
  .gwToolTip {
    min-width: fit-content;
    background-color: none;
    background: none;
    width: fit-content;
    i{
        font-size: small;
    }
    
  
    text-align: center;
  }
  .paHeader {
    @include media(">phoneLandscape") {
      display: flex;
    }
  
      h2 {
        font-size: 30px !important;
        color: $alfa-font-color !important;
        
      }
      padding: 30px 0 20px 0;  
      
  }
  .paSubHeader {
    display: flex;
    display: -webkit-flex;
      h2 {
        font-size: 30px !important;
        color: #333333 !important;
        
        @media only screen and (min-width: 1006px) and (max-width: 1191px) {
          font-size: 25px !important;
        }
      }
        
  }
  .paSubHeaderLongText {
      h2 {
        font-size: 30px !important;
        color: #333333 !important;
        @media only screen and (min-width: 1006px) and (max-width: 1191px) {
          font-size: 25px !important;
        }
      }  
  }
  .hideFlex {
    display: unset;
  }
  .capitalizeName {
    text-transform: capitalize;
  }
  .editInfoText { 
   pointer-events: none;
  }
  .quoteSubheading{
    font-size: 14px;
    color: $alfa-grey-font-color;
    padding-bottom: 20px;
  
    a {
      text-decoration: underline !important;
    }
  
    @include media('>phoneLandscape','<=tabletPortrait') {
      width: 58%;
    }
  
    @include media('<=phoneLandscape') {
      width: 90%;
    }
  }
  .quoteCaption {
    padding: 20px 2px 30px 2px;
    font-size: 12px;
    color: $alfa-grey-font-color;
    max-width: 64rem;
    @include media('<tabletPortrait') {
      padding: 20px 15px 45px 15px;
    }
    @include media('>phoneLandscape','<=tabletPortrait') {
      padding: 20px 5px 35px 5px;
  }
  }
  .editLinkInfo {
    font-size: 16px;
  }
  .flextStyle {
    display: flex;
  }