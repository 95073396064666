@import "~@jutro/theme/assets/sass/helpers";

.digitalWizard {
    --gw-wizard-page-padding: 0 0;
    --gw-wizard-page-section-gap: 4rem;
    --gw-wizard-page-section-phone-gap: 1.4rem;
    --gw-wizard-page-context-gap: 2rem;
    --gw-wizard-page-buttons-bar-padding: 2rem;
    --gw-wizard-page-buttons-bar-margin: 3rem;
    --gw-wizard-page-buttons-margin-x: 2rem;
    --gw-wizard-page-buttons-margin-bottom: 2rem;
    --gw-wizard-page-main-margin: 0 auto 4rem auto;
    --gw-wizard-page-main-phone-margin: 0 auto 2rem 0;
    --gw-wizard-page-main-tablet-margin: 0 auto 2rem auto;
    --gw-wizard-page-main-areas: "navigation   wizcontent"
                                                    "widgets      wizcontent";

    // Desktop variables
    --gw-wizard-page-main-width: 75%;
    --gw-wizard-page-template-sidebar-width: 21rem;
    --gw-wizard-page-template-main-content-width: calc(100% - var(--gw-wizard-page-template-sidebar-width) - var(--gw-wizard-page-section-gap));
    --gw-wizard-page-template-column-widths: var(--gw-wizard-page-template-sidebar-width) var(--gw-wizard-page-template-main-content-width);

    // Tablet variables
    --gw-wizard-page-main-tablet-width: 90%;
    --gw-wizard-page-template-sidebar-tablet-width: var(--gw-tablet-sidebar-width);
    --gw-wizard-page-template-main-content-tablet-width: calc(100% - var(--gw-wizard-page-template-sidebar-tablet-width) - var(--gw-wizard-page-section-gap));
    --gw-wizard-page-template-tablet-column-widths: var(--gw-wizard-page-template-sidebar-tablet-width) var(--gw-wizard-page-template-main-content-tablet-width);

    // Phone variables
    --gw-wizard-page-main-phone-width: 100%;
    --gw-wizard-page-template-main-content-phone-width: var(--gw-wizard-page-template-sidebar-tablet-width) var(--gw-wizard-page-main-phone-width);
    --gw-wizard-page-template-phone-column-widths: 0 auto;

    display: grid;
    width: 100%;
    height: 100%;
    padding: var(--gw-wizard-page-padding);
    grid-gap: var(--gw-wizard-page-context-gap);
    grid-template-rows: auto 1fr;
    grid-template-areas: "context"
                                     "main";
    align-items: start;
}

.wizardMain {
    @include gw-breakpoint-down(phone) {
        --gw-wizard-page-section-gap: var(--gw-wizard-page-section-phone-gap);
        --gw-wizard-page-main-margin: var(--gw-wizard-page-main-phone-margin);

        --gw-wizard-page-main-width: var(--gw-wizard-page-main-phone-width);
        --gw-wizard-page-template-column-widths: var(--gw-wizard-page-template-phone-column-widths);
        // to match the gap between grid sections
        padding-right: var(--gw-wizard-page-section-phone-gap);
        overflow-x: scroll;
    }
    @include gw-breakpoint-only(tablet) {
        --gw-wizard-page-section-gap: var(--gw-wizard-page-section-phone-gap);
        --gw-wizard-page-main-margin: var(--gw-wizard-page-main-tablet-margin);
        --gw-wizard-page-main-width: var(--gw-wizard-page-main-tablet-width);
        --gw-wizard-page-template-column-widths: var(--gw-wizard-page-template-tablet-column-widths);
    }
    display: grid;
    grid-area: main;
    width: var(--gw-wizard-page-main-width);
    margin: var(--gw-wizard-page-main-margin);
    grid-gap: var(--gw-wizard-page-section-gap);
    grid-template-columns: var(--gw-wizard-page-template-column-widths);
    grid-template-areas: var(--gw-wizard-page-main-areas);
    align-items: start;
}

.wizardMainShowSidebar {
    grid-template-columns: var(--gw-wizard-page-template-main-content-phone-width);
}

.wizardContext {
    grid-area: context;
}

.wizardNavigation {
    grid-area: navigation;
}

.wizardContent {
    grid-area: wizcontent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.wizardWidgets {
    grid-area: widgets;
}

.navButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: var(--gw-wizard-page-buttons-bar-padding);
    border-top: 1px solid var(--gw-grey-light-30-color);
    margin-top: var(--gw-wizard-page-buttons-bar-margin);

    /**
    
    @include gw-breakpoint-down(phone) {
        justify-content: center;
        flex-direction: column-reverse;
    }*/

    .navTransitionButtons {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-areas: "previous next";
        grid-gap: var(--gw-wizard-page-buttons-margin-x);
        margin-bottom: var(--gw-wizard-page-buttons-margin-bottom);

        .nextbutton {
            grid-area: next;
            position: absolute;
            right: 0;
            background-color: #287D9F !important;
            box-shadow: 0 1px 1px 0 #abc9da;
  
  
    &:hover {
      background-color: #387289 !important;
    }
    &:active {
        background-color: #387289 !important;
      }
  
    &:disabled {
      background-color: #8db6c7 !important;
      color: white !important;
    }
        }
    }

    .navTransitionButtonOnlyNext {
        grid-template-columns: auto;
        grid-template-areas: "next";
    }
}