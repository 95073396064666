@import '~gw-common-styles';

.currencyStyle {
    --gw-currency-style-margin-top: 1rem;
	--fieldComponent-control-readonly-font-weight: var(--font-weight-regular);
    --fieldComponent-control-readonly-font-size: var(--font-ml);
    margin-top: var(--gw-currency-style-margin-top);
}

.paymentListTableCell {
  @include media("<=tabletLandscape") {
    // FIXME: Remove !important when Jutro allow us to overwrite existing <td> styles
    padding: 10px !important; // Workaround using !important will work
  }
}
