@import "~@jutro/theme/assets/sass/helpers";

.clause {
    --clause-background-color: var(--GW-COLOR-BACKGROUND-1);
    --clause-padding: 5px;

    @include gw-breakpoint-down(phone) {
        --clause-padding: 0px;
        font-size: 12px;
        font-weight: 100;
    }

    background-color: var(--clause-background-color);
    padding: var(--clause-padding);
    text-align: left;
}

.clauseNoPadding {
    padding: 0;
}
.cellDisplay {
    display: flex;
    display: -webkit-flex;
}

.clauseAndTermSpacing {
    //--gw-clause-component-spacing: 15px;
   // margin-bottom: var(--gw-clause-component-spacing);
}

.paClauseGrid {
    //--clause-margin: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    
    grid-template-rows: auto;
    grid-template-areas: 'left middle right';
    //margin: var(--clause-margin) var(--clause-margin) 0 var(--clause-margin);
    //color: var(--gw-grey-dark-90-color);
    color: #333333;
    @include gw-breakpoint-down(phone) {
       
        width: 100%;
    } 
    @include gw-breakpoint-down(tablet) {
        grid-template-columns: 4fr 3fr 3fr;
        padding-bottom: 1rem;
    } 
}

.paClauseGridSlotLeft {
    grid-area: left;
    margin-right:10px;
    transform: translateY(12px);
    @include gw-breakpoint-down(phone) {
        margin-right: 0px;
        transform: translateY(5px);
    }  
    @include gw-breakpoint-down(tablet) {
        margin-right: 1px;
    }  
}

.paClauseGridSlotRight {
    grid-area: right;
    //padding-left:60px;
    transform: translateY(12px);
    text-align: right;
    @include gw-breakpoint-down(phone) {
       padding-left:5px;
        transform: translateY(5px);
    } 
}

.paClauseGridSlotMiddle {
    grid-area: middle;
    @include gw-breakpoint-down(phone) {
        padding-left: 5px;
    }
    
    position: relative;
}

.quoteError {
    color: #C30717;
    font-size: small;
    @include gw-breakpoint-down(phone) {
        font-size: 12px;
    }
}

.clause_term {
    @include gw-breakpoint-down(phone) {
    font-size: 12px;
    --dropdownSelect-control-font-size: 12px !important;
    width: 98%;
    }
    color: var(--gw-grey-dark-90-color);
    div[class*="jut__GenericSelectControl__placeholder"] {
        color: #373737 !important;
        }
}
.termMiddleLabel {
    position: absolute; 
    bottom: 0;
    @media only screen and (min-width: 320px) and (max-width: 420px) {
        bottom: 15%;
    }
}
//EH-22025: Use position as relative for MS quote to fix alignment issue
.termMiddleLabel2 {
    position: relative; 
    bottom: 0;
    @media only screen and (min-width: 320px) and (max-width: 420px) {
        bottom: 15%;
    }
}

// Not Editable Styles
.readOnlyGrid {
    --clause-margin: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'left right';
    margin: var(--clause-margin) var(--clause-margin) 0 var(--clause-margin);

    &:not(:last-child) {
        --read-only-clause-margin: 16px;
        --read-only-clause-boder-size: 1px;
        margin-bottom: var(--read-only-clause-margin);
        border-bottom: var(--read-only-clause-boder-size) solid var(--GW-COLOR-BORDER);
    }
}

.readOnlyGridSlotLeft {
    grid-area: left;
}

.readOnlyGridSlotRight {
    grid-area: right;
}

.readOnlyGridAmount {
    --read-only-clause-amount-margin: 5px;
    margin-left: var(--read-only-clause-amount-margin);
}

.clauseTermReadonly {
    grid-template-columns: 3fr 1fr;
}
.labelStyle {
    line-height: normal;
    @include gw-breakpoint-down(phone) {
       position: unset;
       bottom: unset;
    }
    @include gw-breakpoint-down(tablet) {
        position: unset;
        bottom: unset;
    }
}