.dateRangeContainer {
  display: flex;
}

.startDateValue {
  --start-date-right-margin: 4px;
  margin-right: var(--start-date-right-margin);
  text-align: right;
  width: auto;
  padding-bottom: 0;
}

.endDateValue {
  --end-date-left-margin: 4px;
  margin-left: var(--end-date-left-margin);
  width: auto;
  padding-bottom: 0;
}
