@import 'gw-common-styles';


.floatContainer {
  position: relative;
}
.floatReadOnly {
  &:first-child {
    border-bottom: 2px solid var(--gw-alfa-graphite);
    margin-top: 11px;
    width: 40px;
    text-align: center;
    font-weight: normal;
    color: #000000;
  }
}
.floatOverride {
  div[class*="jut__FieldMessage__error"] {
    display: none;
  }
  input[class^="jut__InputField__input invalid"] {
    color: unset;
    border-color: var(--gw-alfa-graphite);
  } 
}
