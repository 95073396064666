@import "gw-common-styles";

.gwToolTip {
  min-width: fit-content;
  background-color: none;
  background: none;
  width: fit-content;
  i{
      font-size: medium;
  }
  

  text-align: center;
  margin-top:5px;
}

.gw-contactNumber{
  color: #26a9e0;
}
.paHeader {
  display: flex;

    h2 {
      font-size: 30px !important;
      color: var(--gw-alfa-graphite);
    }
    padding: 30px 0 10px 0;  
    
  }

  .validateBtn {
   
    position: absolute;
    right: 0px;
    bottom: 20px;
    background-color: $alfa-btn-active;
    box-shadow: 0 1px 1px 0 $alfa-btn-box-shadow;

  &:hover {
    background-color: $alfa-btn-hover !important;
  }

  }
  .vehicleNextError {
    font-size: 16px;
    display: block;
    color: $alfa-red-color;
    position: absolute;
  
    @include media("<=phonePortrait")  {
      transform: translateY(-30%);
    }
  }

.paMessageHeader {
  padding: 0 0 10px 0;  
  h3 {
    font-size: 24px;
    color: var(--gw-alfa-graphite) !important;
    font-weight: normal !important;
  }
}

.vehicleContainers {
  max-width: 73rem;
  margin: 30px 0 0 0;
  @include media("<=tabletLandscape") {
    width: 100%
  }
  @include media(">tabletLandscape","<=desktopSmall") {
   max-width: 60rem;
  }
}
.newVehicleContainers {
  max-width: 73rem;
  margin: 30px 0 0 0;
  background-color: white;
  @include media("<=tabletLandscape") {
    width: 100%
  }
  @include media(">tabletLandscape","<=desktopSmall") {
   max-width: 60rem;
  }

}
.vehiclePanel {
  margin: -2px 0px;
  padding: 32px;
  background-color: white;
  border: 1px solid var(--greyscale-boulder-999999, #999);
  border-radius: 0px 0px 5px 5px;
  z-index: -1;
   @include media("<=phoneLandscape") {
    padding: 24px;
  }
}
.vehicleAccordionContainer {
     margin: 25px 0px;
     @media only screen and (min-width: 1030px) and (max-width: 1243px) {
      width: 100%
  }
}
.hideAccordion {
  @include media("<=phonePortrait")  {
    display: none;
  }
  margin: 10px 0px;
}
.buttonGroupClass {
  position: relative;
  padding: 20px 0 0 0;
}
.cancelButton {
  text-decoration: underline;
}
.addButton {
    position: absolute;
    right: 0;
  }
  .vehicleCaption {
    padding: 30px 0px 30px 0px;
    font-family: 'coresansn';
    font-size: 14px;
    color: var(--gw-alfa-graphite);
  
    @include media("<=desktopSmall")  {
      width: auto;
      padding: 30px 0px;
    }
  
    @include media("<=phonePortrait")  {
      -webkit-text-size-adjust: none;
    }
  }
  .vehicleCaptionSpan {
    text-decoration: underline !important;
  }

  .blockCollapse {
    display: block !important;
  }

  .allowCollapse {
    display: none !important;
  }

  .openTile {
    display: block !important;
  }

  .vehicleYear {
    font-size: 12px;
    font-family: 'coresansn';
    font-weight: bold;
  }

   .vehicleMakeModel {
    font-size: 16px;
    font-family: 'coresansn';
    font-weight: bold;
    margin-top: -5px;
    text-transform: capitalize;
  }

  .vechileDetailsContainer {
    text-transform: capitalize;
    span {
      display: block;
    }
  }
  
  div[id^="vehiclePageContainer"] div[class^="jut__Accordion__accordion"] {
   --accordion-border-width: 0px;
}