.facebookContent {
   --gw-facebook-share-spacing: 30px;
   --gw-facebook-share-btn-font-family: Helvetica,Arial,sans-serif;
   --gw-facebook-share-btn-content-padding-x: 3px;
   --gw-facebook-share-btn-padding-x: 15px;
   --gw-facebook-share-btn-padding-y: 2px;
   --gw-facebook-share-btn-border-radius: 3px;
   display: flex;
   justify-content: center;
   padding: var(--gw-facebook-share-spacing) 0;
}

.facebookShare {
   --gw-facebook-share-width: 300px;
   --gw-facebook-share-border-radius: 3px;
   border: 1px solid var(--gw-grey-opaque-2);
   border-radius: var(--gw-facebook-share-border-radius);
   max-width: var(--gw-facebook-share-width);
   text-align: center;
   padding: var(--gw-facebook-share-spacing);
}

.facebookShareButton {
   --button-primary-bg-color: var(--gw-facebook-share-background-color);
   --button-primary-hover-bg-color: var(--gw-facebook-share-background-color);
   --button-border-radius: var(--gw-facebook-share-btn-border-radius);
   --button-primary-color: var(--gw-white);
   --button-padding-x: var(--gw-facebook-share-btn-padding-x);
   --button-padding-y: var(--gw-facebook-share-btn-padding-y);
   --gw-button-min-width: unset;
   display: inline-flex;
   align-items: center;
   cursor: pointer;
   border: none;
}

.btnMessage {
   font-family: var(--gw-facebook-share-btn-font-family);
   font-weight: var(--gw-font-weight-bold);
   padding-left: var(--gw-facebook-share-btn-content-padding-x);
}