@import 'gw-common-styles';

:root {
   // --gw-modal-title-padding-top: 20px;
    --gw-modal-title-padding-bottom: 25px;
    --gw-modal-width: 720px;
    --gw-button-container-margin-top: 20px;
    --gw-cancel-button-margin-right: 10px;
}

.panelStyle {
    background: #fff;
    border-radius: 2px;
    width: 30rem;
    height: 45rem;
    min-width: unset;    
}

.gwImage {
  margin-left: 30%;
  height: 140px;
  width: 110px;
}

.darkColor {
  color: #000;  
}
.darkColor:hover {
  background-color: none ;  
}

.gwMessage { 
  line-height: 20px;
  margin-right: 13px;
  padding-bottom: 0;
  font-size: 15px;
  text-align: unset;  
}

.gwTooltipFooter {
  padding: 10px 110px;
}

.divContainerStyle {
  display: flex;
    background: #ddd;
    position: relative;
    left: -10px;
    transform: translateY(-60%);
    width: 30rem;
    padding: 10px;

    h1 {
      font-size: 26px;
    }
}
.titleStyle {
  font-size: 3.9rem;
  font-weight: bold;
}
.closeBtn {
  position: absolute;
  right: 10px;
  height: 20px;
  width: 20px;
}




