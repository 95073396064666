@import 'gw-common-styles';

.gwIssuesContainer {
  --gw-issues-message-radius: 2px;
  --gw-issues-message-height: 3rem;
  --gw-issues-message-border: 100%;
  --gw-issues-message-flex: 1;
  --gw-issues-icon-border: 1px;
  --gw-issues-mar-bot: 20px;
  margin-bottom: var(--gw-issues-mar-bot);

  .gwIssuesMessage {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: var(--gw-issues-message-radius);

    &::before {
      margin: var(--font-xxs);
      display: block;
      font-family: "FontAwesome";
      line-height: var(--gw-issues-message-height);
      font-size: var(--font-l);
      flex: none;
      text-align: center;
      height: var(--gw-issues-message-height);
      width: var(--gw-issues-message-height);
      border-radius: var(--gw-issues-message-border);
    }
  }

  .gwIssuesError {
    border: var(--gw-issues-icon-border) solid var(--gw-red-5);
    background-color: var(--gw-red-4);

    &::before {
      content: var(--icon-font-awesome-error);
      color: var(--gw-red-3);
      background-color: var(--gw-red-5);
    }
  }

  .gwIssuesWarning {
    border: var(--gw-issues-icon-border) solid var(--gw-orange-2);
    background-color: var(--gw-orange-4);

    &::before {
      content: var(--icon-font-awesome-warning);
      color: var(--gw-orange-3);
      background-color: var(--gw-orange-2);
    }
  }

  .gwIssuesMessageContainer {
    flex: var(--gw-issues-message-flex);
  }

  .gwIssuesCloseIcon {
    margin: var(--font-xxs);
    align-self: flex-start;
    color: var(--gw-grey-light-4);
    font-size: var(--font-l);
    cursor: pointer;
  }
}
