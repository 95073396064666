@import "~@jutro/theme/assets/sass/helpers";
.pageContainer {
    --gw-faq-page-container-grid-gap: 10px;
    --gw-faq-page-container-width: 100%;
    --gw-faq-page-container-padding-top: 40px;
    --gw-faq-page-container-padding-bottom: 40px;
    --gw-faq-page-container-grid-columns: 28fr 75%;

    padding-top: var(--gw-faq-page-container-padding-top);
    padding-bottom: var(--gw-faq-page-container-padding-bottom);
    display: grid;
    width: var(--gw-faq-page-container-width);
    margin: auto;
    grid-template-areas: 'navFaq main';
    grid-template-columns: var(--gw-faq-page-container-grid-columns);
    grid-gap: var(--gw-faq-page-container-grid-gap);

    @include gw-breakpoint-only(phone) {
        grid-template-areas: 'main';
        --gw-faq-page-container-grid-columns: 100%;
    }
}

.sideBar {
    grid-area: navFaq;
    @include gw-breakpoint-only(phone) {
        display: none;
    }
}

.content {
  grid-area: main;
}

.gwWizard {
    --gwWizard-min-height: 600px;

    display: inline-block;
    height: 100%;
    width: 100%;
    min-height: var(--gwWizard-min-height);
    text-align: center;
    transform: translateX(0);
    transition: 0.5s all cubic-bezier(0.32, 1.25, 0.375, 1.15);
}

.wizardSidebar{
    --wizardSidebar-width: 23%;
    --wizardSidebar-margin-right: .4%;

    display: inline-block;
    width: var(--wizardSidebar-width);
    vertical-align: top;
    margin-right: var(--wizardSidebar-margin-right);
    text-align: center;
}

.wizardMain {
    --wizardMain-width: 75%;

    text-align: left;
    display: inline-block;
    width: var(--wizardMain-width);
    vertical-align: top;
}

.wizardSidebarSteps{
    --wizardSidebarSteps-border-radius: 2px;

    text-align: left;
    background-color: var(--GW-COLOR-BACKGROUND-1-HOVER);
    border-radius: var(--wizardSidebarSteps-border-radius);
}

.gwsteps {
    --gwsteps-line-height: var(--GW-LINE-HEIGHT-MD);

    line-height: var(--gwsteps-line-height);
}

.ulStyle {
    --ulStyle-margin: 30px;

    list-style: none;    
    text-align: left;
    margin: var(--ulStyle-margin);   
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.wizardWrapper{
    --wizardWrapper-border-radius: 2px;
    --wizardWrapper-padding-top: 17px;
    --wizardWrapper-padding-bottom: 4px;


    padding-top: var(--wizardWrapper-padding-top);
    padding-bottom: var(--wizardWrapper-padding-bottom);
    text-align: left;
    background-color: var(--GW-COLOR-BACKGROUND-1-HOVER);
    border-radius: var(--wizardWrapper-border-radius);
}

.searchTopicInputField {
    --gw-searchInput-margin-bottom: -20px;
    margin-bottom: var(--gw-searchInput-margin-bottom);
}

.gwFaqSection {
    --gwFaqSection-padding-top: 15px;
    padding-top: var(--gwFaqSection-padding-top);
}

.faqSectionContainer {
    --gwSectionContainer-padding-bottom: 50px;
    padding-bottom: var(--gwSectionContainer-padding-bottom);

  .faqnoresult {
    text-align: center;
    padding-top: var(--gw-faq-page-container-padding-top);
  }
}

.wizardLi {
    --wizardLi-margin-bottom: 2px;
    --wizardLi-padding-topBottom: 6px;
    --wizardLi-padding-leftRight: 15px;

    display: block;
    padding: var(--wizardLi-padding-topBottom) var(--wizardLi-padding-leftRight) var(--wizardLi-padding-topBottom);
    margin-bottom: var(--wizardLi-margin-bottom);
    position: relative;
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    cursor: pointer;

    a {
        cursor: pointer;
        color: var(--GW-COLOR-FOCUS-1);
        text-decoration: none;
    }

    a:hover {
        color: var(--GW-COLOR-FOCUS-1-HOVER);
        text-decoration: underline;
    }
}

.wizardLiDisabled {
    cursor: default;

    a {
        color: var(--GW-COLOR-STROKE);
        text-decoration: none;
        pointer-events: none;
    }
}

.wizardLink {
    --wizardLink-width: 100%;

    text-decoration:none;
    width:var(--wizardLink-width);
}

.sideBarTitle{
    --sideBarTitle-margin-bottom: 10px;
    --sideBarTitle-margin-top: 15px;
    --sideBarTitle-border-bottom: 1px;
    --sideBarTitle-padding: 10px;

    font-size: var(--GW-FONT-SIZE-MD);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    line-height: var(--GW-LINE-HEIGHT-LG);
    padding: 0 var(--sideBarTitle-padding);
    margin-bottom: var(--sideBarTitle-margin-bottom);
    margin-top: var(--sideBarTitle-margin-top);
    border-bottom: var(--sideBarTitle-border-bottom) solid var(--GW-COLOR-STROKE);
    display: block;
}
.gwSectionInactive{
        pointer-events:none;
        color: var(--GW-COLOR-STROKE) !important;
}
.gwSectionActive{background-color:var(--GW-COLOR-DARK-CONTRAST)}

.gwPageSectionTitle {
    --gwPageSectionTitle-font-size: var(--GW-FONT-SIZE-XL);
    --gwPageSectionTitle-line-height: var(--GW-LINE-HEIGHT-XS);
    --gwPageSectionTitle-padding-bottom: 8px;
    --gwPageSectionTitle-border-bottom: 2px;
    --gwPageSectionTitle-margin-bottom: 30px;
  
    font-size: var(--gwPageSectionTitle-font-size);
    line-height: var(--gwPageSectionTitle-line-height);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    color: var(--gw-grey-dark-90-color);
    padding-bottom: var(--gwPageSectionTitle-padding-bottom);
    border-bottom: var(--gwPageSectionTitle-border-bottom) solid var(--GW-COLOR-STROKE);
    margin-bottom: var(--gwPageSectionTitle-margin-bottom);
}