@import 'gw-common-styles';

.gwMediaSidebarWrapper {
    --gw-media-sidebar-width: 100%;
    --gw-media-sidebar-height: 75%;
    --gw-media-sidebar-margin: 15px;
    --gw-media-sidebar-text-margin: 10px;
    --gw-media-sidebar-text-padding: 5px;

    width: var(--gw-media-sidebar-width);
    height: var(--gw-media-sidebar-height);
    background-color: var(--gw-grey-light-19);
    margin-top: var(--gw-media-sidebar-margin);
    @include media("<=tabletLandscape") {
        display:none;
    }

    div {
        width: 100%;
    }
}

#player{
    width: 100%;
}

.rssStyle {
    padding: 10px;
    background: var(--gw-white);
    border-bottom: 1px solid var(--gw-grey-light-19);

    a {
        text-decoration: none;
        color: var(--gw-blue-light-8);
    }

    .muted {
        color: var(--gw-grey-light-9);
        font-size: var(--font-s);
    }
}


