@import "gw-common-styles";

.vehicleHeaderContainer {
    width: 100%;
    margin-top: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media("<phonePortrait") {
        flex-direction: column;
        align-items: center;
    }
}

.vehicleDetails {
    --vehicle-header-container-padding: 15px 10px;
    font-size: var(--base-font-size);
    font-family: var(--base-font-family);
    display: flex;
    align-items: center;
    padding: var(--vehicle-header-container-padding);
    padding-left: 0;
}

.vehicleActions {
    --vehicle-actions-width: 125px;
    --vehicle-actions-margin: 70px;

    @include media("<phonePortrait") {
        --vehicle-actions-margin: 0;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: var(--vehicle-actions-width);
    margin-right: var(--vehicle-actions-margin);
}

.chevronIcon {
    font-size: var(--font-xxl);
}

.hideActions {
    display: none;
}

.chevron {
    display: none;
}
.checkMark {
  i {
    color: green !important;
  }
}

.vehicleHeader {
    flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--greyscale-moondust-f-6-f-6-f-6, #F6F6F6);
  position: relative;
  outline: none;

  h2 {
    font-size: 18px !important;
  }
 
}

.vehicleHeaderClosed {
  border: 1px dashed var(--greyscale-boulder-999999, #999);
  border-radius: 5px;
  color: #757575;
  
  &:hover {
    border: 1px solid var(--greyscale-boulder-999999, #999);
    color: #333333;
  }
}

.vehicleHeaderOpen {
  border: 1px solid var(--greyscale-boulder-999999, #999);
  border-radius: 5px 5px 0px 0px;
}

.vehicleHeaderTitle {
    display: flex;
    font-size: 18px;
    padding: 16px;
    height: 71px;

    i {
      font-size: 18px;
      display: table-cell;
      left: 0;
      padding: 10px 16px 5px 0px;
    }

  }
  .vehicleHeadingColumn {
    flex-direction: flex;
    width: 100%;
   height: 39px;
    @include media('<=phoneLandscape') {
      padding-right: unset;
    }
    font-weight: bold;
  }
  .vehicleHeadingColumnNewDriver {
    display: flex;
    width: 100%;
    text-align: left;        
    font-weight: bold;
    font-size: 16px;
    font-family: 'coresansn';
    padding-top: 8px;
  }
  .selectedVehicleHeader {
    height: 71px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    position: relative;
    outline: none;
    color: #333333;
    border: 1px solid var(--greyscale-boulder-999999, #999); 
  
    h2 {
      font-size: 18px !important;
  
      @include media('<=phoneLandscape') {
        font-size: 17px !important;
      }
  
    }
    
  }

  .selectedVehicleHeaderClosed {
    border-radius: 5px; 
  }

  .selectedVehicleHeaderOpen {
    border-radius: 5px 5px 0px 0px; 
  }

  .selectedVehicleHeaderTitle {
    display: flex;
   
    i {
      font-size: 18px;
      display: table-cell;
      left: 0;
      padding: 10px 16px 5px 0px;
    }
  }
  .editRemoveLink {
    display: table-cell;
    right: 0;
    position: absolute;
    color: #333333;
    font-family: 'coresansn';
    @include media('<=tabletLandscape') {
      bottom: 5px;
      margin: 15px;
      font-size:14px;
  
    }
  
    @include media('>tabletLandscape') {
      top: 0;    
      margin: 22px;
    }
  
  
    a {
      cursor: pointer;
      text-decoration: none;
      font-size: 14px;
      margin: 5px;
      font-weight: normal;
    }
    .selectedVehicleName{
      @media screen and (min-width:1100px) and (max-width:1200px) {
        margin-right: 50px;
      }
        @media screen and (min-width:1008px) and (max-width:1090px) {
        margin-right: 80px;
      }
      ///1070 1008 1077.5
      @include media('<=tabletLandscape') {
          margin-right: 0px !important;
      }
    }
  
  }

  .newVehicleHeader{
    flex-direction: row;
    background-color: var(--greyscale-moondust-f-6-f-6-f-6, #F6F6F6);
    justify-content: center;
    align-items: center;
    height: 71px;
    position: relative;
    outline: none;
    padding: 16px;

    h2 {
      font-size: 18px !important;
  
      @include media('<=phoneLandscape') {
        font-size: 17px !important;
      }
  
    }
  }

  .newVehicleHeaderClosed{
    border: 1px dashed var(--greyscale-boulder-999999, #999);
    border-radius: 5px;
    color: #757575;
    
    &:hover {
      border: 1px solid var(--greyscale-boulder-999999, #999);
      color: #333333;
    }
  }

  .newVehicleHeaderOpen{
    border: 1px solid var(--greyscale-boulder-999999, #999);
    border-radius: 5px 5px 0px 0px;
    
  }