@import "~gw-common-styles";

.digitalButton {
    --button-grey:  #5f574f;
    // JUTRO VARIABLES
    --button-height: 40px;
    --button-line-height: 0;
    --button-font-weight: normal;
    // PRIMARY
    --button-primary-bg-image: none;
    --button-primary-hover-bg-image: none;
    --button-primary-active-bg-image: none;
    --button-primary-color: var(--gw-white);
    --button-primary-hover-color: var(--gw-white);
    --button-primary-focus-color: var(--gw-white);
    // SECONDARY
    --button-secondary-bg-color: var(--gw-white);
    --button-secondary-hover-border-color: var(--gw-lime-green);
    --button-secondary-hover-bg-color: var(--gw-light-green-2);
    --button-secondary-hover-color: var(--gw-dark-green);
    --consumerButton-secondary-box-shadow-inner: none;
    //TERTIARY
    --button-tertiary-color: var(--button-grey);
    --button-tertiary-hover-bg-color: var(--gw-grey-light-30-color);
    --button-tertiary-hover-color: var(--button-grey);
    --button-tertiary-active-bg-color: var(--gw-grey-light-30-color);
    --button-tertiary-focus-color: var(--button-grey);

    // CUSTOM VARIABLES
    --gw-button-min-width: 152px;
    --gw-button-transition-time: .25s;

    @include media("<phonePortrait") {
        --gw-button-min-width: 80px;
    }

    // CUSTOM STYLES
    @include media(">=desktopSmall") {
        min-width: var(--gw-button-min-width);
    }
    box-shadow: none;
    transition:
        min-width var(--gw-button-transition-time),
        min-height var(--gw-button-transition-time),
        background-color var(--gw-button-transition-time),
        color var(--gw-button-transition-time),
        border var(--gw-button-transition-time);

    &:disabled {
        color: var(--gw-white);
        border-color: var(--gw-grey-light-30-color);
        background-color: var(--gw-grey-light-30-color);
        box-shadow: none;
    }
}

.digitalSecondary {
    --button-font-weight: var(--font-weight-regular);
    --gw-button-secondary-border: white;
    --button-color: var(--button-grey);
    //--button-focus-color: var(--gw-green-2);
    border: none;

    &:hover {
      //--button-hover-bg-color: var(--gw-light-green-2);
      //--button-hover-color: var(--gw-dark-green);
      background-image: unset;
    }

    &:active {
      --button-bg-color: var(--gw-white);
      --button-color: var(--button-grey);
      --button-active-bg-color: var(--gw-white);
      --button-active-color: var(--gw-white);
      --button-active-bg-image: unset;
    }

    &:active:focus {
      --button-bg-color: var(--gw-white);
      --button-color: var(--button-grey);
    }

    &:active:focus:hover {
      --button-bg-color: var(--gw-white);
      --button-color: var(--button-grey);
    }

    &:focus {
      border: var(--gw-button-secondary-border)  !important;
      box-shadow: var(--button-secondary-focus-box-shadow);
      --button-bg-color: var(--gw-white) !important;
      --button-color: var(--button-grey);
      background-image: unset;
      --button-active-bg-color: var(--gw-white);
      --button-active-color: var(--button-grey);
      --button-active-bg-image: unset;
    }
}